export const preImplant = [
    {
        subject: "Imaging Method",
        fields: [{
            fieldName: "Which imaging method was used?",
            fieldType1: "checkbox",
            fieldType2:"date",
            methods:["Computerised Tomography","Chest X-Ray","Flouroscopy","Other"]
        },
        {
            fieldName: "Is image available for upload?",
            fieldType: "radio",
			values: [
                {
                    fieldName:"Is image available for upload?",
                    value:"Yes"
                },
                {
                    fieldName:"Is image available for upload?",
                    value:"No"
                }
			
			]
        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE***",
            fieldType: "file"
        }],
    },
    {
        subject: "Suitability for Implant",
        fields: [{
            fieldName: "Position of 75mm mark relative to ribs",
            fieldType: "text"
        },
        {
            fieldName: "Is the patient anatomy appropriate for device implant?",
            fieldType: "radio",
            values:[
                {
                    fieldName:"Is the patient anatomy appropriate for device implant?",
                    value:"Yes"
                },
                {
                    fieldName:"Is the patient anatomy appropriate for device implant?",
                    value:"No"
                }
            ]
        },
        {
            fieldName: "If no, please give reason",
            fieldType: "text",
            dependency: {
                fieldName: "Is the patient anatomy appropriate for device implant?",
                value: "No"
            }   
        }
        ],
    },
];