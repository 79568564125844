import { useEffect, useState } from 'react'
import notifications from "../../../../assets/icons/notifications.svg"
import notifydot from "../../../../assets/icons/notifydot.svg"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { IonImg } from '@ionic/react';
import { IoSyncSharp } from "react-icons/io5";
import { useAppData } from '../../../../context/appContext';
import { useHistory } from 'react-router';
import "./patientcard.css"


const Patientcard = (props) => {
    const appData = useAppData()
    const history = useHistory()
    console.log(props.patientData,"these are the props")

    const handleDataSync = (e) => {
        appData.setAppUserData(props.patientData)
        appData.setSelected("Data Sync")
        console.log(props.patientData)
        history.push(`/dashboard/datasync`)
    }


    return (
        <div onClick={handleDataSync} className='patientcard_container'>
            <div className="patientcard_header" >
                <div data-testid="TSUBJECTID" style={{paddingLeft:"20px"}} className='patient_subjectId'>
                    {props.subjectId}
                </div>
{/*                 <div style={{paddingRight:"20px"}} className='patient_subjectId'>
                    HCA Healthcare Nashville
                </div> */}
            </div>
            <div className='patientcard_details_container'>
                <div style={{paddingTop:"5px"}}>
                    <div  className='patientcard_detail_header'> LAST SYNC</div>
                    <div data-testid="TSYNCDAY" style={{color:"#525252"}} className='patientcard_day'>{props?.day}</div>
                    <div data-testid="TSYNCMON" style={{color:"#525252"}} className='patientcard_month'>{props?.month}</div>
                    <div style={{display:"flex",justifyContent:'center',paddingLeft:"10px",paddingBottom:"5px"}}>
                        <IoSyncSharp style={{color:"#22BCBD"}} className='patientcard_sync_icon' />
            
                    </div>
                </div>
                <div style={{ height: "100%", width: "0.5px", color: "red", backgroundColor: "#B3B3B3" }}>

                </div>
                <div style={{paddingTop:"5px"}}>
                    <div className='patientcard_detail_header'> NEXT SYNC</div>
                    <div className='patientcard_day'>16 </div>
                    <div className='patientcard_month'>Mar</div>
                    <button className='patientcard_button'>schedule</button>
                </div>
                <div style={{ height: "100%", width: "0.5px", color: "red", backgroundColor: "#B3B3B3" }}>

                </div>
                <div style={{paddingTop:"5px"}}>
                    <div className='patientcard_detail_header'> NEXT APPT</div>
                    <div className='patientcard_day'>17</div>
                    <div className='patientcard_month'>Mar</div>
                </div>
                <div style={{ height: "100%", width: "0.5px", color: "red", backgroundColor: "#B3B3B3" }}>

                </div>
                <div style={{paddingTop:"5px"}}>
                    <div className='patientcard_detail_header'>DEVICE</div>
                    <div style={{ display: "flex", paddingTop: "11px" }}>
                        <div data-testid="TSYNCBATTLIFE" style={{ width: "60px", paddingTop: '15px' }}>
                            <CircularProgressbar
                                value={props.batteryLife} text={`${props.batteryLife}%`}
                                styles={buildStyles({
                                    textColor: "#22BCBD",
                                    textSize: "25px",
                                    pathColor: "#22BCBD",
                                })} /></div>
                        <div style={{ paddingLeft: "15px", paddingTop: "5px" }}>
                            <div>
                                <div style={{ fontSize: "14px", fontWeight: "400", color: "#808080" }}>IMPLANT DATE</div>
                                <div data-testid="TSYNCIMPLANTDATE" style={{ fontSize: "14px", fontWeight: "400", color: "#808080" }}>{props?.implantDate}</div>
                            </div>
                            <div style={{ paddingTop: '10px' }}>
                                <div style={{ fontSize: "14px", fontWeight: "400", color: "#808080" }}>ERI DATE</div>
                                <div data-testid="TSYNCERIDATE" style={{ fontSize: "14px", fontWeight: "400", color: "#808080" }}>16/JAN/2032</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{ height: "100%", width: "0.5px", color: "red", backgroundColor: "#B3B3B3" }}>

                </div>
                <div style={{paddingTop:"5px"}} className='patientcard_notification_container'>
                    <div className='patientcard_detail_header'>
                        NOTIFICATIONS
                    </div>
                    <div className='patientcard_icon_container'>
                        <IonImg className='patientcard_notification_icon' src={notifications} alt="notifications" />
                        {props.symptoms == undefined ? <div style={{ width: '10px', height: '10px', background: '#535352' }}></div> : <div style={{ width: '10px', height: '10px', background: '#22BCBD' }}></div>}
                    </div>
                    <div className='patientcard_icon_container'>
                        <IoSyncSharp className='patientcard_sync_icon' />
                        <div className='patientcard_indicator'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Patientcard