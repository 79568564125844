
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import { useState, useEffect } from 'react'
import Patientcard from './components/patientcard/patientcard'
import DTAL from '../../service/devicesubscription/mockdataservice'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppData } from '../../context/appContext';
import "./patientdashboard.css"

const Patientdashboard = ({patientData}) => {
    const appData = useAppData()
    // const [patientData, setPatientData] = useState(null)
    console.log(patientData,"patientdata is coming")
    useEffect(() => {
     appData.setSelected("Patient Dashboard")
    }, [])
    
   

    return (
        <div data-testid="TGETIONROWDATA" id="scrollableDiv" style={{ height: '800px' }} className='patient_dash_container'>
            {/* <InfiniteScroll
                dataLength={patientData.length}
                // next={this.fetchMoreData}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
                > */}
            <IonGrid>
                {patientData || patientData !== "undefined" ?
                    <IonRow  style={{paddingBottom:"200px"}}>
                        {patientData?.map((item, index) => {

                            return (
                                <IonCol data-testid={item?.attributes.subjectId} key={item?.attributes.subjectId} style={{ margin: '10px', }} size='auto'>
                                    <Patientcard
                                        patientData={item}
                                        subjectId={item?.attributes.subjectId}
                                        batteryLife={item?.features.devicestatus?.properties?.batterylife}
                                        month={item?.timestamp.slice(0, 4)}
                                        day={item?.timestamp.slice(4, 7)}
                                        symptoms={item?.symptoms}
                                        implantDate={item?.implantDate}
                                    />
                                </IonCol>
                            )
                        })}
                    </IonRow> : <div>Something went wrong</div>}
            </IonGrid>
            {/* </InfiniteScroll> */}
        </div >
    )
}

export default Patientdashboard