import React, { useState,useEffect } from 'react'
import { adverseFields } from '../../../../utils/jsonFiles/ecrfJson/adverse'
import { handleGetEnrollmentData,unescapeCRFData } from '../../../../util/helpers/helperfunctions';
import * as _ from "lodash";
import {ECRFform} from '../../../../util/helpers/eCRFform';

let ecrfAdverseEvents = {};

const Adversefields = ({subjectId,accessRights}) => {
    const [fieldValues, setFieldValues] = useState([]);

    const getEcrfAdverseFields = async () => {
        const response = await handleGetEnrollmentData(`eCRF_adverseevent:${subjectId}`)
        //console.log(response,"devicemalfunction data")
        ecrfAdverseEvents = response[0]?.features?.adverseevents?.properties?.values;
        if (ecrfAdverseEvents) {
            ecrfAdverseEvents = unescapeCRFData(ecrfAdverseEvents);
            setFieldValues(ecrfAdverseEvents);
        } else {
            ecrfAdverseEvents = {};
        }
    }
    useEffect(() => {
        getEcrfAdverseFields()
    }, [])


    return (
        <ECRFform formName="adverseevents" eCRFfields={adverseFields} fieldValues={fieldValues} 
                  namespace="eCRF_adverseevent" identifier={subjectId} accessRights = {accessRights}
        /> 
    )
}

export default Adversefields