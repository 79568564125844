import React, { useState, useContext } from 'react';
import {
  IonInput,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonImg,
  IonContent,
  IonCheckbox
} from '@ionic/react';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import '../styles/LoginForm.css';
import "../fonts.css";
import { handleLogin, handleLogout, handleRefreshToken, handleRegisterUser, parseJwt, getUserInfo } from "../service/adminUI";
import { useHistory } from 'react-router';
import AppContext from './AppContext';
import { baseurl, authStateDelimiter, authStateDuration, authStateUserAgent } from "../util/config";




export const LoginForm = () => {

  const [message, setMessage] = useState('Login Successful. Please wait');
  const [toastClass, setToastClass] = useState('toast-invisible');
  const [refreshToken, setRefreshToken] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [userId, setUserId] = useState('');
  const history = useHistory();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [totp, setTotp] = useState('');
  const [rememberme, setRememberme] = useState(false);

  const { appData, setAppData, refreshTimerId, setRefreshTimerId } = useContext(AppContext);

  const { setUserData } = useContext(AppContext);

  const OAUTH_URL = baseurl + 'auth/admin/calyan/console/'
  const LOGIN_ACTION_URL = baseurl + 'auth/realms/calyan/account/'
  const FETCH_TOKEN_FREQ = 240000; // 4 minutes 

  //login••••
  const handleLoginOnClick = async () => {
    const result = await handleLogin(username, password, totp);
    if (result == 400) {
      const css = 'toast' + ' ' + 'toast-partial';
      setToastClass(css);
      //clarityxdevfd3@gmail.com
      setMessage(<body>Partial Account Setup..Please <a href="" onClick={() => openInNewTab()} > click here</a>, to complete the account setup</body>);
    } else if (result == 401) {
      const css = 'toast' + ' ' + 'toast-error';
      setToastClass(css);
      setMessage('Login unsuccessful, Please check your details');
    } else {
      const css = 'toast' + ' ' + 'toast-success';
      const parsedData = parseJwt(result.access_token);

      setMessage('Login Successful. Please wait');

      //set refresh token and access token for further fetch calls
      startFetchingRefreshToken(result.refresh_token, result.access_token, appData);

      setToastClass(css);
      setTimeout(() => {
        localStorage.setItem("access_token", result.access_token)
        setAccessToken(result.access_token);
        setUserId(parsedData.sub);
        setRefreshToken(result.refresh_token);
        setUserData({ userId: parsedData.sub, userName: parsedData.name, userType: parsedData.type,emailId: parsedData.email });
        let role = localStorage.getItem("type")
        //console.log(role, "ye role hai")
        if (role == "ecrfadmin") {
          history.push("/dashboard/ecrf")
        } 
        else if (role == "clinician") {
          history.push("/dashboard/patientdashboard")
        } else {
          history.push({ pathname: '/home/widget' });
        }

      }, 2000);

      //process authstate if rememberMe
      if(rememberme) {
        //fetch the userinfo
        let userinfo = await getUserInfo(result.access_token);
        let IsAuthStateValid = false; 
        let authstate = null;
        let currentTime = new Date().getTime()/1000;
        currentTime = Math.round(currentTime);

        //check if authstate is not valid 
        if(userinfo?.attributes?.authstate) {
          authstate = Buffer.from(userinfo.attributes.authstate[0],'base64').toString('utf-8');
          let aAuthstateInfo = authstate.split('$');
          if((aAuthstateInfo[0] == userinfo.email) && ( aAuthstateInfo[1] > currentTime)) {
            IsAuthStateValid = true;
          }

        }
        //set valid authstate to remember the user 
          if(!IsAuthStateValid && userinfo?.attributes) {
          currentTime = currentTime + authStateDuration*24*3600;
          authstate = userinfo.email + authStateDelimiter +  currentTime  + authStateDelimiter + authStateUserAgent;

          if(!userinfo.attributes?.authstate) userinfo.attributes.authstate=[]; //define array if authstate is not present
          userinfo.attributes.authstate[0] =  Buffer.from(authstate).toString('base64');

          let userattrmodel = { "attributes" : {}}
          userattrmodel.attributes = userinfo.attributes
          handleRegisterUser(userinfo.attributes.type, userattrmodel,result.access_token, userinfo.id); //update the authstate
        }
      }
      
    }
    //handleLogout('');z
  };



  //redirect user to login page on session expiry
  const logoutOnTokenExpiry = async () => {
    //const result = await handleLogout(userId, appData.refreshToken, appData.accessToken);
    //if (result === 204) {
    //disable refresh token, clear context
    clearInterval(refreshTimerId.timerId);
    setRefreshTimerId({ timerId: null });
    setAppData({ count: 0, refreshToken: "", accessToken: "" });
    history.push('/login');
    //} else {
    const css = 'toast' + ' ' + 'toast-error';
    setToastClass(css);
    setMessage('Session expired, Please login again');
    //}
  }

  //start timer on login success, before this 
  //refresh token must be set from first login response
  function startFetchingRefreshToken(refreshtoken, accesstoken) {
    setAppData({ count: 1, refreshToken: refreshtoken, accessToken: accesstoken });
    var temp = 1;
    //start timer for background fetch refresh token
    var timerHandle = setInterval(async () => {
      temp++;
      //TODO use appData.refreshToken in handleRefreshToken
      const result = await handleRefreshToken(refreshtoken);
      if (result == 400 || result == 400) {
        await logoutOnTokenExpiry();
      }
      else {
        refreshtoken = result.refresh_token;
        localStorage.setItem("access_token",result.access_token)
        setAppData({ count: temp, refreshToken: result.refresh_token, accessToken: result.access_token });
      }
    }, FETCH_TOKEN_FREQ, refreshtoken);

    // update Context
    setRefreshTimerId({ timerId: timerHandle });
  }

  //get anchor tag with URL to open in new tab 
  const openInNewTab = () => {
    const newWindow = window.open(LOGIN_ACTION_URL, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };


  const handleUsernameOnChange = (event) => {
    //setUsername(event.currentTarget.value);
    setUsername(event.detail.value);
  };
  const handlePasswordOnChange = (event) => {
    //setPassword(event.currentTarget.value);
    setPassword(event.detail.value);
  };
  const handleEyeIconOnClick = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleOtpOnChange = (event) => {
    //setUsername(event.currentTarget.value);
    setTotp(event.detail.value);
  };
  const handleRememberMe = (event) => {
    //setUsername(event.currentTarget.value);
    setRememberme(event.detail.checked);
  };  
  
  return (
    <IonPage className='page'>
      <IonImg src='../../assets/icon/calyanLogo.png' className='logo' />
      <IonCardHeader className='header'>
        <IonCardTitle className='login-title'>Login</IonCardTitle>
      </IonCardHeader>

      <IonCardContent className='content'>
        Welcome back, Please login in to your account
      </IonCardContent>
      <IonContent className='underline' />
      <IonCard className='card'>


        <IonItem>
          <IonLabel className='login-label' position='stacked'>
            Email
          </IonLabel>
          <IonInput
            data-testid="TGETLOGINUSER"
            className='login-input'
            value={username}
            onIonChange={handleUsernameOnChange}
          ></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position='stacked' className='login-label'>
            Password
          </IonLabel>
          <IonInput
            data-testid="TGETLOGINID"
            className='login-input'
            type={passwordVisible ? 'text' : 'password'}
            value={password}
            onIonChange={handlePasswordOnChange}
          ></IonInput>
          <IonIcon
            className='eye'
            icon={passwordVisible ? eyeOutline : eyeOffOutline}
            slot='end'
            onClick={handleEyeIconOnClick}
          />
        </IonItem>

        <IonItem>
           <IonLabel position='stacked' className='login-label'>
            OTP
          </IonLabel>
          
          <IonInput 
            data-testid="TGETLOGINOTP"
            className='login-input'
            type= 'text'
            value={totp}
            placeholder='Enter Code'
            onIonChange={handleOtpOnChange}
          ></IonInput>
          <IonLabel>Remember Me</IonLabel>
          <IonCheckbox onIonChange={handleRememberMe}></IonCheckbox>

        </IonItem>   
     
      </IonCard>
      <IonButton
        data-testid="TGETLOGINDATA"
        class='loginButton'
        fill='solid'
        shape='round'
        onClick={handleLoginOnClick}
      >
        Login
      </IonButton>
      <div data-testid="TGETLOGINMSG" className={toastClass}>{message}</div>
    </IonPage>

  );
};
export default LoginForm;
