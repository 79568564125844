const deviceModel = {
	"macid": "01-AB-23-CD-45-EF",	
	"serialnumber": "XYZ089010ABC",
	"mfgdate": "2021/06/15",
	"blekey_mfg": "text@#",
	"blekey_patient": "text@#",
	"blekey_clinician": "text@#",
	"model": "P200",
	"technology": "VVIP",
	"version": {
		"sw": {
			"minor": "0.1",
			"major": "2.3.1"
		},
		"hw": "0.0.2",
		"fw": "1.245",
		"implant": false
	}
}

export default deviceModel;