// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyACMZm9l_jH9lAFxIXEkE9ArkQXOhwvhGA",
  authDomain: "pmsfdb-74bbd.firebaseapp.com",
  projectId: "pmsfdb-74bbd",
  storageBucket: "pmsfdb-74bbd.appspot.com",
  messagingSenderId: "432424385842",
  appId: "1:432424385842:web:7e01234b6560f1bc542b2b",
  measurementId: "G-8B7K4DKQYY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)