
import React, { useState, useEffect } from 'react'
import { IonButton, IonGrid, IonImg, IonInput, IonTextarea, IonRow, IonCol, IonItem, IonNote, IonSpinner, IonIcon, IonLabel} from '@ionic/react';
import * as _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faFileImage } from '@fortawesome/free-solid-svg-icons'
import { close } from 'ionicons/icons'
//firebase
import { storage } from '../../config/firebase';
import { ref, uploadBytes, listAll, getDownloadURL, uploadBytesResumable,deleteObject } from "firebase/storage"


export var uploadedFiles='';

export const FileAttachment = props => {
       
  const [uploadPercent, setUploadPercent] = useState(0)
  const [fileList, setFileList] = useState([])  
  const [existingFileList, setExistingFileList] = useState([])   
  const [isLoadingInProgress,setIsLoadingInProgress] = useState(false)   

    const handleFileUpload = (e) => {
      let files = Array.from(e.target.files);
      
      files = _.filter(files,function (o) { return o.name.indexOf('pdf') != -1; });
      if(_.isEmpty(files)) {
        if(props.errorSubject) {
            props.errorSubject.next("Please retry upload with pdf files"); //CFG notification of file uploads
        }
      }
    //jest testing
    if(process.env.JEST_WORKER_ID !== undefined) {
        uploadedFiles = files[0].name;
    } else {
      files.map((file, index) => {
      const storageRef = ref(storage, `/files/${file.name}`);

      // progress can be paused and resumed. It also exposes progress updates.
      // Receives the storage reference and the file to upload.
      const uploadTask = uploadBytesResumable(storageRef, file);
      setIsLoadingInProgress(true);

      uploadTask.on(
          "state_changed",
          (snapshot) => {
              const percent = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );

              // update progress
              setUploadPercent(percent);
          },
          (err) => console.log(err),
          () => {
              // download url
              getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                  //setImageUpload([...imageUpload, url])
                  props.fileUploaded.set(file.name,url);
                  setFileList([...fileList,file.name])
                  setIsLoadingInProgress(false);
                  
                  if(props.fileAttachmentSubject) {
                    props.fileAttachmentSubject.next({fieldName: props.item.fieldName, fileName:file.name, fileurl: url, action: true}); //notification of file uploads
                }
              });
          }
      );     
      }) }  
  }

  const handleFileDelete= (selectedFile) => {
    
      let url = props.fileUploaded.get(selectedFile); //url to delete
      const storageRef = ref(storage,url);
      deleteObject(storageRef).then(() => {
        if(props.fileAttachmentSubject) {
            props.fileAttachmentSubject.next({fieldName: props.item.fieldName, fileName:selectedFile , fileurl: url, action: false}); //notification of file delete
        }
          props.fileUploaded.delete(selectedFile);
          setFileList(Array.from(props.fileUploaded.keys()));

        }).catch((error) => {
          // Failed, 
        }); 

  } 

    useEffect(async () => {
      setExistingFileList(props.fieldValues);
    }, [props.fieldValues])

    return (   
          <div style={{ display: 'flex', justifyContent: "space-between", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px solid #535352", alignItems: "center" }}>
       
           <IonGrid>   
           <IonRow class="ion-justify-content-end">
          <label style={{ fontSize: "14px", color: "#535352", width:"65%" }}>{props.item.fieldName}</label>
          {isLoadingInProgress && <IonSpinner name="crescent"> </IonSpinner>}
          <input data-testid={props.item.fieldName} name={props.item.fieldName} onChange={handleFileUpload} onClick={e => e.target.value = null}  type={props.item.fieldType} accept="application/pdf"/>
          </IonRow>
          <IonRow class="ion-justify-content-end">
                  { existingFileList && existingFileList.map((item, index) => {
                        let splitPaths = item.split('/');  
                        let lastPath = splitPaths[splitPaths.length-1].split('?');
                        let decodedFilename = decodeURIComponent(lastPath[0])
                        let filename = decodedFilename.split('/');
                        let filetype = (filename[filename.length-1].indexOf(".pdf") == -1)?"png":"pdf"

                        return (
                            <IonItem color="light"  lines="none" >
                                <FontAwesomeIcon icon={filetype=="png"?faFileImage:faFilePdf} />
                                <IonItem color="light" href={item} target="_blank" rel="noopener noreferrer" lines="none">
                                    <IonLabel>{filename[filename.length-1]}</IonLabel>
                                </IonItem>
                            </IonItem>                                                                                                            
                        )
                    })
                }             


                { fileList.map((selectedFile, index) => {
                    return(
                            <IonCol class='ion-no-margin ion-no-padding ion-padding-end' size="auto">
                                <IonItem color="light"  lines="none">
                                <IonLabel data-testid={props.item.fieldName+"TFILENAME"+"_"+index}>{selectedFile}</IonLabel>
                                <IonButton size="small" fill="clear" onClick={(e) => handleFileDelete(selectedFile)}>
                                <IonIcon icon={close}></IonIcon>
                                </IonButton>
                                </IonItem>

                            </IonCol>    
                        
                    )
                })}
                </IonRow>  
                </IonGrid>
                 </div>         
          
    )
     
}