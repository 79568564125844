import React from 'react';

const AppContext = React.createContext(null);


//const refreshToken  = "Hey";

const AppContextProvider = AppContext.Provider;
const AppContextConsumer= AppContext.Consumer;


export {AppContextProvider, AppContextConsumer};
export default AppContext;
