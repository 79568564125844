import React, {useContext, useState} from 'react';
import {
    IonPage,
    IonImg,
    IonButtons,
    IonMenuButton, IonHeader, IonContent, IonButton
} from '@ionic/react';

import "../styles/Home.css";
import "../styles/Menu.css";
import Menu from "./Menu";
import {handleLogout} from '../service/adminUI';
import {Route, Switch} from "react-router-dom";
import {useHistory} from 'react-router';
import {menuController} from "@ionic/core";
import AppContext from './AppContext';
import Widget from "./Widget";
import RegisterDevice from "./RegisterDevice";
import RegisterPatient from "./RegisterPatient";
import RegisterClinician from "./RegisterClinician";
import AssignDevice from "./AssignDevice";
import Revoke from "./Revoke";
import Filter from "./Filter";
import { baseurl } from "../util/config";

const Home = props => {
    const TOAST_ERROR_CSS = 'update-pwd-toast' + ' ' + 'toast-error';
    const MAIN_FOOTER_ENABLE_CSS = 'homeMainFooter';
    const MAIN_FOOTER_DISABLE_CSS = 'homeMainFooter-invisible';

    const [message, setMessage] = useState('Loading..Please wait');
    const [toastClass, setToastClass] = useState('toast-invisible');
    const [mainFooterClass, setMainFooterClass] = useState(MAIN_FOOTER_DISABLE_CSS);
    const [toastVisible, setToastVisible] = useState(false);
    const [registerToastVisible, setRegisterToastVisible] = useState(false);
    const [updatePasswordCaption, setUpdatePasswordCaption] = useState('Update Password');
    const OAUTH_URL = baseurl + 'auth/admin/calyan/console/'
    const LOGIN_ACTION_URL = baseurl + 'auth/realms/calyan/account/'

    const {appData, userData, pageData, setAppData, setPageData} = useContext(AppContext);
    const {refreshTimerId, setRefreshTimerId} = useContext(AppContext);

    const {userId, userName} = userData;
    const [backgroundImage, setBackgroundImage] = useState('widget.png');
    const [registerRequestFormId, setRegisterRequestFormId] = useState('');
    const [cancelRequestFormId, setCancelRequestFormId] = useState('');

    const history = useHistory();
    window.menuController = menuController;

    //logout
    const handleLogoutOnClick = async () => {
        const result = await handleLogout(userId, appData.refreshToken, appData.accessToken);
        if (result === 204) {
            //disable refresh token, clear context
            clearInterval(refreshTimerId.timerId);
            setRefreshTimerId({timerId: null});
            setAppData({count: 0, refreshToken: "", accessToken: ""});
            history.push('/login');
        } else {
            autoHideToast(TOAST_ERROR_CSS, "Error: " + result.error);
        }
    }

    //home 
    const handleHomeOnClick = async () => {
        await handleHamMenuCloseOnClick();
        setBackgroundImage('widget.png');
        history.push({pathname: '/home/widget'});
    }

    //menu close
    const handleHamMenuCloseOnClick = async () => {
        const isMenuOpen = await menuController.isOpen();
        if (isMenuOpen) {
            const value = await menuController.close();
            console.log('Menu close called :' + value);
        }
    }

    //password update
    const handlePasswordUpdateOnClick = async () => {
        switch (updatePasswordCaption) {
            case LOGIN_ACTION_URL:
                openInNewTab();
                const value = await menuController.close();
                console.log('Menu close invoked from Update Password' + value);
                break;
            case 'Update Password':
                const isMenuOpen = await menuController.isOpen();
                if (isMenuOpen) {
                    //change password button content --> clickabale url
                    setUpdatePasswordCaption(LOGIN_ACTION_URL);
                }
                
                break;
        }
    }

    //Show/Hide toast
    const showHideToast = (showtoast, css, message) => {
        if (showtoast) {
            setToastVisible(true);
            setToastClass(css);
            setMessage(message);
        } else {
            setToastVisible(false);
        }
    }

    //Auto Hide toast after 5 seconds
    const autoHideToast = (css, message) => {
        setToastClass(css);
        setMessage(message);
        setToastVisible(true);
        setTimeout(() => {
            setToastVisible(false);
        }, 5000);
    }

    //open url in new tab
    const openInNewTab = () => {
        const newWindow = window.open(LOGIN_ACTION_URL, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    //handle widget on click
    const handleWidgetOnClick = (event) => {
        const id = event.currentTarget.id;
        setBackgroundImage(id + ".png");
        if (id == "registerDevice" || id == "registerPatient" || id == "registerClinician")
            setMainFooterClass(MAIN_FOOTER_ENABLE_CSS);
        setPageData({formId: id});
        history.push(`/home/${id}`);
    }

    const handleBckImgChange = (name) => {
        setBackgroundImage(name);
    }

    const handleFooterAction = (show) => {
        setMainFooterClass(show === true ? MAIN_FOOTER_ENABLE_CSS : MAIN_FOOTER_DISABLE_CSS);
    }

    const handlerCancelFormOnClick = () => {
        setCancelRequestFormId(pageData.formId);
    }

    const handleRegisterOnClick = (formId) => {
        setRegisterRequestFormId(pageData.formId);
    }

    const handleSetRegisterToast = (toastParams) => {
        setToastClass(toastParams.css);
        setMessage(toastParams.message);
        setRegisterToastVisible(true);
        setTimeout(() => {
            setRegisterToastVisible(false);
        }, 5000);
    }

    const resetRequest = () => {
        setRegisterRequestFormId('');
        setCancelRequestFormId('');
    }

    return (
        <IonPage className="landing-page">
            <Menu userName={userName} updatePassword={updatePasswordCaption} onHome={handleHomeOnClick}
                  onUpdatePassword={handlePasswordUpdateOnClick} onLogout={handleLogoutOnClick}
                  onMenuClose={handleHamMenuCloseOnClick}/>
            <IonButtons className="menu" slot="end">
                <IonMenuButton side="end"/>
            </IonButtons>
            <IonHeader className="landing-page-header">
                <div>
                    {
                        toastVisible && <div className={toastClass}> {message} </div>
                    }
                </div>

                <IonImg src="../../assets/icon/calyanHomeLogo.png" className="logo-home"/>
                <div className="img-container">
                    <IonImg className="dna-img" src={"../../assets/icon/" + backgroundImage}/>
                    <div className="img-heading">
                        True silence is the rest of the mind, and is to the spirit what sleep is to the body,
                        nourishment and refreshment
                    </div>
                </div>
            </IonHeader>
            <IonContent className="landing-page-main">
                <IonContent className="homeMain">
                    <Switch className="router-page">
                        <Route exact path="/home/registerDevice"
                               render={() => <RegisterDevice text="Register Device"
                                                             bckimgChange={handleBckImgChange}
                                                             registerRequest={registerRequestFormId}
                                                             cancelRequest={cancelRequestFormId}
                                                             setRegisterToast={handleSetRegisterToast}
                                                             resetRequest={resetRequest}/>}
                        />
                        <Route exact path="/home/registerPatient"
                               render={() => <RegisterPatient text="Register Patient"
                                                              bckimgChange={handleBckImgChange}
                                                              registerRequest={registerRequestFormId}
                                                              cancelRequest={cancelRequestFormId}
                                                              setRegisterToast={handleSetRegisterToast}
                                                              resetRequest={resetRequest}/>}
                        />
                        <Route exact path="/home/registerClinician"
                               render={() => <RegisterClinician text="Register Clinician"
                                                                bckimgChange={handleBckImgChange}
                                                                registerRequest={registerRequestFormId}
                                                                cancelRequest={cancelRequestFormId}
                                                                setRegisterToast={handleSetRegisterToast}
                                                                resetRequest={resetRequest}/>}
                        />
                        <Route exact path="/home/assignDevice"
                               render={() => <AssignDevice text="Assign Device" bckimgChange={handleBckImgChange}/>}
                        />
                        <Route exact path="/home/revoke">
                            <Revoke text="Revoke"/>
                        </Route>
                        <Route exact path="/home/widget"
                               render={(props) => <Widget
                                   handleWidgetOnClick={handleWidgetOnClick}
                                   bckimgChange={handleBckImgChange}
                                   footerAction={handleFooterAction}
                               />}
                        />
                        <Route exact path="/home/filter"
                               render={() => <Filter text="Filter page" bckimgChange={handleBckImgChange}/>}
                        />
                    </Switch>
                    <div className="toast-container">
                        <div>
                            {
                                registerToastVisible &&
                                <div className={toastClass + ' register-toast'}> {message} </div>
                            }
                        </div>
                    </div>
                    <div className={mainFooterClass}>
                        <IonButton
                            class='loginButton'
                            fill='solid'
                            shape='round'
                            onClick={handlerCancelFormOnClick}>
                            Cancel
                        </IonButton>
                        <IonButton
                            class='loginButton'
                            fill='solid'
                            shape='round'
                            onClick={handleRegisterOnClick}>
                            Register
                        </IonButton>
                    </div>
                </IonContent>
            </IonContent>
        </IonPage>
    );
};
export default Home;