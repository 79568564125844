export const implantFields = [
    {
        subject: "Visit Date",
        fields: [{
            fieldName: "Date of Visit:",
            fieldType: "date"
        }],
    },
    {
        subject: "Procedure Details",
        fields: [{
            fieldName: "Start time of procedure",
            fieldType: "time",
            tz:true
        },
        {
            fieldName: "End time of procedure",
            fieldType: "time",
            tz:true
        },
        {
            fieldName: "Size of incision",
            fieldType: "number",
            min:"0",
            isFloat:false,
            unit:"mm"
        },
        {
            fieldName: "Number of tunneling attempts",
            fieldType: "number",
            min:"0",
            isFloat:false
        },
        {
            fieldName: "Procedure Notes:",
            fieldType: "text"
        }

        ],
    },
    {
        subject: "Device Log",
        fields: [{
            fieldName: "Calyan Pacemaker Model #",
            fieldType: "text"
        },
        {
            fieldName: "Calyan Pacemaker Description",
            fieldType: "text"
        },
        {
            fieldName: "Calyan Pacemaker Delivery System Model #",
            fieldType: "text"
        },
        {
            fieldName: "Calyan Pacemaker Delivery System Description",
            fieldType: "text"
        },
        {
            fieldName: "Calyan Programmer Model #",
            fieldType: "text"
        },
        {
            fieldName: "Calyan Programmer Description",
            fieldType: "text"
        }

        ],
    },
    {
        subject: "Device Performance",
        fields: [{
            fieldName: "Device Preparation",
            fieldType: "select",
            values: [
                "1 = poor & unacceptable – unable to open package manually, requires scissors",
                "2= fair & acceptable –able to open package manually but with difficulty",
                "3= good & acceptable – able to manually open the package",
                "4 = very good & acceptable -- easy to open the package and access device"
            ]
        },
        {
            fieldName: "Use of Dilators to Create Tunnel",
            fieldType: "select",
            values: [
                "1 = poor & unacceptable – unable to open package manually, requires scissors",
                "2= fair & acceptable –able to open package manually but with difficulty",
                "3= good & acceptable – able to manually open the package",
                "4 = very good & acceptable -- easy to open the package and access device"
            ]
        },
        {
            fieldName: "Pushability",
            fieldType: "select",
            values: [
                "1 = poor & unacceptable – unable to open package manually, requires scissors",
                "2= fair & acceptable –able to open package manually but with difficulty",
                "3= good & acceptable – able to manually open the package",
                "4 = very good & acceptable -- easy to open the package and access device"
            ]
        },
        {
            fieldName: "Device Positioning",
            fieldType: "select",
            values: [
                "1 = poor & unacceptable – unable to open package manually, requires scissors",
                "2= fair & acceptable –able to open package manually but with difficulty",
                "3= good & acceptable – able to manually open the package",
                "4 = very good & acceptable -- easy to open the package and access device"
            ]
        },
        {
            fieldName: "Deployment / Retraction",
            fieldType: "select",
            values: [
                "1 = poor & unacceptable – unable to open package manually, requires scissors",
                "2= fair & acceptable –able to open package manually but with difficulty",
                "3= good & acceptable – able to manually open the package",
                "4 = very good & acceptable -- easy to open the package and access device"
            ]
        },
        {
            fieldName: "Visibility",
            fieldType: "select",
            values: [
                "1 = poor & unacceptable – unable to open package manually, requires scissors",
                "2= fair & acceptable –able to open package manually but with difficulty",
                "3= good & acceptable – able to manually open the package",
                "4 = very good & acceptable -- easy to open the package and access device"
            ]
        }
        ],
    },
    {
        subject: "Electrode Position Log",
        fields: [{
            fieldName: "Location Number",
            fieldType: "number"
        },
        {
            fieldName: "Cardiac Chamber",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Cardiac Chamber",
                    value: "Left Ventricle"
                },
                {
                    fieldName: "Cardiac Chamber",
                    value: "Right Ventricle"
                }
            ]
        },
        {
            fieldName: "Description of Position",
            fieldType: "text"
        },
        {
            fieldName: "Extra-cardiac Stimulation",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Extra-cardiac Stimulation",
                    value: "Yes"
                },
                {
                    fieldName: "Extra-cardiac Stimulation",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "Final",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Final",
                    value: "Yes"
                },
                {
                    fieldName: "Final",
                    value: "No"
                }
            ]
        }
        ],
    },
    {
        subject: "Stimulation Log",
        fields: [{
            fieldName: "Stimulation Location Number",
            fieldType: "text"
        },
        {
            fieldName: "Time",
            fieldType: "time",
            tz:true
        },
        {
            fieldName: "Rate",
            fieldType: "number",
            min:"0",
            isFloat:false
        },
        {
            fieldName: "Amplitude & Polarity",
            fieldType: "number",
            min:"0",
            isFloat:false
        },
        {
            fieldName: "Impedance",
            fieldType: "number",
            min:"0",
            isFloat:false
        },
        {
            fieldName: "Sensed Amp",
            fieldType: "number",
            min:"0",
            isFloat:false
        },
        {
            fieldName: "Capture",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Capture",
                    value:"Yes"
                },
                {
                    fieldName:"Capture",
                    value:"No"
                },
                {
                    fieldName:"Capture",
                    value:"Intermittent"
                }
              ]
        },
        {
            fieldName: "Comments",
            fieldType: "text"
        },
        ],
    },
    {
        subject: "Fluoroscopy Image",
        fields: [{
            fieldName: "Was fluoroscopy used to guide device implantation?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Was fluoroscopy used to guide device implantation?",
                    value:"No"
                },
                {
                    fieldName:"Was fluoroscopy used to guide device implantation?",
                    value:"Yes"
                }
                ]
        },
        {
            fieldName: "Was a fluoroscopy image of the final device image recorded?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Was a fluoroscopy image of the final device image recorded?",
                    value:"No"
                },
                {
                    fieldName:"Was a fluoroscopy image of the final device image recorded?",
                    value:"Yes"
                }]
        },
        {
            fieldName: "Is Fluoroscopy image available for upload?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Is Fluoroscopy image available for upload?",
                    value:"No"
                },
                {
                    fieldName:"Is Fluoroscopy image available for upload?",
                    value:"Yes"
                }]

        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE***",
            fieldType: "file",
            dependency: {
                fieldName: "Is Fluoroscopy image available for upload?",
                value: "Yes"
            }            
        },
        // {
        //     fieldName: "If no, please give reason, and complete a protocol deviation CRF",
        //     fieldType: "text"
        // },
        // {
        //     fieldName: "Comments (protocol deviation CRF)",
        //     fieldType: "text"
        // },
        ],
    },
    {
        subject: "12-Lead Electrocardiogram",
        fields: [{
            fieldName: "Was a 12-lead ECG performed?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Was a 12-lead ECG performed?",
                    value:"No"
                },
                {
                    fieldName:"Was a 12-lead ECG performed?",
                    value:"Yes"
                }]
        },
        {
            fieldName: "Is 12-lead ECG report available for upload?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Is 12-lead ECG report available for upload?",
                    value:"No"
                },
                {
                    fieldName:"Is 12-lead ECG report available for upload?",
                    value:"Yes"
                }]

        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE (12-lead ECG report)***",
            fieldType: "file",
            dependency: {
                fieldName: "Is 12-lead ECG report available for upload?",
                value: "Yes"
            }   
        },
        // {
        //     fieldName: "If no, please give reason, and complete protocol deviation CRF",
        //     fieldType: "text"
        // }
        ],
    },
    {
        subject: "Blood Collection",
        fields: [{
            fieldName: "Was blood collection performed after 4 hours?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Was blood collection performed after 4 hours?",
                    value:"No"
                },
                {
                    fieldName:"Was blood collection performed after 4 hours?",
                    value:"Yes"
                }]
        },
        {
            fieldName: "Is 4 hour blood collection report available for upload?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Is 4 hour blood collection report available for upload?",
                    value:"No"
                },
                {
                    fieldName:"Is 4 hour blood collection report available for upload?",
                    value:"Yes"
                }
                ]

        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE (4 hour blood collection report)***",
            fieldType: "file",
            dependency: {
                fieldName: "Is 4 hour blood collection report available for upload?",
                value: "Yes"
            }   
        },
        // {
        //     fieldName: "If no, please give reason, and complete the protocol deviation CRF",
        //     fieldType: "text"
        // },
        {
            fieldName: "Was blood collection performed after 8 hours?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Was blood collection performed after 8 hours?",
                    value:"No"
                },
                {
                    fieldName:"Was blood collection performed after 8 hours?",
                    value:"Yes"
                }]
        },
        {
            fieldName: "Is 8 hour blood collection report available for upload?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Is 8 hour blood collection report available for upload?",
                    value:"No"
                },
                {
                    fieldName:"Is 8 hour blood collection report available for upload?",
                    value:"Yes"
                }]

        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE*** (8 hour blood collection report)",
            fieldType: "file",
            dependency: {
                fieldName: "Is 8 hour blood collection report available for upload?",
                value: "Yes"
            } 
        },
        // {
        //     fieldName: "If no, please give reason, and finish protocol deviation CRF",
        //     fieldType: "text"
        // },
        {
            fieldName: "Was blood collection performed after 24 hours?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Was blood collection performed after 24 hours?",
                    value:"No"
                },
                {
                    fieldName:"Was blood collection performed after 24 hours?",
                    value:"Yes"
                }]
        },
        {
            fieldName: "Is 24 hour blood collection report available for upload?",
            fieldType: "radio",
            values: [
                {
                    fieldName:"Is 24 hour blood collection report available for upload?",
                    value:"No"
                },
                {
                    fieldName:"Is 24 hour blood collection report available for upload?",
                    value:"Yes"
                }]

        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE (24 hour blood collection report)***",
            fieldType: "file",
            dependency: {
                fieldName: "Is 24 hour blood collection report available for upload?",
                value: "Yes"
            } 
        },
        // {
        //     fieldName: "If no, please give reason, and complete a protocol deviation CRF",
        //     fieldType: "text"
        // }
        ],
    }
];