import React from 'react';
import {IonGrid, IonRow, IonCol, IonContent, IonImg, IonPage} from '@ionic/react';
import LoginForm from "./LoginForm";
import "../styles/Login.css";

const Login = () => (
    <IonContent>
      <IonGrid className="grid">
        <IonRow>
          <IonCol className="column">
              <LoginForm />
          </IonCol>
          <IonCol className="container column">
              <IonPage style={{height: '100%', minHeight: '100vh'}}>
                <img className="img" src="../../assets/icon/plus.png"/>
              </IonPage>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
);
export default Login;