import React, { useState, useEffect } from 'react'
import { protocoldeviationFields } from '../../../../utils/jsonFiles/ecrfJson/protocoldeviation'
import { handleGetEnrollmentData, unescapeCRFData } from '../../../../util/helpers/helperfunctions';
import * as _ from "lodash";
import {ECRFform} from '../../../../util/helpers/eCRFform';

let ecrfProtocolDeviationRecord = {};


const Protocoldeviation = ({ subjectId, accessRights }) => {
    const [fieldValues, setFieldValues] = useState({});    

    const getEcrfProtocolDeviation = async () => {
        const response = await handleGetEnrollmentData(`eCRF_protocoldeviation:${subjectId}`)
        // console.log(response,"protocoldeviation data")
        ecrfProtocolDeviationRecord = response[0]?.features?.protocoldeviation?.properties?.values;
        if (!ecrfProtocolDeviationRecord) {
            ecrfProtocolDeviationRecord = {};
        } else {
            ecrfProtocolDeviationRecord = unescapeCRFData(ecrfProtocolDeviationRecord);
        }
        setFieldValues(ecrfProtocolDeviationRecord);
    }
    useEffect(() => {
        getEcrfProtocolDeviation()
    }, [])

    return (
        <ECRFform formName="protocoldeviation" eCRFfields={protocoldeviationFields} fieldValues={fieldValues} 
                  namespace="eCRF_protocoldeviation" identifier={subjectId} accessRights = {accessRights}
        /> 
    )
}

export default Protocoldeviation