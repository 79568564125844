const clinicianModel = {
	"email": "xyz@gmail.com",
	"password" : "test",
	"firstName": "Sreekanth",
	"lastName": "Shetty",
	"phone": "+918234590123",
	"hospital": "AIIMS Delhi",
	"address": ["second main, 100ft Rd", "Indranagar Bangalore", "Karnataka Zip 578100"],
	"speciality" : "MD"
}
export default clinicianModel;