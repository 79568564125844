import React, {useContext, useEffect, useState} from 'react';
import "../styles/filter.css";
import {
    IonButton,
    IonInput,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption, IonToggle,
    IonChip,
    IonIcon, IonContent, IonRadioGroup, IonRadio, IonSpinner, IonDatetime
} from "@ionic/react";
import deviceOperations, {userOperationModel} from "../model/operation";
import user from "../model/user";
import deviceModel from "../model/device_model";
import {calendarOutline, closeCircle, checkmarkOutline} from 'ionicons/icons';
import {getAllDevices, getAllUsers, getFilteredDevices, getFilteredUsers} from "../service/adminUI";
import AppContext from "./AppContext";
import {useHistory, useLocation} from "react-router";

const Filter = props => {

    const {bckimgChange} = props;
    const [deviceAttributes, setDeviceAttributes] = useState({});
    const [userAttributes, setUserAttributes] = useState({});
    const {userFilterData, deviceFilterData, setUserFilterData, setDeviceFilterData} = useContext(AppContext);
    const location = useLocation();
    const history = useHistory();
    const [selectedAttribute, setSelectedAttribute] = useState({});
    const [selectedOperation, setSelectedOperation] = useState({});
    const [filterValue, setFilterValue] = useState('');
    const [selectedFilters, setSelectedFilters] = useState([]);

    const FILTER_CONDITION_ANY = 'or';
    const FILTER_CONDITION_ALL = 'and';
    const MODEL_TYPE_USER = 'user';
    const MODEL_TYPE_DEVICE = 'device';
    const [anyAllFilter, setAnyAllFilter] = useState('');
    const [modelType, setModelType] = useState('');
    const [dateAttributeSelected, setDateAttributeSelected] = useState(false);

    useEffect(() => {
        bckimgChange('assignDevice.png');
        if (location.state) {
            const {modelName} = location.state;
            setModelType(modelName);
            setAnyAllFilter(FILTER_CONDITION_ANY);
        }
    }, [])

    useEffect(() => {
        if (modelType == MODEL_TYPE_DEVICE) {
            const data = setAttributes(deviceModel, 'attributes', {});
            setDeviceAttributes(data);
            setSelectedFilters(deviceFilterData.deviceFilters)
        } else if (modelType == MODEL_TYPE_USER) {
            const data = setAttributes(user, 'attributes', {});
            setUserAttributes(data);
            setSelectedFilters(userFilterData.userFilters)
        }
    }, [modelType])

    useEffect(() => {
        if (selectedAttribute.key == "attributes/mfgdate") {
            setDateAttributeSelected(true);
        } else {
            setDateAttributeSelected(false);
        }
    }, [selectedAttribute])

    const setAttributes = (deviceModel, prefix, result) => {
        for (let key in deviceModel) {
            if (typeof (deviceModel[key]) == 'object') {
                //return setAttributes(deviceModel[key], prefix + '/' + key, result);
                return setAttributes(deviceModel[key], prefix + '/' + key, result);
            } else {
                result[prefix + '/' + key] = key;
            }
        }
        return result;
    }

    const handleShowResults = async () => {
        let filterExpression = '';
        //get all data when no filter selected
        if (selectedFilters == null || !(selectedFilters.length > 0)) {
            filterExpression = '';
        } else {
            if (selectedFilters.length == 1) {
                // no prefix for single expression
                filterExpression = selectedFilters[0].id;
            } else {
                for (let i = 0; i < selectedFilters.length; i++) {
                    filterExpression = `${filterExpression}${selectedFilters[i].id},`;
                }
                filterExpression = filterExpression.slice(0, -1);
                //append and-or condition prefix in expression
                filterExpression = (anyAllFilter == FILTER_CONDITION_ANY ?
                    FILTER_CONDITION_ANY : FILTER_CONDITION_ALL) + `(${filterExpression})`;
            }
        }
        //set app context for the filters and expressions
        modelType == MODEL_TYPE_USER ?
            (setUserFilterData({userFilters: selectedFilters, userFilterExpression: filterExpression})) :
            (setDeviceFilterData({deviceFilters: selectedFilters, deviceFilterExpression: filterExpression}));

        history.push({
            pathname: '/home/assignDevice',
            state: {modelType}
        });
    }

    const handleDeleteFilter = (chipToDelete) => () => {
        const chips = selectedFilters;
        setSelectedFilters([...chips.filter((chip) => chip.key !== chipToDelete.key)]);
    };

    const handleApplyOnClick = (model) => {
        let chips = [];
        if(model==MODEL_TYPE_DEVICE){
            chips = selectedFilters;
            chips. push({
                key: selectedAttribute.value + ' ' + selectedOperation.value, value: filterValue,
                id: selectedOperation.key + '(' + selectedAttribute.key + ',"' + filterValue + '")'
            });
        }
        else{
            chips.push({
                key: selectedAttribute.value + ' ' + selectedOperation.value, value: filterValue,
                id:  filterValue
            });
        }

        setSelectedFilters([...chips]);
        console.log('key:' + selectedAttribute + selectedOperation + ', value:' + filterValue);
    }

    const clearSelection = () => {
    }

    const compareWith = (o1, o2) => {
        return o1 && o2 ? o1.key === o2.key : o1 === o2;
    };

    const handleModelTypeToggle = (checked) => {
        checked ? setModelType(MODEL_TYPE_DEVICE) : setModelType(MODEL_TYPE_USER);
    }

    return (
        <div className="filter-container">
            <div className="chip-box">
                <IonItem className="filter" placeholder="Search by">
                    {(selectedFilters && selectedFilters.length > 0) ? selectedFilters.map((data) => {
                        return (<IonChip>
                            <IonLabel>{data.key + " " + data.value}</IonLabel>
                            <IonIcon icon={closeCircle}
                                     onClick={data.key === 'React' ? undefined : handleDeleteFilter(data)}/>
                        </IonChip>)
                    }) : null}
                </IonItem>
                <IonIcon className="filter-icon" icon={checkmarkOutline}    onClick={handleShowResults}/>
            </div>
            <div className="filter-box">
                <IonItem className="toggle-item">
                    <IonLabel className="option-align">USER</IonLabel>
                    <IonToggle className="toggle-option" checked={modelType == MODEL_TYPE_DEVICE}
                               onIonChange={e => (e.currentTarget.checked ?
                                   setModelType(MODEL_TYPE_DEVICE) :
                                   setModelType(MODEL_TYPE_USER))}/>
                    <IonLabel className="option-align">DEVICE</IonLabel>
                </IonItem>
                <IonItem className="toggle-item">
                    <IonLabel className="option-align">ANY</IonLabel>
                    <IonToggle className="toggle-option" checked={anyAllFilter == FILTER_CONDITION_ALL}
                               onIonChange={e => (e.currentTarget.checked ?
                                   setAnyAllFilter(FILTER_CONDITION_ALL) :
                                   setAnyAllFilter(FILTER_CONDITION_ANY))}/>
                    <IonLabel className="option-align">ALL</IonLabel>
                </IonItem>
            </div>
            <div className="filter-box filter-mob">
                <IonItem className="drop-down mob-view">
                    <IonSelect okText="Okay" cancelText="Dismiss" placeholder="Attribute"
                               className="select" interface="popover" compareWith={compareWith}
                               onIonChange={e => setSelectedAttribute({
                                   key: e.detail.value.key,
                                   value: e.detail.value.value
                               })}>
                        {Object.entries(modelType == MODEL_TYPE_DEVICE ? deviceAttributes : userAttributes).map((data) => (
                            <IonSelectOption className="popover-item" key={data[0]}
                                             value={{key: data[0], value: data[1]}}>
                                {data[1]}
                            </IonSelectOption>
                        ))}
                    </IonSelect>
                </IonItem>
                <IonItem className="drop-down mob-view">
                    <IonSelect okText="Okay" cancelText="Dismiss" placeholder="Operation"
                               className="select" interface="popover" compareWith={compareWith}
                               onIonChange={e => setSelectedOperation({
                                   key: e.detail.value.key,
                                   value: e.detail.value.value
                               })}>
                        {Object.entries(modelType == MODEL_TYPE_DEVICE ? deviceOperations : userOperationModel).map((data) => (
                                <IonSelectOption className="popover-item" key={data[0]}
                                                 value={{key: data[0], value: data[1]}}>
                                    {data[1]}
                                </IonSelectOption>
                            ))}
                    </IonSelect>
                </IonItem>
                {dateAttributeSelected ?
                    <IonItem className="value mob-view">
                        <IonDatetime key="mfgdate" name="mfgdate" displayFormat="YYYY/MM/DD"
                                     onIonChange={e => setFilterValue(e.detail.value.split('T')[0].replaceAll('-', '/').trim())}/>
                        <IonIcon
                            className="calendar-icon"
                            icon={calendarOutline}/>
                    </IonItem>

                    : <IonItem className="value mob-view"><IonInput placeholder="Enter value"
                                                                    onIonChange={e => setFilterValue(e.detail.value.trim())}/></IonItem>}


                <IonButton className="filter-button" onClick={e=>handleApplyOnClick(modelType)}>Apply</IonButton>
            </div>
        </div>
    );
};
export default Filter;