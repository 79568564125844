import React, { useState, useEffect } from 'react'
import { implantFields } from '../../../../utils/jsonFiles/ecrfJson/implant'
import { unescapeCRFData, handleGetEnrollmentData } from '../../../../util/helpers/helperfunctions';
import * as _ from "lodash";
import {ECRFform} from '../../../../util/helpers/eCRFform';

let ecrfImplant = {};

const Implant = ({ subjectId, accessRights }) => {
    const [fieldValues, setFieldValues] = useState([]);


    const getEcrfImplantFields = async () => {
        const response = await handleGetEnrollmentData(`eCRF_implant:${subjectId}`)
        //console.log(response,"devicemalfunction data")
        ecrfImplant = response[0]?.features?.implant?.properties?.values;
        if (ecrfImplant) {
            ecrfImplant = unescapeCRFData(ecrfImplant);
            setFieldValues(ecrfImplant);
        } else {
            ecrfImplant = {};
        }
    }
    useEffect(() => {
        getEcrfImplantFields()
    }, [])


    return (
        <ECRFform formName="implant" eCRFfields={implantFields} fieldValues={fieldValues} 
                  namespace="eCRF_implant" identifier={subjectId} accessRights = {accessRights}
        /> 

    )
}

export default Implant