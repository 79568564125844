export const studycompletionORterminationFields = [
    {
        subject: "Study Completion / Termination (6-Month, 9-Month, 12-Month, 18-Month)",
        fields: [{
            fieldName: "Did subject complete the study?",
            fieldType: "radio",
			values : [
				{
					fieldName:"Did subject complete the study?",
					value:"yes"
				},
				{
					fieldName:"Did subject complete the study?",
					value:"No"
				}
			]
        },
		{
            fieldName: "If Yes, please provide that Date of Completion",
            fieldType: "date"
        },
		{
            fieldName: "*If No, please provide Date of Discontinuation",
            fieldType: "date"
        },
		//TODO Protocol Non-compliance, Investigator decision, Other Reasons should have additionalonal Specify,  to be considered in conditional basis
		{
            fieldName: "*If No, please specify the primary reason for not completing the study (select only one)",
            fieldType: "select",
			values : [
			"Lost to follow-up",
			"Adverse Event",
			"Receiving another concurrent investigational drug",
			"Protocol Non-compliance",
			"Withdrawal of consent",
			"Pregnancy",
			"Investigator decision",
			"Sponsor request",
			"Other Reasons"
			]
        },		
		{
            fieldName: "Is the device ON or OFF at termination?",
            fieldType: "radio",
			values : [
				{
					value:"ON"
				},
				{
					value:"OFF"
				}
			]
        },		
		{
            fieldName: "Comments, if any:",
            fieldType: "text"
        }
		],
    }
];