import React from "react";
import {IonCol, IonList, IonRadio, IonRadioGroup, IonRow} from "@ionic/react";
import "../styles/userTable.css";

const UserTable = (props) => {

    const {data, onRadioChange} = props;

    return (
        <IonList>
            <IonRadioGroup onIonChange={onRadioChange}>
                {data.map((item) => {
                    return (
                        <IonRow className="users-row">
                            <IonCol className="table-cell" >{item.firstName} {item.lastName}</IonCol>
                            <IonCol  className="table-cell">{item.email}</IonCol>
                            <IonCol className="table-cell radio-button">
                                <IonRadio value={item.email + "&" + item.attributes.type[0] + "&" + item.id}/>
                            </IonCol>
                        </IonRow>
                    );
                })}
            </IonRadioGroup>
        </IonList>
    );
}

export default UserTable;