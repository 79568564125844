export const adverseFields = [
	{
		subject: "Adverse Event Form",
		fields: [{
			fieldName: "AE Diagnoses",
			fieldType: "text"
		},
		{
			fieldName: "Onset Date of Adverse Event",
			fieldType: "date"
		},
		{
			fieldName: "Onset Time of Adverse Event",
			fieldType: "time",
			tz:true
		},
		//TODO Protocol Non-compliance, Investigator decision, Other Reasons should have additionalonal Specify,  to be considered in conditional basis
		{
			fieldName: "Description of Adverse Event :",
			fieldType: "text"
		},
		{
			fieldName: "Report Date",
			fieldType: "date"
		},
		{
			fieldName: "Report Type",
			fieldType: "select",
			values: [
				"Initial Report",
				"Follow up Report"
			]
		},
		{
			fieldName: "Outcome:",
			fieldType: "select",
			values: [
				"Recovered without sequelae",
				"Recovered with sequelae",
				"Ongoing and Recovering",
				"Ongoing and Not Recovering",
				"Fatal",
				"Unknown"
			]
		},
		{
			fieldName: "If Recovered with or without sequalae please fill in Date of Outcome",
			fieldType: "date"
		},
		{
			fieldName: "Severity:",
			fieldType: "select",
			values: [
				"Mild",
				"Moderate",
				"Severe",
				"Not Assessable"
			]
		},
		//ZZZZ TODO Seriousness Criteria (Check all that apply), another verison of checkboxdate, nested subject
		{
				fieldName: "Seriousness Criteria (Check all that apply)",
				fieldType: "info"
		},
		{
				fieldName: "Death",
				fieldType: "checkbox"
		},
		{
				fieldName: "Cause of Death",
				fieldType: "text",
				dependency: {
					fieldName: "Death",
					value: true
				} 
		},
		{
			fieldName: "Date of Death",
			fieldType: "date",
			dependency: {
				fieldName: "Death",
				value: true
			} 
		},
		{
			fieldName: "Autopsy",
			fieldType: "radio",
			values : [
				{
					value: "No"
				},
				{
					value: "Yes"
				},
				{
					value: "Unknown"
				}								
			],
			dependency: {
				fieldName: "Death",
				value: true
			} 
		},
        {
            fieldName: "Attach Autopsy report",
            fieldType: "file",
            dependency: {
                fieldName: "Autopsy",
                value: "Yes"
            } 
        },
		{
			fieldName: "Life-threatening (immediately life-threatening at time of event)",
			fieldType: "checkbox"
		},
		{
			fieldName: "Hospitalization",
			fieldType: "radio",
			values: [{
				value: "Initial"
			},
			{
				value: "Prolonged"
			},
			{
				value: "Ongoing"
			}
				
			]
		},
		// {
		// 	fieldName: "Initial",
		// 	fieldType: "checkbox"
		// },
		// {
		// 	fieldName: "Prolonged",
		// 	fieldType: "checkbox"
		// },
		{
			fieldName: "Admission Date",
			fieldType: "date",
			dependency: {
                fieldName: "Hospitalization",
                value: "Prolonged"
            } 
		},
		{
			fieldName: "Discharge Date",
			fieldType: "date",
			dependency: {
                fieldName: "Hospitalization",
                value: "Prolonged"
            } 
		},	
		{
			fieldName: "Other (considered medically significant)",
			fieldType: "checkbox"
		},
		{
			fieldName: "Rationale",
			fieldType: "textarea",
			dependency: {
				fieldName: "Other (considered medically significant)",
				value: true
			} 			
		},		
		{
			fieldName: "Significant or persistent Disability/Incapacity",
			fieldType: "checkbox"
		},
		{
			fieldName: "Intervention required to prevent serious outcome",
			fieldType: "checkbox"
		},


		{
			fieldName: "Causality Relationship to:",
			fieldType: "radio",
			values: [
				{
					fieldName: "Causality Relationship to:",
					value: "Definitely"
				},
				{
					fieldName: "Causality Relationship to:",
					value: "Probable"
				},
				{
					fieldName: "Causality Relationship to:",
					value: "Possible"
				},
				{
					fieldName: "Causality Relationship to:",
					value: "Unlikely"
				},
				{
					fieldName: "Causality Relationship to:",
					value: "Not Related"
				},
			]
		},

		//ZZZZ TODO Medication discontinued, Medication dose changed, Medication added, Others...to be considered in conditional basis	

		],
	},
	{
		subject: "Action Taken",
		fields: [{
			fieldName: "Action Taken:",
			fieldType1: "checkbox",
			methods: [
				"None required","Stimulation turned OFF","Device removed","Medication discontinued (specify medication)",
				"Medication dose changed (specify medication)","Medication added (specify medication)","Other (specify)"]
			}	
		]
	},
	{
		subject: "Raw Data",
		fields: [{
			fieldName: "***SCAN Adverse Event Form AND PUT IN DATABASE***",
			fieldType: "file"
		}
		],
	}
];