import { GiHamburgerMenu } from "react-icons/gi"
import { FiMessageSquare } from "react-icons/fi"
import { AiOutlineFileSync } from "react-icons/ai"
import { IoMdPerson } from "react-icons/io"
import { BsFillPeopleFill } from "react-icons/bs"
export const dashOptions = [
    {
        "id": '1',
        "title": "eCRF",
        "logo":<GiHamburgerMenu />,
        "route":"ecrf",
        "role": ["ecrfadmin","clinician"]
    },
    {
        "id": '2',
        "title": "Data Sync",
        "logo":<AiOutlineFileSync/>,
        "route":"datasync",
        "role": ["clinician"]
    },
    {
        "id": '3',
        "title": "Patient Log",
        "logo":<IoMdPerson/>,
        "route":"patientlog",
        "role": ["clinician"]
    },
    {
        "id": '4',
        "title": "Messages",
        "logo":<FiMessageSquare/>,
        "route":"messages",
        "role": ["clinician"]
    },
    {
        "id": '5',
        "title": "Patient Dashboard",
        "logo":<BsFillPeopleFill/>,
        "route":"patientdashboard",
        "role": ["clinician"]
    },
]