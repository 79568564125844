import * as React from "react";

import { useCallback, useContext, useMemo, useState } from "react";

const UseAppDataContext = React.createContext(null);

function useAppData() {
    const appDataContext = useContext(UseAppDataContext);

    // if (appDataContext === null) {
    //     throw new Error(
    //         "useAuth() can only be used inside of <UseAuthProvider />, " +
    //         "please declare it at a higher level."
    //     );
    // }

    const { appData } = appDataContext;

    return useMemo(() => {
        return { ...appData };
    }, [appData]);
}

function UseAppDataProvider({ children }) {
    const authContext = useContext(UseAppDataContext);

    if (authContext !== null) {
        throw new Error("<UseAppContext /> has already been declared.");
    }


    const [appUserData, setAppUserData] = useState();
    const [allPatientData, setAllPatientData] = useState()
    const [selected, setSelected] = useState("Patient Dashboard")

    const appData = useMemo(
        () => ({
            appUserData,
            allPatientData,
            selected,
            setAppUserData,
            setAllPatientData,
            setSelected
        }),
        [appUserData, allPatientData, selected,
            setSelected,
            setAppUserData, setAllPatientData]);
    ;

    return (
        <UseAppDataContext.Provider
            value={{
                appData,
            }}
        >
            {children}
        </UseAppDataContext.Provider>
    );
}

function UseAppDataProviderWrapper(props) {
    return <UseAppDataProvider {...props} />;
}

export const withAuth = (Component) => {
    return (props) => {
        const auth = useAppData();

        return <Component auth={auth} {...props} />;
    };
};

export { UseAppDataProviderWrapper as UseAppDataProvider, useAppData };
