export const devicemalfunctionFields = [
    {
        subject: "Device Malfunction Form",
        fields: [{
            fieldName: "System Malfunction Type",
            fieldType: "select",
			values: [
			"Delivery System Failure",
			"FlexArm Failure",
			"Pacemaker Failure",
			"Programmer Failure",
			"Other, specify"
			]
        },
		{
            fieldName: "Battery Depletion",
            fieldType: "radio",
			values: [
                {
                    fieldName:"Battery Depletion",
                    value:"Yes"
                },
                {
                    fieldName:"Battery Depletion",
                    value:"No"
                }
			
			]
        },

		{
            fieldName: "Describe event:",
            fieldType: "text"

        },
		//TODO Protocol Non-compliance, Investigator decision, Other Reasons should have additionalonal Specify,  to be considered in conditional basis
		{
            fieldName: "Did the system malfunction cause an Adverse Event?",
            fieldType: "radio",
			values: [
                {
                    fieldName:"Did the system malfunction cause an Adverse Event?",
                    value:"Yes"
                },
                {
                    fieldName:"Did the system malfunction cause an Adverse Event?",
                    value:"No"
                }
			]
        },		
		{
            fieldName: "Corrective Action Taken:",
            fieldType: "text"
        }
		]
    },
    {
        subject: "Raw Data",
        fields: [{
            fieldName: "***SCAN Device Malfunction Form AND PUT IN DATABASE***",
            fieldType: "file"
        }
		],
    }	
];