export const ecrfTabs = [
    {
        "id": '1',
        "title": "ENROLLMENT",
    },
    {
        "id": '2',
        "title": "PRE IMPLANT VISIT",
    },
    {
        "id": '3',
        "title": "IMPLANT VISIT",
    },
    {
         "id": '4',
         "title": "FOLLOW UP",
    },
    {
        "id": '5',
        "title": "STUDY COMPLETION /TERMINATION",
    },
    {
        "id": '6',
        "title": "ADVERSE EVENTS",
    },
    {
        "id": '7',
        "title": "PROTOCOL DEVIATION",
    },
    {
        "id": '8',
        "title": "DEVICE MALFUNCTION",
    },
]