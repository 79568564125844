export const protocoldeviationFields = [
    {
        subject: "Protocol Deviation Form",
        fields: [{
            fieldName: "Date of Deviation:",
            fieldType: "date"
        },
		{
            fieldName: "Date Deviation reported",
            fieldType: "date"
        },
		//TODO Other specify to be considered in conditional basis
		{
            fieldName: "Type of Deviation",
            fieldType: "select",
			values: [
			"Informed consent not properly obtained",
			"Wrong version of Informed Consent signed",
			"Inclusion / Exclusion criteria not met",
			"Non-trained personnel performing study related procedures",
			"Trial procedures not followed / testing not completed",
			"Non compliance to study schedule",
			"Other, specify"
			]
        },
		//TODO Protocol Non-compliance, Investigator decision, Other Reasons should have additionalonal Specify,  to be considered in conditional basis
		{
            fieldName: "Describe Deviation:",
            fieldType: "text"
        },		
		{
            fieldName: "Corrective Action Taken:",
            fieldType: "text"
        },
		{
            fieldName: "Reported to IRB",
            fieldType: "select",
			values: [
			"Yes",
			"No",
			"N/A",
			]
        },
		//TODO Date Reported to IRB,  to be considered in conditional basis
		{
            fieldName: "Date Reported to IRB:",
            fieldType: "date"		
        },
		{
            fieldName: "If Recovered with or  without sequalae please fill in Date of Outcome",
            fieldType: "date"
        },
		{
            fieldName: "Comments::",
            fieldType: "text"	
        }	
		]
    },
    {
        subject: "Raw Data",
        fields: [{
            fieldName: "***SCAN Protocol Deviation Form AND PUT IN DATABASE***",
            fieldType: "file"
        }
		],
    }	
];