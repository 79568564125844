import React from 'react'
import calyanlogo from "../../assets/logos/calyanLogo.svg"
import { IonImg } from '@ionic/react'

const Report = () => {
  return (
    <div style={{width:"90%",height:"750px",overflowY:"scroll",backgroundColor:"white",borderRadius:"5px",paddingTop:"20px",paddingLeft:"30px",paddingRight:"30px"}}>
        <div style={{width:"100%",height:"250px",border:"2px solid black",display:"flex"}}>
            <div style={{width:"30%"}}>
                <IonImg style={{ width: '250px' }} src={calyanlogo}/>
            </div>
            <div style={{width:"100%"}}>
                
            </div>
        </div>
    </div>
  )
}

export default Report