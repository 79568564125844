import { useEffect, useState } from 'react'
import { devicemalfunctionFields } from '../../../../utils/jsonFiles/ecrfJson/devicemalfunction'
import { handleSaveEnrollmentData, handleGetEnrollmentData,unescapeCRFData,escapeCRFData } from '../../../../util/helpers/helperfunctions';
import * as _ from "lodash";
import { IonButton } from '@ionic/react';
import '../../ecrf.css';
import {ECRFform} from '../../../../util/helpers/eCRFform';

let ecrfdevicemalfunctionrecord = {}; //store the initial devicemalfunction record for the selected patient

const Devicemalfunction = ({ subjectId, accessRights }) => {
    const [fieldValues, setFieldValues] = useState([]);

    const getEcrfDeviceMalfunction = async () => {
        const response = await handleGetEnrollmentData(`eCRF_devicemalfunction:${subjectId}`)
        // console.log(response,"devicemalfunction data")
        ecrfdevicemalfunctionrecord = response[0]?.features?.devicemalfunction?.properties?.values;
        if (ecrfdevicemalfunctionrecord) {
            ecrfdevicemalfunctionrecord = unescapeCRFData(ecrfdevicemalfunctionrecord);
            setFieldValues(ecrfdevicemalfunctionrecord);
        } else {
            ecrfdevicemalfunctionrecord = {};
        }
    }

    useEffect(() => {
        getEcrfDeviceMalfunction()
    }, [])

    return (
        <ECRFform formName="devicemalfunction" eCRFfields={devicemalfunctionFields} fieldValues={fieldValues} 
                  namespace="eCRF_devicemalfunction" identifier={subjectId} accessRights = {accessRights}
        /> 
    )
}

export default Devicemalfunction