//FIX ME  hardcoded admin user id to be removed
const registerPolicyModel = {
    "policyId": "pacemaker:12-13-14-15-16-17-policy",
    "entries": {
        "DEFAULT": {
            "subjects": {
                "calyan:3814c6b9-c18c-43c8-8c8c-84d78d1e3d7b": {
                    "type": "clarityxdevsubject"
                }
            },
            "resources": {
                "policy:/": {
                    "grant": [
                        "READ",
                        "WRITE",
                        "EXECUTE"
                    ],
                    "revoke": []
                },
                "thing:/": {
                    "grant": [
                        "READ",
                        "WRITE"
                    ],
                    "revoke": []
                },
                "message:/": {
                    "grant": [
                        "READ",
                        "WRITE"
                    ],
                    "revoke": []
                }
            }
        },
		"historian": {
            "subjects": {
                "nginx:ditto": {
                    "type": "nginxuser"
                }
            },
            "resources": {
                "thing:/": {
                    "grant": [
                        "READ","WRITE"
                    ],
                    "revoke": []
                },
                "message:/": {
                    "grant": [
                        "READ","WRITE"
                    ],
                    "revoke": []
                }
            }
        }
    }
}

export default registerPolicyModel;