import React from 'react';
import {
    IonContent,
    IonLabel,
    IonImg,
    IonMenu,
    IonList,
    IonItem,
    IonChip,
    IonAvatar,
    IonMenuToggle,
    IonIcon
} from '@ionic/react';
import { home, closeCircle } from 'ionicons/icons';
import '../styles/Menu.css';

const Menu = ({ userName, updatePassword, onHome, onUpdatePassword, onLogout, onMenuClose }) => (
    <IonMenu id="homeMenu" className="menu-inner" contentId="mainContent" side="end">
        <IonContent className="hamburger">

            <IonItem className="ham-top-item">
                <IonIcon className="ham-close" icon={closeCircle} slot="start" onClick={onMenuClose} />
                <IonChip className="ham-chip" slot="end" >
                    <IonLabel >Hi, {userName}</IonLabel>
                    <IonAvatar >
                        <IonImg src="../../assets/icon/avatar.png" />
                    </IonAvatar>
                </IonChip>
            </IonItem>

            <IonList className="ham-list">

                <IonItem className="ham-item" button onClick={onHome} routerDirection="none">
                    <IonMenuToggle auto-hide="false">
                        <IonLabel>Home</IonLabel>
                    </IonMenuToggle>
                    <IonIcon className="ham-item-icon" icon={home} slot="end" />
                </IonItem>
                <IonItem className="ham-item" button onClick={onUpdatePassword} routerDirection="none">
                    <IonLabel>{updatePassword}</IonLabel>
                    <IonIcon className="ham-item-icon" src="../../assets/svg/lock.svg" />
                </IonItem>
                <IonItem className="ham-item" button onClick={onLogout} routerDirection="none">
                    <IonMenuToggle auto-hide="false">
                        <IonLabel>Logout</IonLabel>
                    </IonMenuToggle>
                    <IonIcon className="ham-item-icon" src="../../assets/svg/logout.svg" />
                </IonItem>
            </IonList>
        </IonContent>
    </IonMenu>
);
export default Menu;