import { useState,useEffect } from 'react'
import { preImplant } from '../../../../utils/jsonFiles/ecrfJson/preimplant'
import { handleGetEnrollmentData,unescapeCRFData} from '../../../../util/helpers/helperfunctions';
import * as _ from "lodash";
import '../../ecrf.css';
import {ECRFform} from '../../../../util/helpers/eCRFform';

let ecrfPreImplant = {};

const Preimplant = ({subjectId,accessRights}) => {
    const [fieldValues, setFieldValues] = useState([]);

    const getEcrfPreImplantFields = async () => {
        const response = await handleGetEnrollmentData(`eCRF_preimplant:${subjectId}`)
        //console.log(response,"devicemalfunction data")
        ecrfPreImplant = response[0]?.features?.preimplant?.properties?.values;
        if (ecrfPreImplant) {
            ecrfPreImplant = unescapeCRFData(ecrfPreImplant);
            setFieldValues(ecrfPreImplant);
        } else {
            ecrfPreImplant = {};
        }
    }
    useEffect(() => {
        // preImplant.map((item, index) => {
        //     item.fields.map((item, index) => {
        //     if(item.fieldType == 'file') {
        //         if(!fileFieldsMap.has(item.fieldName)) {
        //             fileFieldsMap.set(item.fieldName,new Map());
        //          }

        //     }})})
        getEcrfPreImplantFields()
    }, [])


    // const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    //     console.log(values);
    //     setSubmitting(false);
    // }

    return (
//         <div>
//             <div className='ecrf_height' style={{ overflowY: "scroll", padding: "20px" }}>
//                 <form>
//                     {preImplant.map((item, index) => {
//                         return (
//                             <div key={index}>
//                                 <h4 style={{ color: "#535352" }}>{item.subject}</h4>
//                                 {item.fields.map((item, index) => {
//                                     return (
//                                         <div key={index}>

//                                             {item.fieldType != "radio" && item.fieldType != "select" && item.fieldType != "file" && !item.fieldType1 && (

//                                                 <div style={{ display: 'flex', justifyContent: "space-between", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px solid #535352", alignItems: "center" }}>
//                                                     <label style={{ fontSize: "14px", color: "#535352" }}>{item.fieldName}</label>
//                                                     <input data-testid={item.fieldName} name={item.fieldName} onChange={handleInputChange} className={item.fieldType} type={item.fieldType} value={fieldValues[item.fieldName]?.value ?? ''}/>
//                                                 </div >
//                                             )}
//                                             {item.fieldType != "radio" && item.fieldType != "select" && item.fieldType1 && 
//                                                 <div key={index} style={{ display: 'flex', justifyContent: "space-between", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px solid #535352", alignItems: "center" }}>
//                                                     <label data-testid={item.fieldName} style={{ fontSize: "14px", color: "#535352" }}>{item.fieldName}</label>     
//                                                 </div> &&            
//                                             (
                                                    
//                                                 item?.methods.map((method, index) => {

//                                                     return (
//                                                         <div key={index} style={{ display: 'flex', justifyContent: "space-between", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px solid #535352", alignItems: "center" }}>
// {/*                                                             <div style={{ fontSize: "14px", color: "#535352" }}>{item.fieldName}</div> */}
//                                                             <div style={{ color: "#535352" }}>{method}</div>
//                                                             {_.isEmpty(method.match(regpatternOthers))?
//                                                                 <div style={{ display: "flex", alignItems: "center" }}>
//                                                                 <input data-testid={item.fieldName+'_'+item.fieldType1+'_'+index} name={item.fieldName+'_'+item.fieldType1+'_'+index} onChange={handleInputChange} style={{ marginRight: "30px" }} className={item.fieldType1} type={item.fieldType1} value={fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value ?? ''} checked={fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value??false}/>
//                                                                 {fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value && <input data-testid={item.fieldName+'_'+item.fieldType2+'_'+index} name={item.fieldName+'_'+item.fieldType2+'_'+index} onChange={handleInputChange} className={item.fieldType2} type={item.fieldType2} value={fieldValues[item.fieldName+'_'+item.fieldType2+'_'+index]?.value ?? ''}/>}
//                                                             </div>
//                                                             :<div style={{ display: "flex", alignItems: "center" }}>
//                                                                 <input data-testid={item.fieldName+'_'+item.fieldType1+'_'+index} name={item.fieldName+'_'+item.fieldType1+'_'+index} onChange={handleInputChange} style={{ marginRight: "30px" }} className={item.fieldType1} type={item.fieldType1} value={fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value ?? ''} checked={fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value??false}/>
//                                                                 {fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value && <input data-testid={item.fieldName+'_'+'text'+'_'+index} name={item.fieldName+'_'+'text'+'_'+index} onChange={handleInputChange} className="text" type="text" value={fieldValues[item.fieldName+'_'+'text'+'_'+index]?.value ?? ''}/>}
//                                                             </div>}
//                                                         </div >
//                                                     )
//                                                 })
//                                             )}
//                                             {
//                                                 item.fieldType == "select" && (
//                                                     <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #535352", paddingBottom: "15px", paddingTop: "15px" }}>
//                                                         <label style={{ color: "#535352" }} for="cars">{item.fieldName}</label>

//                                                         <select fieldType={item.fieldType} data-testid={item.fieldName} name={item.fieldName} onChange={handleInputChange} className='select' id="cars">
//                                                         {   fieldValues[item.fieldName]?.value? 
//                                                                 item.values.map((valelement, index) => {
//                                                                 return (
//                                                                     <option value={valelement} selected={valelement==fieldValues[item.fieldName]?.value}>{valelement}</option>
//                                                                 )
//                                                             }):
//                                                             item.values.map((item, index) => {
//                                                                 return (
//                                                                     <option value={item} >{item}</option>
//                                                                 )
//                                                             })}
//                                                         </select>
//                                                         {!_.isEmpty(item.values.find((valelement, index) => { return valelement.match(regpatternOthers)}))?
//                                                         <input data-testid={item.fieldName+'_'+'text'} name={item.fieldName+'_'+'text'} onChange={handleInputChange} className="text" type="text" value={fieldValues[item.fieldName+'_'+'text']?.value ?? ''}/>
//                                                         :null} 
                                                                                                               


//                                                     </div>
//                                                 )
//                                             }                                            
//                                             {item.fieldType == "radio" && (
//                                                 <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #535352", paddingBottom: "15px", paddingTop: "15px" }}>
//                                                     <div style={{ fontSize: "14px", color: "#535352" }}>{item.fieldName}</div>
//                                                     <div style={{ display: "flex" }}>
//                                                         {item.values.map((item, index) => {
//                                                             return (
//                                                                 <div key={index} style={{ display: "flex", alignItems: "center" }}>
//                                                                     <label style={{ marginLeft: "10px", color: "#535352" }}>{item.value}</label>
//                                                                     {fieldValues[item.fieldName]?.value==item.value?
//                                                                         <input name={item.fieldName} data-testid={item.fieldName+'_'+item.value} onChange={handleInputChange} value={item.value} style={{ marginLeft: "5px" }} type="radio" checked/>:
//                                                                         <input name={item.fieldName} data-testid={item.fieldName+'_'+item.value} onChange={handleInputChange} value={item.value} style={{ marginLeft: "5px" }} type="radio" />
//                                                                     }
                                                                    
//                                                                 </div>
//                                                             )
//                                                         })}
//                                                     </div>
//                                                     {!_.isEmpty(item.values.find((valelement, index) => { return valelement.value.match(regpatternOthers)}))?
//                                                         <input data-testid={item.fieldName+'_'+'text'} name={item.fieldName+'_'+'text'} onChange={handleInputChange} className="text" type="text" value={fieldValues[item.fieldName+'_'+'text']?.value ?? ''}/>
//                                                         :null} 
//                                                 </div>
//                                             )} 
//                                             {item.fieldType == "file" && (

//                                              <FileAttachment item={item} fileUploaded={fileFieldsMap.get(item.fieldName)} fieldValues={fieldValues[item.fieldName]}/>
                                             
//                                             )}                                                                                       
//                                         </div>
//                                     )
//                                 })}
//                             </div>
//                         )
//                     })}
//                 </form>
//             </div>
//             {(accessRights?.policyowner?.type == 'ecrfadmin') && <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
//             {toastVisible && <IonNote slot='start' className={toastClass} style={{ height: "35px"}}> {message} </IonNote>}     
//                 <div>
//                     <IonButton data-testid="TDELETEDRAFT" class='deleteBtnColor' onClick={deleteDraftImplant} style={{ width: "220px", textAlign: "center", height: "35px", color: "white", fontSize: "13px", letterSpacing: "0.2em", borderRadius: "5px", marginTop: "20px", marginBottom: "20px" }}>DELETE DRAFT</IonButton>
//                     <IonButton data-testid="TSAVEDRAFT" class='saveBtnColor' onClick={savePreImplantData} style={{ width: "220px", textAlign: "center", height: "35px", backgroundColor: "#4D4D4D", color: "white", fontSize: "13px", letterSpacing: "0.2em", borderRadius: "5px", margin: "20px 20px 20px 40px" }}>SAVE</IonButton>
//                 </div>
//             </div>}
//         </div>
        <ECRFform formName="preimplant" eCRFfields={preImplant} fieldValues={fieldValues} 
                  namespace="eCRF_preimplant" identifier={subjectId} accessRights = {accessRights}
        />          
    )
}

export default Preimplant