import React, { useState,useEffect} from 'react'
import { enrollmentFields } from '../../../../utils/jsonFiles/ecrfJson/enrollment'
import "./enrollment.css"
import { handleGetEnrollmentData, unescapeCRFData } from '../../../../util/helpers/helperfunctions';
import * as _ from "lodash";
import {ECRFform} from '../../../../util/helpers/eCRFform';

let ecrfEnrollment = {};

const Enrollment = ({subjectId,accessRights,patientInfo}) => {
    const [fieldValues, setFieldValues] = useState([]);


    const getEcrfPreImplantFields = async () => {
        const response = await handleGetEnrollmentData(`eCRF_enrollment:${subjectId}`)
        //console.log(response,"devicemalfunction data")
        ecrfEnrollment = response[0]?.features?.enrollment?.properties?.values;
        if (!ecrfEnrollment) {
            ecrfEnrollment = {};
            ecrfEnrollment['Male or Female'] = {value:patientInfo.attributes.gender=='Male'?'M':'F', type:'radio'}; //ZZZZ modify if the field name/value for followUp visit changes
            ecrfEnrollment['Age 18 or above'] = {value:patientInfo.attributes.eligibility, type:'radio'};   //ZZZZ modify if the field name/value for followUp visit changes         
        } else {
            ecrfEnrollment = unescapeCRFData(ecrfEnrollment);
        }
        setFieldValues(ecrfEnrollment);
    }
    useEffect(() => {
        if(subjectId && patientInfo) getEcrfPreImplantFields()
    }, [])

    return (
        <ECRFform formName="enrollment" eCRFfields={enrollmentFields} fieldValues={fieldValues} 
                  namespace="eCRF_enrollment" identifier={subjectId} accessRights = {accessRights}
        /> 
    )
}

export default Enrollment