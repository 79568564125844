import React, { useState,useEffect } from 'react'
import { studycompletionORterminationFields } from '../../../../utils/jsonFiles/ecrfJson/studycompletionORtermination'
import { unescapeCRFData } from '../../../../util/helpers/helperfunctions';
import { handleGetEnrollmentData } from '../../../../util/helpers/helperfunctions';
import {ECRFform} from '../../../../util/helpers/eCRFform';
import * as _ from "lodash";

let ecrfStudyCompletionRecord = {};

const Studycompletion = ({ subjectId, accessRights }) => {
    const [fieldValues, setFieldValues] = useState([]);


    const getEcrfstudyCompletion = async() => {
        const response = await handleGetEnrollmentData(`eCRF_studycompletionORtermination:${subjectId}`)
        console.log(response,"studycompletion data")
        ecrfStudyCompletionRecord = response[0]?.features?.studycompletion?.properties?.values;
        if(ecrfStudyCompletionRecord)
        {
            ecrfStudyCompletionRecord = unescapeCRFData(ecrfStudyCompletionRecord);
            setFieldValues(ecrfStudyCompletionRecord);
        } else {
            ecrfStudyCompletionRecord = {};
        }
    }
    useEffect(() => {
        getEcrfstudyCompletion()
    }, [])
    

    return (
        <ECRFform formName="studycompletion" eCRFfields={studycompletionORterminationFields} fieldValues={fieldValues} 
                  namespace="eCRF_studycompletionORtermination" identifier={subjectId} accessRights = {accessRights}
        /> 

    )
}

export default Studycompletion
