import React from 'react';
import "../styles/Home.css";
import "../styles/Menu.css";

const Revoke = props => {
    return (
        <div>
            {props.text}
        </div>
    );
};
export default Revoke;