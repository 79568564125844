const registerUserModel = {
    "enabled": true,
    "attributes": {
        "type": "patient",
        "phone": "+918234590123",
        "hospital": "AIIMS Delhi", //for clinician
        "speciality" : "MD",       //for clinician
        "address": ["street no 178, Juhu", "Doddankundi Bangalore", "Karnataka Zip 578901"]
    },
    "emailVerified": false,
    "email": "clarityxdevfd4@gmail.com",
    "firstName": "Amar",
    "lastName": "Kumar",
    "requiredActions": ["UPDATE_PASSWORD"],//"VERIFY_EMAIL" after sendgrid integration
    "credentials": [{
        "type": "password",
        "value": "test"
    }]
};

export const updateUserModel = {
    "attributes": {
        "type": "patient",
        "phone": "+918234590123",
        "hospital": "AIIMS Delhi", //for clinician
        "speciality" : "MD",       //for clinician
        "address": ["street no 178, Juhu", "Doddankundi Bangalore", "Karnataka Zip 578901"]
    },
    "firstName": "Amar",
    "lastName": "Kumar",
}

export default registerUserModel;