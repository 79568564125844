import React, {useState, useContext, useEffect, Fragment} from 'react';
import "../styles/registerPatient.css";
import patientModel from "../model/patient_model";
import {IonButton, IonCardContent, IonTitle} from "@ionic/react";
import AppContext from "./AppContext";
import {handleRegisterUser, getFilteredUsers} from "../service/adminUI";
import registerUserModel from "../model/registerUserModel";
import {getPattern, mapFormDataWithModel} from "../util/dynamicForm";
import * as _ from "lodash";
import { updateUserModel } from '../model/registerUserModel';

let uniqueKey='email';
let userid='';

const RegisterPatient = props => {
    const {bckimgChange, registerRequest, cancelRequest, setRegisterToast, resetRequest} = props;
    const TOAST_ERROR_CSS = 'toast' + ' ' + 'toast-error';
    const TOAST_SUCCESS_CSS = 'toast' + ' ' + 'toast-success';
    const {appData} = useContext(AppContext);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [patientVal, setPatientVal] = useState([]);
    const patient = [];

    useEffect(() => {
        bckimgChange('registerPatient.png');
        setPatientVal(patient);
    }, []);

    useEffect(() => {
        if (registerRequest === 'registerPatient') {
            handleRegisterClinicianRequest();
        }
        if (cancelRequest === 'registerPatient') {
            clearForm();
            resetRequest();
        }
    }, [registerRequest, cancelRequest]);

    const handleRegisterClinicianRequest = async () => {
        const toastParams = await handleRegisterPatientOnClick();
        if (toastParams) {
            setRegisterToast(toastParams);
        }
        resetRequest();
    }

    const handleEyeIconOnClick = () => {
        setPasswordVisible(!passwordVisible);
    };

    const displayError = (id) => {
        document.getElementById(id).style = "display: visible; color: red; font-family: PoppinsRegular; font-size: 0.8rem";
    }

    const hideError = (id) => {
        document.getElementById(id).style = "display: none";
    }

    const validatePatientData = () => {
        let validationResult = true;
        const patientData = [...patientVal];
        for (let i = 0; i < patientData.length; i++) {
            if (patientData[i].pattern == "password") {
                if (patientData[i].value) {
                    patientData[i].isValid = true;
                    hideError(patientData[i].key);
                } else {
                    patientData[i].isValid = false;
                    validationResult = false;
                    displayError(patientData[i].key);
                }
            } else if (patientData[i].pattern == "array") {
                if (patientData[i].value && patientData[i].value.length > 0) {
                    patientData[i].isValid = true;
                    hideError(patientData[i].key);
                } else {
                    patientData[i].isValid = false;
                    validationResult = false;
                    displayError(patientData[i].key);
                }
            } else {
                if (patientData[i].value && patientData[i].value.match(patientData[i].pattern)) {
                    patientData[i].isValid = true;
                    hideError(patientData[i].key);
                } else {
                    patientData[i].isValid = false;
                    validationResult = false;
                    displayError(patientData[i].key);
                }
            }
        }
        setPatientVal(patientData);
        return validationResult;
    }

    const validateExistingPatientData = () => {
        let validationResult = true;
        const patientData = [...patientVal];
        for (let i = 0; i < patientData.length; i++) {
            //iterate through inputs that are set
            if(patientData[i].value != '') {
                if (patientData[i].pattern == "array") {
                    if (patientData[i].value && patientData[i].value.length > 0) {
                        patientData[i].isValid = true;
                        hideError(patientData[i].key);
                    } else {
                        patientData[i].isValid = false;
                        validationResult = false;
                        displayError(patientData[i].key);
                    }
                } else {
                    if (patientData[i].value && patientData[i].value.match(patientData[i].pattern)) {
                        patientData[i].isValid = true;
                        hideError(patientData[i].key);
                    } else {
                        patientData[i].isValid = false;
                        validationResult = false;
                        displayError(patientData[i].key);
                    }
                }
            }
        }
        setPatientVal(patientData);
        return validationResult;
    }    
//register device
    const handleRegisterPatientOnClick = async () => {
        userid = '';

        let auniqueObj = _.filter(patientVal,function (o) { return o.key==uniqueKey});
        let uniqueObj = !_.isEmpty(auniqueObj)?auniqueObj[0]:null; 
        if(!_.isEmpty(uniqueObj) && uniqueObj?.value != '' && uniqueObj.value.match(uniqueObj?.pattern)) {
            //fetch the entity
            let entityVal = await getFilteredUsers(appData.accessToken, uniqueObj?.value);
            if(!_.isEmpty(entityVal) && entityVal[0]?.attributes && entityVal[0]?.attributes.type && entityVal[0]?.attributes.type[0] == 'patient') {
                userid = entityVal[0].id;
            }
        }

        if(userid != '') {
            if (validateExistingPatientData()) {
                const finalModel = mapFormDataWithModel(updateUserModel, patientVal);
                const result = await handleRegisterUser(finalModel.attributes.type, finalModel, appData.accessToken,userid);
                if (result == true) {
                    clearForm();
                    return {css: TOAST_SUCCESS_CSS, message: "Patient registration succeeded!"};
                } else {
                    return {css: TOAST_ERROR_CSS, message: "Error: " + result};
                }

            }
        } else {
            if (validatePatientData()) {
                const finalModel = mapFormDataWithModel(registerUserModel, patientVal);
                const result = await handleRegisterUser(finalModel.attributes.type, finalModel, appData.accessToken);
                if (result == true) {
                    clearForm();
                    return {css: TOAST_SUCCESS_CSS, message: "Patient registration succeeded!"};
                } else {
                    return {css: TOAST_ERROR_CSS, message: "Error: " + result};
                }

            }
        }
    }


    const handleOnInput = async (event) => {
        let value = event.target.value;
        const name = event.target.name;
        const patientData = [...patientVal];
        for (let i = 0; i < patientData.length; i++) {
            if (patientData[i].key == name) {
                if (patientData[i].pattern == 'array') {
                    value = value.split('\n');
                }
                patientData[i].value = value;
            }
        }

        if(name == uniqueKey && value != '') {
            let auniqueObj = _.filter(patientData,function (o) { return o.key==uniqueKey});
            let uniqueObj = !_.isEmpty(auniqueObj)?auniqueObj[0]:null; 
            if(!_.isEmpty(uniqueObj) && uniqueObj?.value != '' && uniqueObj.value.match(uniqueObj?.pattern)) {
                //fetch the entity
                let entityVal = await getFilteredUsers(appData.accessToken, uniqueObj?.value);
                if(!_.isEmpty(entityVal) && entityVal[0]?.attributes && entityVal[0]?.attributes.type && entityVal[0]?.attributes.type[0] == 'patient') {
                        
                    let username = {firstName:'0', lastName:'0', email:'0'};
                    for(var element in username) {
                    let idx = _.findIndex(patientData, function(o) { return o.key == element; });
                    if(idx != -1) {
                        patientData[idx].value = entityVal[0][element]; 
                        }
                    }
                    element = 'phone';
                    let idx = _.findIndex(patientData, function(o) { return o.key == element; });
                    if(idx != -1) {
                        patientData[idx].value = entityVal[0].attributes[element][0] ; 
                        }
                    element = 'address'   
                    idx = _.findIndex(patientData, function(o) { return o.key == 'address'; });
                    if(idx != -1) {
                        patientData[idx].value = entityVal[0].attributes[element]; 
                    }    
                }

                }
                                 

            
            
    }        
        setPatientVal(patientData);
    };

    const clearForm = () => {
        document.getElementById('patient-form').reset();
        document.getElementsByTagName('textarea')[0].value = '';
        setPatientVal(patient);
        for(let val in patientVal) {
            hideError(patientVal[val].key);
        }
    }

    return (
        <Fragment>
            <IonTitle className="patient-title">Patient Form</IonTitle>
            <IonCardContent className="patient-content">
                <form id='patient-form' className="patient-form">
                    {Object.entries(patientModel).map((data) => {
                        let idx = _.findIndex(patientVal, function(o) { return o.key == data[0]; });
                        return (
                            getPattern(data[0], data[1], 1, handleOnInput, patient, passwordVisible, handleEyeIconOnClick,idx != -1? patientVal[idx].value:patientVal)
                        );
                    })}

                </form>
            </IonCardContent>
        </Fragment>
    );
};
export default RegisterPatient;