import { DTAL } from "../../service/DTAL"
import * as _ from "lodash";

let dtalService = new DTAL(null);

function escapeJSON(string) {
  //return string.replace(/[\n"\&\r\t\b\f\/]/g, '$&');
  return encodeURIComponent(string)
}
export function unescapeJSON(string) {
  //return string.replace(/\\[\n"\&\r\t\b\f\\]/g, '$&');
  return decodeURIComponent(string)
}

export function escapeCRFData(values) {
    var retObj = values;
    //iterate through object to escape the key containing special character e.g. \
    if(values && !_.isEmpty(values)) {
    var escapedValues = {}  
    var escapedKey = "" 
    for (var key in values)
    {
        escapedKey = escapeJSON(key);
        escapedValues[escapedKey] = values[key];
    }
    retObj = escapedValues;
   }

    return retObj;
}


export function unescapeCRFData(values) {
    var retObj = values;  
    //iterate through object to unescape the key containing special character e.g. \
    if(values && !_.isEmpty(values)) {    
    var unescapedValues = {}  
    var unescapedKey = ""     
    for (var key in values)
    {
      unescapedKey = unescapeJSON(key);
      unescapedValues[unescapedKey] = values[key];
    }    
    retObj = unescapedValues;
  }
  return retObj;  
}

    
export const handleSaveEnrollmentData = async (namespace,deviceId,values,accessrights) => {
      await dtalService.create(`${namespace}:${deviceId}`, values, accessrights)
}

export const handleUpdateEnrollmentData = async (namespace,deviceId,values,accessrights) => {
    await dtalService.set(`${namespace}:${deviceId}`, values, accessrights)
}
export const handleGetEnrollmentData = async(identifier) => {
  let response =  await dtalService.get(identifier)
  return response
}


