import React, {useState, useContext, useEffect, Fragment} from 'react';
import "../styles/registerClinician.css";
import clinicianModel from "../model/clinician_model";
import {IonButton, IonCardContent, IonTitle} from "@ionic/react";
import {handleRegisterUser} from "../service/adminUI";
import registerUserModel from "../model/registerUserModel";
import AppContext from "./AppContext";
import {displayError, getPattern, hideError, mapFormDataWithModel} from "../util/dynamicForm";
import * as _ from "lodash";

const RegisterClinician = props => {
    const {bckimgChange, registerRequest, cancelRequest, setRegisterToast, resetRequest} = props;
    const TOAST_ERROR_CSS = 'toast' + ' ' + 'toast-error';
    const TOAST_SUCCESS_CSS = 'toast' + ' ' + 'toast-success';
    const {appData} = useContext(AppContext);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [clinicianVal, setClinicianVal] = useState([]);
    let clinician = [];

    useEffect(() => {
        bckimgChange('registerClinician.png');
        setClinicianVal(clinician);
    }, [])

    useEffect(() => {
        if (registerRequest === 'registerClinician') {
            handleRegisterClinicianRequest();
        }
        if (cancelRequest === 'registerClinician') {
            clearForm();
            resetRequest();
        }
    }, [registerRequest, cancelRequest]);

    const handleEyeIconOnClick = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleRegisterClinicianRequest = async () => {
        const toastParams = await handleRegisterClinicianOnClick();
        if (toastParams) {
            setRegisterToast(toastParams);
        }
        resetRequest();
    }

    const validateClinicianData = () => {
        let validationResult = true;
        const clinicianData = [...clinicianVal];
        for (let i = 0; i < clinicianData.length; i++) {
            if (clinicianData[i].pattern == "password") {
                if (clinicianData[i].value) {
                    clinicianData[i].isValid = true;
                    hideError(clinicianData[i].key);
                } else {
                    clinicianData[i].isValid = false;
                    validationResult = false;
                    displayError(clinicianData[i].key);
                }
            } else if (clinicianData[i].pattern == "array") {
                if (clinicianData[i].value && clinicianData[i].value.length > 0) {
                    clinicianData[i].isValid = true;
                    hideError(clinicianData[i].key);
                } else {
                    clinicianData[i].isValid = false;
                    validationResult = false;
                    displayError(clinicianData[i].key);
                }
            } else if (clinicianData[i].pattern == 'default') {
                if (clinicianData[i].value) {
                    clinicianData[i].isValid = true;
                    hideError(clinicianData[i].key);
                } else {
                    clinicianData[i].isValid = false;
                    validationResult = false;
                    displayError(clinicianData[i].key);
                }
            } else {
                if (clinicianData[i].value && clinicianData[i].value.match(clinicianData[i].pattern)) {
                    clinicianData[i].isValid = true;
                    hideError(clinicianData[i].key);
                } else {
                    clinicianData[i].isValid = false;
                    validationResult = false;
                    displayError(clinicianData[i].key);
                }
            }
        }
        setClinicianVal(clinicianData);
        return validationResult;
    }

//register clinician
    const handleRegisterClinicianOnClick = async () => {
        if (validateClinicianData()) {
            const finalModel = mapFormDataWithModel(registerUserModel, clinicianVal);
            const result = await handleRegisterUser("clinician", finalModel, appData.accessToken);
            if (result == true) {
                clearForm();
                return {css: TOAST_SUCCESS_CSS, message: "Clinician registration succeeded!"}
            } else {
                return {css: TOAST_ERROR_CSS, message: "Error: " + result}
            }
        }
    }

    const handleOnInput = (event) => {
        let value = event.target.value;
        const name = event.target.name;
        const clinicianData = [...clinicianVal];
        for (let i = 0; i < clinicianData.length; i++) {
            if (clinicianData[i].key == name) {
                if (clinicianData[i].pattern == 'array') {
                    value = value.split('\n');
                }
                clinicianData[i].value = value;
            }
        }
        setClinicianVal(clinicianData);
    };

    const clearForm = () => {
        document.getElementById('clinician-form').reset();
        document.getElementsByTagName('textarea')[0].value = '';
        setClinicianVal(clinician);
        for(let val in clinicianVal) {
            hideError(clinicianVal[val].key);
        }
    }

    return (
        <Fragment>
            <IonTitle className="clinician-title">Clinician Form</IonTitle>
            <IonCardContent className="clinician-content">
                <form id="clinician-form" className="clinician-form">
                    {Object.entries(clinicianModel).map((data) => {
                        let idx = _.findIndex(clinicianVal, function(o) { return o.key == data[0]; });
                        return (
                            getPattern(data[0], data[1], 1, handleOnInput, clinician, passwordVisible, handleEyeIconOnClick,idx != -1? clinicianVal[idx].value:clinicianVal)
                        );
                    })}
                </form>
            </IonCardContent>
        </Fragment>
    );
};
export default RegisterClinician;