const revokePolicyModel = {
    "subjects": {}
    ,
    "resources":
        {
            "thing:/":
                {
                    "revoke":
                        [
                            "READ",
                            "WRITE"
                        ],
                    "grant": []
                }
            ,
            "message:/":
                {
                    "revoke":
                        [
                            "READ",
                            "WRITE"
                        ],
                    "grant": []
                }
        }
}

export default revokePolicyModel;