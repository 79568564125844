import { values } from "lodash";

export const enrollmentFields = [
    {
        subject: "Visit Date",
        fields: [{
            fieldName: "Date of Visit",
            fieldType: "date"
        }],
    },
    {
        subject: "Informed consent",
        fields: [{
            fieldName: "Did subject sign the Informed Consent Form?",
            fieldType: "radio",
            values: [{
                fieldName: "Informed consent",
                value: "Yes"
            },
            {
                fieldName: "Informed consent",
                value: "No"
            }
            ]
        },
        {
            fieldName: "Date Informed Consent Signed",
            fieldType: "date"
        }
        ],
    },
    {
        subject: "Inclusion Criteria",
        fields: [{
            fieldName: "Male or Female",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Male or Female",
                    value: "M"
                },
                {
                    fieldName: "Male or Female",
                    value: "F"
                }
            ]
        },
        {
            fieldName: "Age 18 or above",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Age 18 or above",
                    value: "Yes"
                },
                {
                    fieldName: "Age 18 or above",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "Willing and capable of providing informed consent",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Willing and capable of providing informed consent",
                    value: "Yes"
                },
                {
                    fieldName: "Willing and capable of providing informed consent",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "Class I or Iia indication for implantation of a single-chamber ventricular pacemaker, according to ACC/AHA/HRS guidelines",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Class I or Iia indication for implantation of a single-chamber ventricular pacemaker, according to ACC/AHA/HRS guidelines",
                    value: "Yes"
                },
                {
                    fieldName: "Class I or Iia indication for implantation of a single-chamber ventricular pacemaker, according to ACC/AHA/HRS guidelines",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "A life expectancy of at last one year, and is suitable candidate based on overall health and well-being",
            fieldType: "radio",
            values: [
                {
                    fieldName: "A life expectancy of at last one year, and is suitable candidate based on overall health and well-being",
                    value: "Yes"
                },
                {
                    fieldName: "A life expectancy of at last one year, and is suitable candidate based on overall health and well-being",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "Patients in whom a substernal device implant should be avoided",
            fieldType: "info"
        },
        {
            fieldName: "1.a Any prior sternotomy",
            fieldType: "radio",
            values: [
                {
                    fieldName: "1.a Any prior sternotomy",
                    value: "Yes"
                },
                {
                    fieldName: "1.a Any prior sternotomy",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "1.b Any prior medical condition or prcoedure that leads to adhesions in the antrior mediastinal space",
            fieldType: "radio",
            values: [
                {
                    fieldName: "1.b Any prior medical condition or prcoedure that leads to adhesions in the antrior mediastinal space",
                    value: "Yes"
                },
                {
                    fieldName: "1.b Any prior medical condition or prcoedure that leads to adhesions in the antrior mediastinal space",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "1.c Any marked sternal abnormality, such as pectus excavatum or pectus carinatum",
            fieldType: "radio",
            values: [
                {
                    fieldName: "1.c Any marked sternal abnormality, such as pectus excavatum or pectus carinatum",
                    value: "Yes"
                },
                {
                    fieldName: "1.c Any marked sternal abnormality, such as pectus excavatum or pectus carinatum",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "1.d Prior abdominal surgery in the epigastric region",
            fieldType: "radio",
            values: [
                {
                    fieldName: "1.d Prior abdominal surgery in the epigastric region",
                    value: "Yes"
                },
                {
                    fieldName: "1.d Prior abdominal surgery in the epigastric region",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "1.e Planned sternotomy",
            fieldType: "radio",
            values: [
                {
                    fieldName: "1.e Planned sternotomy",
                    value: "Yes"
                },
                {
                    fieldName: "1.e Planned sternotomy",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "1.f Prior chest radiotherapy",
            fieldType: "radio",
            values: [
                {
                    fieldName: "1.f Prior chest radiotherapy",
                    value: "Yes"
                },
                {
                    fieldName: "1.f Prior chest radiotherapy",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "1.g Hiatal hernia that distorts medistinal anatomy",
            fieldType: "radio",
            values: [
                {
                    fieldName: "1.g Hiatal hernia that distorts medistinal anatomy",
                    value: "Yes"
                },
                {
                    fieldName: "1.g Hiatal hernia that distorts medistinal anatomy",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "1.h Adehsions in the anterior mediastinal space",
            fieldType: "radio",
            values: [
                {
                    fieldName: "1.h Adehsions in the anterior mediastinal space",
                    value: "Yes"
                },
                {
                    fieldName: "1.h Adehsions in the anterior mediastinal space",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "1.i Severe obesity so that subxiphoid/substernal tunneling cannot be safely performed",
            fieldType: "radio",
            values: [
                {
                    fieldName: "1.i Severe obesity so that subxiphoid/substernal tunneling cannot be safely performed",
                    value: "Yes"
                },
                {
                    fieldName: "1.i Severe obesity so that subxiphoid/substernal tunneling cannot be safely performed",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "2. Severe RV dilation, gross hepatosplenomegaly, or severe obesitysuch that subxiphoidal/substernal tunneling cannot be safely performed",
            fieldType: "radio",
            values: [
                {
                    fieldName: "2. Severe RV dilation, gross hepatosplenomegaly, or severe obesitysuch that subxiphoidal/substernal tunneling cannot be safely performed",
                    value: "Yes"
                },
                {
                    fieldName: "2. Severe RV dilation, gross hepatosplenomegaly, or severe obesitysuch that subxiphoidal/substernal tunneling cannot be safely performed",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "3. Class III indication for permanent pacemaker",
            fieldType: "radio",
            values: [
                {
                    fieldName: "3. Class III indication for permanent pacemaker",
                    value: "Yes"
                },
                {
                    fieldName: "3. Class III indication for permanent pacemaker",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "4. Decompensated heart failure not due to brady cardia and expected to worsen with chronic RV pacing",
            fieldType: "radio",
            values: [
                {
                    fieldName: "4. Decompensated heart failure not due to brady cardia and expected to worsen with chronic RV pacing",
                    value: "Yes"
                },
                {
                    fieldName: "4. Decompensated heart failure not due to brady cardia and expected to worsen with chronic RV pacing",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "5. An implanted cardiac pacemaker, cardioverter defibrillator, cardiac resynchronization device, or neurostimulator device, or planned implantation of a cardioverter defibrillator, cardiac resynchronization device, or neurostimulator device",
            fieldType: "radio",
            values: [
                {
                    fieldName: "5. An implanted cardiac pacemaker, cardioverter defibrillator, cardiac resynchronization device, or neurostimulator device, or planned implantation of a cardioverter defibrillator, cardiac resynchronization device, or neurostimulator device",
                    value: "Yes"
                },
                {
                    fieldName: "5. An implanted cardiac pacemaker, cardioverter defibrillator, cardiac resynchronization device, or neurostimulator device, or planned implantation of a cardioverter defibrillator, cardiac resynchronization device, or neurostimulator device",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "6. A current or planned implantation of a substernal device, or any implanted device that would interfere with the implantation or operation of a substernal device",
            fieldType: "radio",
            values: [
                {
                    fieldName: "6. A current or planned implantation of a substernal device, or any implanted device that would interfere with the implantation or operation of a substernal device",
                    value: "Yes"
                },
                {
                    fieldName: "6. A current or planned implantation of a substernal device, or any implanted device that would interfere with the implantation or operation of a substernal device",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "7. Have previously undergone an open-heart surgical procedure",
            fieldType: "radio",
            values: [
                {
                    fieldName: "7. Have previously undergone an open-heart surgical procedure",
                    value: "Yes"
                },
                {
                    fieldName: "7. Have previously undergone an open-heart surgical procedure",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "8. An active infection",
            fieldType: "radio",
            values: [
                {
                    fieldName: "8. An active infection",
                    value: "Yes"
                },
                {
                    fieldName: "8. An active infection",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "9. On chronic oral anticoagulation which cannot be temporarily discontinued for surgery",
            fieldType: "radio",
            values: [
                {
                    fieldName: "9. On chronic oral anticoagulation which cannot be temporarily discontinued for surgery",
                    value: "Yes"
                },
                {
                    fieldName: "9. On chronic oral anticoagulation which cannot be temporarily discontinued for surgery",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "10. A condition in which pericardial pacing would be difficult or impossible, such as acute pericarditis, chronic pericardial effusion or pericardial thickening or calcification, cardiac tamponade, or chronic restrictive pericarditis",
            fieldType: "radio",
            values: [
                {
                    fieldName: "10. A condition in which pericardial pacing would be difficult or impossible, such as acute pericarditis, chronic pericardial effusion or pericardial thickening or calcification, cardiac tamponade, or chronic restrictive pericarditis",
                    value: "Yes"
                },
                {
                    fieldName: "10. A condition in which pericardial pacing would be difficult or impossible, such as acute pericarditis, chronic pericardial effusion or pericardial thickening or calcification, cardiac tamponade, or chronic restrictive pericarditis",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "11. Have tested positive for the COVID-19 in the past 3 months, or are currently showing symptoms consistent with COVID-19",
            fieldType: "radio",
            values: [
                {
                    fieldName: "11. Have tested positive for the COVID-19 in the past 3 months, or are currently showing symptoms consistent with COVID-19",
                    value: "Yes"
                },
                {
                    fieldName: "11. Have tested positive for the COVID-19 in the past 3 months, or are currently showing symptoms consistent with COVID-19",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "12. Women of childbearing potential who are or might be pregnant at the time of the study or breastfeeding",
            fieldType: "radio",
            values: [
                {
                    fieldName: "12. Women of childbearing potential who are or might be pregnant at the time of the study or breastfeeding",
                    value: "Yes"
                },
                {
                    fieldName: "12. Women of childbearing potential who are or might be pregnant at the time of the study or breastfeeding",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "13. Subjects with a life expectancy of less than 12 months",
            fieldType: "radio",
            values: [
                {
                    fieldName: "13. Subjects with a life expectancy of less than 12 months",
                    value: "Yes"
                },
                {
                    fieldName: "13. Subjects with a life expectancy of less than 12 months",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "14. Are currently enrolled or planning to participate in any concurrent clinical study with an investigational therapy",
            fieldType: "radio",
            values: [
                {
                    fieldName: "14. Are currently enrolled or planning to participate in any concurrent clinical study with an investigational therapy",
                    value: "Yes"
                },
                {
                    fieldName: "14. Are currently enrolled or planning to participate in any concurrent clinical study with an investigational therapy",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "15. Decompensated heart failure expected to worsen with chronic RV pacing",
            fieldType: "radio",
            values: [
                {
                    fieldName: "15. Decompensated heart failure expected to worsen with chronic RV pacing",
                    value: "Yes"
                },
                {
                    fieldName: "15. Decompensated heart failure expected to worsen with chronic RV pacing",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "16. Complete AV block or other pacemaker-dependent conditions",
            fieldType: "radio",
            values: [
                {
                    fieldName: "16. Complete AV block or other pacemaker-dependent conditions",
                    value: "Yes"
                },
                {
                    fieldName: "16. Complete AV block or other pacemaker-dependent conditions",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "17. COPD with oxygen dependence",
            fieldType: "radio",
            values: [
                {
                    fieldName: "17. COPD with oxygen dependence",
                    value: "Yes"
                },
                {
                    fieldName: "17. COPD with oxygen dependence",
                    value: "No"
                }
            ]
        },

        ],
    },
    {
        subject: "Patient Eligibility",
        fields: [{
            fieldName: "Does the patient meet all eligibility criteria for enrollment in the study?",
            fieldType: "radio",
            // alternateFieldName: "If No, please give reason for eligibility failure",
            // alternateFieldType: "text"
            values: [
                {
                    fieldName: "Does the patient meet all eligibility criteria for enrollment in the study?",
                    value: "Yes"
                },
                {
                    fieldName: "Does the patient meet all eligibility criteria for enrollment in the study?",
                    value: "No"
                }
            ]            
        },
        {
            fieldName: "If No, please give reason for eligibility failure",
            fieldType: "text",
            dependency: {
                fieldName: "Does the patient meet all eligibility criteria for enrollment in the study?",
                value: "No"
            }
        }],
    },
    {
        subject: "Investigator's Declaration",
        fields: [
        {
            fieldName: "\"I have reviewed the data entries for all pages in this Case Report Form. To the best of my knowledge, they are complete, accurate and compatible with the source documents. All entries were either made by myself or a person under my supervision who has signed the Delegation Log.\"",
            fieldType: "info",
        },
        {
            fieldName: "Investigator's Name",
            fieldType: "text",
        },
        {
            fieldName: "Investigator's Signature",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Investigator's Signature",
                    value: "Yes"
                },
                {
                    fieldName: "Investigator's Signature",
                    value: "No"
                }
            ]  
        },
        {
            fieldName: "Date",
            fieldType: "date",
        },
        ],
    },
    {
        subject: "Demographics",
        fields: [{
            fieldName: "Gender",
            fieldType: "radio",
            values:[
                {
                    fieldName:"Gender",
                    value:"Male"
                },
                {
                    fieldName:"Gender",
                    value:"Female"
                }
            ]
        },
        {
            fieldName: "Was a pregnancy test performed for female subject of childbearing age?",
            fieldType: "radio",
            values:[
                {
                    fieldName:"Was a pregnancy test performed for female subject of childbearing age",
                    value:"Yes"
                },
                {
                    fieldName:"Was a pregnancy test performed for female subject of childbearing age",
                    value:"No"
                }
            ]
        },
        {
            fieldName: "Date of Birth",
            fieldType: "date",
        },
        {
            fieldName: "Age",
            fieldType: "number",
            min:"18",
            max:"100"
        },
        //TODO Other, Specify ,  to be considered in conditional basis
        {
            fieldName: "Race",
            fieldType: "select",
            values: [
                "American Indian or Alaska Native",
                "Asian",
                "Black or African American",
                "Native Hawaiian or Other Pacific Islander",
                "White",
                "Other, Specify"
            ]
        },
        //TODO Other, Specify ,  to be considered in conditional basis		
        {
            fieldName: "Ethnicity",
            fieldType: "select",
            values: [
                "Hispanic or Latino",
                "Non-Hispanic",
                "Other, Specify"
            ]
        },
        ],
    },
    {
        subject: "Vital Signs",
        fields: [{
            fieldName: "Weight",
            fieldType: "number",
            min:"0",
            max:"999"      
        },
        {
            fieldName: "Height",
            fieldType: "number",
            min:"0",
            max:"999"              
        },
        {
            fieldName: "Blood Pressure - Systolic",
            fieldType: "number",
            min:"0",
            max:"999"              
        },
        {
            fieldName: "Blood Pressure - Diastolic",
            fieldType: "number",
            min:"0",
            max:"999"              
        },
        {
            fieldName: "Pulse Rate",
            fieldType: "number",
            min:"0",
            max:"999"              
        },
        {
            fieldName: "Respiratory Rate",
            fieldType: "number",
            min:"0",
            max:"999"              

        },
        {
            fieldName: "Body Temperature",
            fieldType: "number",
            min:"0",
            max:"999"              
        },
        {
            fieldName: "Oxygen Saturation",
            fieldType: "number",
            min:"0",
            max:"999"  
        }

        ]
    },
    {
        subject: "Medical and Surgical History",
        fields: [
            // {
            //     fieldName: "Is there any relevant Medical or Surgical History? (If yes, please provide details below)",
            //     fieldType: "radio",
            //     alternateFieldName: "If yes, please provide the details below",
            //     alternateFieldType: "text"
            // },
            {
                fieldName: "Body System (Code Only)",
                fieldType: "select",
                values: [
/*                     "Head, Eyes, Ear, Nose, Throat",
                    "Respiratory",
                    "Cardiovascular",
                    "Genitourinary",
                    "Hemopoietic/Lymphatic",
                    "Musculoskeletal",
                    "Endocrine/Metabolic",
                    "Psychological", */
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "Other, specify"
                ]
            },
            {
                fieldName: "Describe",
                fieldType: "text",
            },
            {
                fieldName: "Medical or surgical",
                fieldType: "select",
                values: [
                    "Medical",
                    "Surgical"
                ]
            },
            {
                fieldName: "Date of Surgery / Planned date of Surgery/ Date of medical illness",
                fieldType: "date",
            },
            {
                fieldName: "Current Status",
                fieldType: "select",
                values: [
                    "Past",
                    "Ongoing"
                ]
            },
            {
                fieldName: "CAD in a first degree relative",
                fieldType: "radio",
                values:[{
                    fieldName:"CAD in a first degree relative",
                    value:"Yes"
                },
                {
                    fieldName:"CAD in a first degree relative",
                    value:"No"
                }]
            },
            {
                fieldName: "Prior Myocardial Infarction",
                fieldType: "radio",
                values:[{
                    fieldName:"Prior Myocardial Infarction",
                    value:"Yes"
                },
                {
                    fieldName:"Prior Myocardial Infarction",
                    value:"No"
                }]
            },
            {
                fieldName: "Number of Myocardial Infarctions",
                fieldType: "number",
                min:"0",
                max:"100",
                isFloat:false
            },
            {
                fieldName: "Most recent:",
                fieldType: "date"
            },
            {
                fieldName: "History of Angina",
                fieldType: "radio",
                values:[{
                    fieldName:"History of Angina",
                    value:"Yes"
                },
                {
                    fieldName:"History of Angina",
                    value:"No"
                }]
            },
            {
                fieldName: "CHF",
                fieldType: "radio",
                values:[{
                    fieldName:"CHF",
                    value:"Yes"
                },
                {
                    fieldName:"CHF",
                    value:"No"
                }]
            },
            {
                fieldName: "NYHA Class",
                fieldType: "select",
                values: [
                    "I",
                    "II",
                    "III",
                    "IV",
                    "Unknown"
                ]
            },
            {
                fieldName: "Valve disease",
                fieldType: "radio",
                values:[{
                    fieldName:"Valve disease",
                    value:"Yes"
                },
                {
                    fieldName:"Valve disease",
                    value:"No"
                }]
            },
            {
                fieldName: "Prior Pace Maker",
                fieldType: "radio",
                values:[{
                    fieldName:"Prior Pace Maker",
                    value:"Yes"
                },
                {
                    fieldName:"Prior Pace Maker",
                    value:"No"
                }]
            },
            {
                fieldName: "Prior AICD",
                fieldType: "radio",
                values:[{
                    fieldName:"Prior AICD",
                    value:"Yes"
                },
                {
                    fieldName:"Prior AICD",
                    value:"No"
                }]
            },
            {
                fieldName: "Cardiomyopathy",
                fieldType: "radio",
                values:[{
                    fieldName:"Cardiomyopathy",
                    value:"Yes"
                },
                {
                    fieldName:"Cardiomyopathy",
                    value:"No"
                }]
            },
            {
                fieldName: "Congenital Heart Disease",
                fieldType: "radio",
                values:[{
                    fieldName:"Congenital Heart Disease",
                    value:"Yes"
                },
                {
                    fieldName:"Congenital Heart Disease",
                    value:"No"
                }]
            },
            {
                fieldName: "Arrhythmia",
                fieldType: "radio",
                values:[{
                    fieldName:"Arrhythmia",
                    value:"Yes"
                },
                {
                    fieldName:"Arrhythmia",
                    value:"No"
                }]
            },
            {
                fieldName: "Other Cardiac Condition",
                fieldType: "radio",
                values:[{
                    fieldName:"Other Cardiac Condition",
                    value:"Yes"
                },
                {
                    fieldName:"Other Cardiac Condition",
                    value:"No"
                }]
            },
            {
                fieldName: "Prior Cardiac Procedures",
                fieldType: "radio",
                values:[{
                    fieldName:"Prior Cardiac Procedures",
                    value:"Yes"
                },
                {
                    fieldName:"Prior Cardiac Procedures",
                    value:"No"
                }]
            },
            //TODO not clear Date Most Recent verus subsequent fields
            //subsequent fields are dates instead of Yes/No and then Date, rationale if Date is not empty then its applicable/Yes 
            {
                fieldName: "Date Most Recent",
                fieldType: "date"
            },
            {
                fieldName: "Thrombolitic Therapy",
                fieldType: "radio",
                values:[{
                    fieldName:"Thrombolitic Therapy",
                    value:"Yes",
                    triggerType:"date"  //trigger a date field if Yes is selected
                },
                {
                    fieldName:"Thrombolitic Therapy",
                    value:"No"
                }]
            },
            {
                fieldName: "PCI (stent, PTCA, etc)",
                fieldType: "radio",
                values:[{
                    fieldName:"PCI (stent, PTCA, etc)",
                    value:"Yes",
                    triggerType:"date"  //trigger a date field if Yes is selected
                },
                {
                    fieldName:"PCI (stent, PTCA, etc)",
                    value:"No"
                }]
            },
            {
                fieldName: "CABG",
                fieldType: "radio",
                values:[{
                    value:"Yes",
                    triggerType:"date"  //trigger a date field if Yes is selected
                },
                {
                    value:"No"
                }]
            },
            {
                fieldName: "Valve Surgery",
                fieldType: "radio",
                values:[{
                    value:"Yes",
                    triggerType:"date"  //trigger a date field if Yes is selected
                },
                {
                    value:"No"
                }]
            },
            {
                fieldName: "Transplant Surgery",
                fieldType: "radio",
                values:[{
                    value:"Yes",
                    triggerType:"date"  //trigger a date field if Yes is selected
                },
                {
                    value:"No"
                }]
            },
            {
                fieldName: "Peripheral Vascular Disease",
                fieldType: "radio",
                values:[{
                    value:"Yes",
                    triggerType:"date"  //trigger a date field if Yes is selected
                },
                {
                    value:"No"
                }]
            },
            {
                fieldName: "Cerebrovascular/Neurological Disease",
                fieldType: "radio",
                values:[{
                    value:"Yes",
                    triggerType:"date"  //trigger a date field if Yes is selected
                },
                {
                    value:"No"
                }]
            },
            {
                fieldName: "Prior Stroke",
                fieldType: "radio",
                values:[{
                    value:"Yes",
                    triggerType:"date"  //trigger a date field if Yes is selected
                },
                {
                    value:"No"
                }]
            },
            {
                fieldName: "Ishemic",
                fieldType: "radio",
                values:[{
                    fieldName:"Ishemic",
                    value:"Yes"
                },
                {
                    fieldName:"Ishemic",
                    value:"No"
                }]
            },
            {
                fieldName: "Hemorrhagic",
                fieldType: "radio",
                values:[{
                    fieldName:"Hemorrhagic",
                    value:"Yes"
                },
                {
                    fieldName:"Hemorrhagic",
                    value:"No"
                }]
            },
            {
                fieldName: "Unknown",
                fieldType: "radio",
                values:[{
                    fieldName:"Unknown",
                    value:"Yes"
                },
                {
                    fieldName:"Unknown",
                    value:"No"
                }]
            },
            {
                fieldName: "Diabetes Mellitus",
                fieldType: "radio",
                values:[{
                    fieldName:"Diabetes Mellitus",
                    value:"Yes"
                },
                {
                    fieldName:"Diabetes Mellitus",
                    value:"No"
                }]
            },
            {
                fieldName: "Insulin",
                fieldType: "radio",
                values:[{
                    fieldName:"Insulin",
                    value:"Yes"
                },
                {
                    fieldName:"Insulin",
                    value:"No"
                }]
            },
            {
                fieldName: "Oral antidiabetic agents",
                fieldType: "radio",
                values:[{
                    fieldName:"Oral antidiabetic agents",
                    value:"Yes"
                },
                {
                    fieldName:"Oral antidiabetic agents",
                    value:"No"
                }]
            },
            {
                fieldName: "Diet only",
                fieldType: "radio",
                values:[{
                    fieldName:"Diet only",
                    value:"Yes"
                },
                {
                    fieldName:"Diet only",
                    value:"No"
                }]
            },
            {
                fieldName: "Hypertension",
                fieldType: "radio",
                values:[{
                    fieldName:"Hypertension",
                    value:"Yes"
                },
                {
                    fieldName:"Hypertension",
                    value:"No"
                }]
            },
            {
                fieldName: "Hyperlipidemia",
                fieldType: "radio",
                values:[{
                    fieldName:"Hyperlipidemia",
                    value:"Yes"
                },
                {
                    fieldName:"Hyperlipidemia",
                    value:"No"
                }]
            },
            {
                fieldName: "COPD",
                fieldType: "radio",
                values:[{
                    fieldName:"COPD",
                    value:"Yes"
                },
                {
                    fieldName:"COPD",
                    value:"No"
                }]
            },
            {
                fieldName: "Renal Insufficiency",
                fieldType: "radio",
                values:[{
                    fieldName:"Renal Insufficiency",
                    value:"Yes"
                },
                {
                    fieldName:"Renal Insufficiency",
                    value:"No"
                }]
            },
            {
                fieldName: "Other Significant Medical History",
                fieldType: "radio",
                values:[{
                    fieldName:"Other Significant Medical History",
                    value:"Yes",
                    triggerType:"textarea"
                },
                {
                    fieldName:"Other Significant Medical History",
                    value:"No"
                }]
            },
            {
                fieldName: "Non Cardiac Surgery/Intervention",
                fieldType: "radio",
                values:[{
                    fieldName:"Non Cardiac Surgery/Intervention",
                    value:"Yes",
                    triggerType:"textarea"
                },
                {
                    fieldName:"Non Cardiac Surgery/Intervention",
                    value:"No"
                }]
            },
        ],
    },
    {
        subject: "Concomitant Medications / Therapies Logs",
        fields: [
            {
                fieldName: "Brand / Generic Name",
                fieldType: "text"
            },
            {
                fieldName: "Indication",
                fieldType: "text"
            },
            {
                fieldName: "Dose/Units",
                fieldType: "text",
            },
            {
                //ZZZZ TODO Other (specify case has to be considered in conditional basis
                fieldName: "Route",
                fieldType: "select",
                values: [
                    "1= Per Oral (PO)",
                    "2= Sublingual (SL)",
                    "3= Subcutaneous (SC)",
                    "4= Inhaled (IH)",
                    "5= Intramuscular (IM)",
                    "6= Transdermal (TD)",
                    "7= Intravenous (IV)",
                    "8= Nasal (N)",
                    "9= Per Rectal (PR)",
                    "10= Intrauterine (IU)",
                    "11= Other (specify)"
                ]
            },
            {
                fieldName: "Frequency",
                fieldType: "select",
                values: [
                    "1= PRN",
                    "2= Every Day (QD)",
                    "3= Twice a day (BID)",
                    "4= Thrice a day (TID)",
                    "5= Every other day (QOD)",
                    "6= Every hour (QH)",
                    "7= Every 4 hours (Q4H)",
                    "8= Once weekly (OW)",
                    "9= Twice weekly (TIW)",
                    "10= Other (specify)"
                ]
            },
            {
                fieldName: "Start Date",
                fieldType: "date",
            },
            {
                fieldName: "Stop Date",
                fieldType: "date",
            },
            {
                fieldName: "Check if Ongoing",
                fieldType: "radio",
                values:[{
                    fieldName:"Check if Ongoing",
                    value:"Yes"
                },
                {
                    fieldName:"Check if Ongoing",
                    value:"No"
                }]
            }
        ],
    },
    {
        subject: "Class I or II Indication for Pacemaker Implantation:",
        fields: [
            {
                fieldName: "Class I or II Indication for Pacemaker Implantation:",
                fieldType1: "checkbox",
                methods: [
                    "Sinus Node Dysfunction",
                    "Atrioventricular (AV) Block",
                    "Chronic Bifascicular Block",
                    "After Acute Myocardial Infarction",
                    "Neurocardiogenic Syncope and Hypersensitive Carotid Sinus Syndrome",
                    "Post Cardiac Transplantation",
                    "Hypertrophic Cardiomyopathy",
                    "Pacing to Detect and Terminate Tachycardia",
                    "Cardiac Resynchronization Therapy with Severe Systolic Heart Failure",
                    "Congenital Heart Disease",
                    "Other __________________"
                ]
            }
        ],
    },
    {
        subject: "Blood Collection",
        fields: [{
            fieldName: "Was blood collection performed?",
            fieldType: "radio",
            values: [{
                fieldName: "Was blood collection performed?",
                value: "Yes"
            },
            {
                fieldName: "Was blood collection performed?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "Is blood collection report available for upload?",
            fieldType: "radio",
            values: [{
                fieldName: "Is blood collection report available for upload?",
                value: "Yes"
            },
            {
                fieldName: "Is blood collection report available for upload?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE***",
            fieldType: "file",
            dependency: {
                fieldName: "Is blood collection report available for upload?",
                value: "Yes"
            }            
        },
        {
            fieldName: "If no, please give reason, and complete a protocol deviation CRF",
            fieldType: "text"
        }
        ],
    },
    {
        subject: "Echocardiogram",
        fields: [{
            fieldName: "Was Echocardiogram performed?",
            fieldType: "radio",
            values: [{
                fieldName: "Was Echocardiogram performed?",
                value: "Yes"
            },
            {
                fieldName: "Was Echocardiogram performed?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "Is ECHO video available for upload?",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Is ECHO video available for upload?",
                    value: "Yes"
                },
                {
                    fieldName: "Is ECHO video available for upload?",
                    value: "No"
                }
            ]
        },
        {
            fieldName: "***SCAN ECHO video AND PUT IN DATABASE***",
            fieldType: "file",
            dependency: {
                fieldName: "Is ECHO video available for upload?",
                value: "Yes"
            }  
        },
        {
            fieldName: "Is ECHO report available for upload?",
            fieldType: "radio",
            values: [{
                fieldName: "Is ECHO report available for upload?",
                value: "Yes"
            },
            {
                fieldName: "Is ECHO report available for upload?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "***SCAN ECHO report AND PUT IN DATABASE***",
            fieldType: "file",
            dependency: {
                fieldName: "Is ECHO report available for upload?",
                value: "Yes"
            } 
        },
        {
            fieldName: "If no, please give reason, and complete a protocol deviation CRF",
            fieldType: "text"
        },
        {
            fieldName: "Date of recording",
            fieldType: "date"
        },
        {
            fieldName: "Left ventricular ejection fraction",
            fieldType: "number",
            unit:"%"
        }
        ],
    },

]