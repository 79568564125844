import React, { useState, useEffect } from 'react'
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from "lodash";


let autoStartTimerId;
let isSSEstopped = true;

const SIX_MONTHS_MS = 15552000000 //180*24*60*60*1000 (180 days)
const SSE_INACTIVITY_PERIOD = 5000;

/** POST call for refreshToken
 ReqBody - {refresh_token}
 */
 export const getHistoricalData = async (props) => {

    let historcalSyncDataSubscription;    

    const handlesseInactivity = () => {
        if (props.thingUpdates.size != 0) {
            props.duControlSubject.next(true)
        }
    
      };    
        let currentDate = new Date();

        let totimestamp = currentDate.toISOString();
        let fromtimestamp = new Date(currentDate.getTime() - SIX_MONTHS_MS).toISOString();
    
        const historcalSyncData = await props.dtalService.gethistorical(props.identifier, null, null, props.fromtimestamp??fromtimestamp, 
            props.totimestamp??totimestamp, props.fields??"thingId,attributes,features,_revision,_modified")

        if (historcalSyncData) {
          isSSEstopped = false; //SSE started
          historcalSyncDataSubscription = historcalSyncData.subscribe(response => {
            let existingEntry = null;
    
            //SSE
            if (autoStartTimerId) clearTimeout(autoStartTimerId); //cancel inactivity timer
            if (props.thingUpdates.size == 0) {
                props.thingUpdates.set(response._modified, response);
              autoStartTimerId = setTimeout(handlesseInactivity, SSE_INACTIVITY_PERIOD); //start inactivity timer
            } else {
              existingEntry = props.thingUpdates.get(response._modified);
              if (existingEntry) {
                //stop SSE as data is getting repeated, trigger processing of device updates
                props.duControlSubject.next(true);
    
              } else {
                //found new entry, start inactivity timer
                props.thingUpdates.set(response._modified, response);
                autoStartTimerId = setTimeout(handlesseInactivity, SSE_INACTIVITY_PERIOD);
    
              }
            }
          });
        }   
        
        return historcalSyncDataSubscription;
}