import {Redirect, Route, Switch} from 'react-router-dom';
import {IonApp, IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import Login from "./components/Login";
import Home from "./components/Home";
import Dashboard from './pages/dashboard/dashboard';
import "./App.css"
import {AppContextProvider} from './components/AppContext';
import { UseAppDataProvider } from './context/appContext';
import {useMemo, useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { DTAL} from './service/DTAL';

const App = () => {

    const [appData, setAppData] = useState({count: 0, refreshToken: "", accessToken: ""});
    const [userData, setUserData] = useState({userId: "", userName: "", userType: "", emailId: ""});
    const [refreshTimerId, setRefreshTimerId] = useState({timerId: null});
    const [pageData, setPageData] = useState({formId: ""});
    const [userFilterData, setUserFilterData] = useState({userFilters:[], userFilterExpression:''});
    const [deviceFilterData, setDeviceFilterData] = useState({deviceFilters:[], deviceFilterExpression:''});
    const [userRecords, setUserRecords] = useState([]);
    const [deviceRecords, setDeviceRecords] = useState([]);
    const [dtalService, setDtalService] = useState(new DTAL(null));

    const appDataValue = useMemo(
        () => ({
            appData, refreshTimerId, userData, pageData, userFilterData,deviceFilterData, userRecords, deviceRecords, dtalService,
            setAppData, setRefreshTimerId, setUserData, setPageData, setUserFilterData, setDeviceFilterData, setUserRecords, setDeviceRecords, setDtalService
        }),
        [appData, refreshTimerId, userData, pageData, userFilterData, deviceFilterData,dtalService,
            setAppData, setRefreshTimerId, setUserData, setPageData, setUserFilterData, setDeviceFilterData, setUserRecords, setDeviceRecords,setDtalService]);

    return (
        <IonApp>
            <IonReactRouter>
                <UseAppDataProvider>
                <AppContextProvider value={appDataValue}>
                    <IonRouterOutlet id="mainContent">
                        <Switch>
                            <Route exact path="/login">
                                <Login/>
                            </Route>
                            <Route exact path="/home/*">
                                <Home/>
                            </Route>
                            <Route exact path="/forgotPassword">
                                <Login/>
                            </Route>
                            <Route exact path="/dashboard/*">
                                <Dashboard/>
                            </Route>
                            <Route exact path="/">
                                <Redirect to="/login"/>
                            </Route>
                        </Switch>
                    </IonRouterOutlet>
                </AppContextProvider>
                </UseAppDataProvider>
            </IonReactRouter>
           
        </IonApp>
    );
};
export default App;
