export const grantPolicyModel = {
    "subjects": {}
    ,
    "resources":
        {
            "thing:/":
                {
                    "grant":
                        [
                            "READ",
                            "WRITE"
                        ],
                    "revoke": []
                }
            ,
            "message:/":
                {
                    "grant":
                        [
                            "READ",
                            "WRITE"
                        ],
                    "revoke": []
                }
        }
}

export const grantRdonlyPolicyModel = {
    "subjects": {}
    ,
    "resources":
        {
            "thing:/":
                {
                    "grant":
                        [
                            "READ"
                        ],
                    "revoke": []
                }
            ,
            "message:/":
                {
                    "grant":
                        [
                            "READ"
                        ],
                    "revoke": []
                }
        }
}

export default grantPolicyModel;