import React, {useContext, useEffect, useState} from 'react';
import "../styles/assignDevice.css";
import {
    IonCard, IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
    IonButton, IonSpinner
} from "@ionic/react";
import {closeCircle, filterOutline, optionsOutline, search} from "ionicons/icons";
import {useHistory, useLocation} from "react-router";
import PaginatedView from "./PaginatedView";
import UserTable from "./UserTable";
import SubjectIdTable from "./SubjectIdTable";
import DeviceTable from "./DeviceTable";
import {
    handleGrantPolicy,
    getAllDevices,
    getAllUsers,
    getFilteredDevices,
    getFilteredUsers,
    handleRevokePolicy,
    getAllSubjects,
    handleGrantThingPolicy
} from "../service/adminUI";
import AppContext from "./AppContext";

import * as _ from "lodash";

import {syncTimerFreqMode} from "../util/config";

const AssignDevice = props => {

    const {bckimgChange} = props;
    const history = useHistory();
    const location = useLocation();
    const [userResult, setUserResult] = useState([]);
    const [deviceResult, setDeviceResult] = useState([]);
    const [isUserDataLoading, setIsUserDataLoading] = useState(false);
    const [isSubjectDataLoading, setIsSubjectDataLoading] = useState(false);
    const [isDeviceDataLoading, setIsDeviceDataLoading] = useState(false);
    const {appData, userData, userFilterData, deviceFilterData, setUserFilterData, setDeviceFilterData,
            userRecords, deviceRecords, setUserRecords, setDeviceRecords, dtalService} = useContext(AppContext);
    const MODEL_TYPE_USER = 'user';
    const MODEL_TYPE_DEVICE = 'device';
    const FILTER_CONDITION_ANY = 'or';
    const FILTER_CONDITION_ALL = 'and';
    const [userEmail, setUserEmail] = useState('');
    const [userType, setUserType] = useState('');
    const [deviceMacId, setDeviceMacId] = useState('');
    const [userId, setUserId] = useState('');
    const [subjectId, setSubjectId] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [serialNo, setSerialNo] = useState('');
    const [macId, setMacId] = useState('');
    const [originalUserResult, setOriginalUserResult] = useState([]);
    const [originalDeviceResult, setOriginalDeviceResult] = useState([]);
    const [nameAsc, setNameAsc] = useState(true);
    const [emailAsc, setEmailAsc] = useState(true);
    const [serialNoAsc, setSerialNoAsc] = useState(true);
    const [macIdAsc, setMacIdAsc] = useState(true);
    const [toastClass, setToastClass] = useState('toast toast-invisible');
    const [toastVisible, setToastVisible] = useState(false);
    const [message, setMessage] = useState('Loading..Please wait');
    const TOAST_SUCCESS_CSS = 'toast toast-success';
    const TOAST_ERROR_CSS = 'toast toast-error';
    const TOAST_INFO_CSS = 'toast toast-info';

    const [subjectResult, setSubjectResult] = useState([]);

    useEffect(async () => {
        bckimgChange('assignDevice.png');
        if (location.state) {
            const {modelType} = location.state;
            await handleLoadData(modelType);

            if (userRecords && userRecords.length > 0 && modelType != MODEL_TYPE_USER) {
                setUserResult(userRecords);
                setOriginalUserResult(userRecords);
            }
            if (deviceRecords && deviceRecords.length > 0 && modelType != MODEL_TYPE_DEVICE) {
                setDeviceResult(deviceRecords);
                setOriginalDeviceResult(deviceRecords);
            }
        }
    }, [])

    const handleFilterOnClick = (modelName) => {
        history.push({pathname: '/home/filter', state: {modelName}})
    }

    const handleLoadData = async (modelType) => {
        if (modelType == MODEL_TYPE_USER) {
            await handleLoadUsers();
        } else if (modelType == MODEL_TYPE_DEVICE) {
            await handleLoadDevices();
        } else {
            await handleLoadUsers();
            await handleLoadDevices();
        }
    }

    const handleAssignDevice = async () => {
        let patientSubjectId='';     
        let deviceData = {};  
        let mapperData = {};

        const result = await handleGrantPolicy(deviceMacId, userId, appData.accessToken, userEmail, userType);
/*         if(result.status==201){
            autoHideToast(TOAST_SUCCESS_CSS, `Assign: ${result.message}` );
        } else if(result.status==204){
            autoHideToast(TOAST_INFO_CSS, `Assign: ${result.message}` );
        } else{
            autoHideToast(TOAST_ERROR_CSS, `Assign: ${result.message}`);
        } */

        if((result.status==201) || (result.status==204))
        {
            try {    
                //get subjectId associated with device
                deviceData = await dtalService.get("pacemaker:"+deviceMacId+'&fields=attributes');
                if(!_.isEmpty(deviceData)) {
                    patientSubjectId = deviceData[0].attributes?.subjectId ?? '';
                }

                //iniitailze access rights
                let accessRights = {
                    //give policy RW and thing RW to logged in admin user credentials
                    policyowner:{userId:userData.userId,emailId:userData.emailId,type:userData.userType},
                    rwowners: [{userId:userData.userId,emailId:userData.emailId,type:userData.userType}],
                    ronlyowners:[],
                };

                if(userType == 'patient')
                {
                    //check if patient subjectId is not associated with device
                    if(patientSubjectId.length == 0)
                    {
                       
                        //get subjectId from mapper
                        mapperData = await dtalService.get("mapper:"+userId);
                        if(!_.isEmpty(mapperData)) {
                            patientSubjectId = mapperData[0].attributes?.subjectId ?? '';
                        }                
                        //update device attributes to associate subjectId to device
                        let devicePayload = {
                            "attributes": {
                                "subjectId": patientSubjectId
                        }
                        }
                        //proceed further only if patient has subjectId
                        if(patientSubjectId.length > 0) 
                        {
                            await dtalService.set("pacemaker:"+deviceMacId,devicePayload)
                            
                            //create message for Patient, assign Read
                            accessRights.ronlyowners.push({userId:userId,emailId:userEmail,type:userType})
                            await dtalService.create("message:"+patientSubjectId, {}, accessRights); //no message payload, to be triggered from patient app

                            //create symptom (access - RW)
                            accessRights.ronlyowners=[];
                            accessRights.rwowners.push({userId:userId,emailId:userEmail,type:userType});
                            await dtalService.create("symptom:"+patientSubjectId, {}, accessRights); //no symptom payload, to be triggered from patient app 
                        } else {
                            autoHideToast(TOAST_ERROR_CSS, `Assign: failed as ${userEmail} has no subjectId mapped`);      
                        }

                    }
                } else {
                    //create message for Doctor, assign Read
                    accessRights.ronlyowners.push({userId:userId,emailId:userEmail,type:userType})
                    await dtalService.create("message:"+userId, {}, accessRights); //no message payload, to be triggered from RMS    
                    
                        //proceed further only if device has subjectId
                        if(patientSubjectId.length > 0) 
                        {
                            //grant RW to doctor for patient message
                            await handleGrantThingPolicy('message:'+patientSubjectId, userId, appData.accessToken, userEmail, userType,true);
                            //grant R to doctor for patient symptom                         
                            await handleGrantThingPolicy('symptom:'+patientSubjectId, userId, appData.accessToken,userEmail, userType,false); 

                            //grant RW to patient for doctor message, 
                            //fetch patient userId from subjectId 
                            mapperData = await dtalService.fetch("mapper",`eq(attributes/subjectId,"${patientSubjectId}")`,'');
                            if(!_.isEmpty(mapperData)) {
                                let patientUserId = mapperData.items[0].thingId.split(':')[1];
                                let patientEmailId = mapperData.items[0].attributes.emailId;
                                let patientType = mapperData.items[0].attributes.type; //mostly this will be always 'patient'

                                await handleGrantThingPolicy('message:'+userId, patientUserId, appData.accessToken,patientEmailId, patientType,true); 

                                //create syncTimer (access -RW for doctor, R for patient),   
                                accessRights.ronlyowners=[];
                                accessRights.rwowners.push({userId:userId,emailId:userEmail,type:userType});
                                accessRights.ronlyowners.push({userId:patientUserId,emailId:patientEmailId,type:patientType});

                                //fetch eCRF_implant 
                                let triggerTimeStamp = 0;
                                let implantRecord = await dtalService.get('eCRF_implant:'+patientSubjectId);
                                if(!_.isEmpty(implantRecord)) {
                                    //ZZZZ depends on how the eCRF data is saved in RMS
                                    //if(implantRecord.items[0].features.content.properties.)
                                }
                                // Get today's date
                                const today = new Date();
                                // Get current year
                                const year = today.getFullYear();
                                // Get current month(0 based)
                                const month = today.getMonth();
                                // Keep day as 1 to get first day
                                const day = 1;
                                // Get first day of current month
                                const firstDayOfMonth = new Date(year, month, day);
                                triggerTimeStamp = firstDayOfMonth.getTime()/1000;    
                            
                                let syncTimerPayload = {
                                    "features": {
                                        "content": {
                                            "properties": {
                                                "triggerTimeStamp": triggerTimeStamp,
                                                "freqmode": syncTimerFreqMode 
                                            }
                                        }
                                    }

                                }
                                await dtalService.create("syncTimer:"+patientSubjectId, syncTimerPayload, accessRights); //no symptom payload, to be triggered from patient app                     

                            }                     
                            autoHideToast(TOAST_SUCCESS_CSS, `Assign: ${result.message}` );
                        } else {
                            autoHideToast(TOAST_ERROR_CSS, `Assign: failed as device ${deviceMacId} has no subjectId associated`);      
                        }            


                } 
                
        } catch(e){ console.log(e); 
            autoHideToast(TOAST_ERROR_CSS, `Assign: ${result.message}`); } 

        //create message, assign Read

        //if patient, update device-attirbutes with subjectId, create symptom (access - RW)

        //if doctor/physician, assign RW to patient message, assign RW to doctor message, 
        //create syncTimer (access -RW for doctor, R for patient), assign R to patient symptom
        }
    }   

    const handleUnAssignDevice = async () => {
        const result = await handleRevokePolicy(deviceMacId, userId, appData.accessToken, userEmail, userType);
        if(result.status==204) {
            autoHideToast(TOAST_SUCCESS_CSS, `UnAssign: ${result.message}`);
        }
        else{
            autoHideToast(TOAST_ERROR_CSS, `UnAssign: ${result.message}`);
        }
    }

    const handleMapSubject = async () => {
        //create a mapper entry btw patient and subjectId
        let accessRights = {
            //get the logged in admin user credentials
            policyowner:{userId:userData.userId,emailId:userData.emailId,type:userData.userType},
            rwowners: [{userId:userData.userId,emailId:userData.emailId,type:userData.userType}],
            ronlyowners:[],
        };
        //form userId + subjectId mapper payload
        let mapperPayload = {
            "attributes": {
                "subjectId": subjectId,
                "emailId":userEmail,
                "type":userType
        }};
        try {
        //create an mapper thing with id as userId and containing static attribute subjectId
        await dtalService.create("mapper:"+userId, mapperPayload, accessRights);

        autoHideToast(TOAST_SUCCESS_CSS, `Mapped subject ${subjectId} to patient ${userEmail}`);

        } catch(e){ console.log(e); 
            autoHideToast(TOAST_ERROR_CSS, `Mapping failed subject ${subjectId} to patient ${userEmail}`); } 
 
    }    
    const handleLoadUsers = async (isStaleExpression) => {
        let userData = null;
        let newUserExpression = '';
        setUserId('');
        setUserEmail('');
        setUserType('');
        setIsUserDataLoading(true);
        if (isStaleExpression == true) {
            newUserExpression = setFilterExpression(MODEL_TYPE_USER)
        } else {
            newUserExpression = userFilterData.userFilterExpression;
        }
        if (userFilterData.userFilters && !(userFilterData.userFilters.length > 0)) {
            userData = await getAllUsers(appData.accessToken);
        } else {
            //await getFilteredUsers(appData.accessToken, 'clarity');
            userData = await getFilteredUsers(appData.accessToken, newUserExpression);
        }
        if (userData && userData.length > 0) {
            setUserResult(userData);
            setOriginalUserResult(userData);
            setUserRecords(userData);
        } else {
            setUserRecords([]);
            autoHideToast(TOAST_INFO_CSS, "Users search: No data received" );
        }
        setIsUserDataLoading(false);
    }

    const handleLoadSubjects = async () => {
        let subjectData = null;
        setIsSubjectDataLoading(true);
        subjectData = await getAllSubjects(appData.accessToken);
        if (subjectData && subjectData.length > 0) {
            setSubjectResult(subjectData);
        } else {
            setSubjectResult([]);
            autoHideToast(TOAST_INFO_CSS, "Subjects search: No data received" );
        }
        setIsSubjectDataLoading(false);
    }    
    
    const handleLoadDevices = async (isStaleExpression) => {
        let deviceData = null;
        let newDeviceExpression = '';
        setDeviceMacId('');
        setIsDeviceDataLoading(true);
        if (isStaleExpression == true) {
            newDeviceExpression = setFilterExpression(MODEL_TYPE_DEVICE)
        } else {
            newDeviceExpression = deviceFilterData.deviceFilterExpression;
        }
        if (deviceFilterData.deviceFilters && !(deviceFilterData.deviceFilters.length > 0)) {
            deviceData = await getAllDevices(appData.accessToken);
        } else {
            //await getFilteredDevices(appData.accessToken, 'eq(attributes/serialnumber,"AAA089010AAB")');
            deviceData = await getFilteredDevices(appData.accessToken, newDeviceExpression);
        }
        if (deviceData && deviceData.length > 0) {
            setDeviceResult(deviceData);
            setOriginalDeviceResult(deviceData);
            setDeviceRecords(deviceData);
        } else {
            setDeviceRecords([]);
            autoHideToast(TOAST_INFO_CSS, "Devices search: No data received" );
        }
        setIsDeviceDataLoading(false);
    }

    const setFilterExpression = (model) => {
        let filterExpression = '';
        let filters = null;
        model == MODEL_TYPE_USER ? (filters = userFilterData.userFilters) : (filters = deviceFilterData.deviceFilters);

        //get all data when no filter selected
        if (filters == null || !(filters.length > 0)) {
            filterExpression = '';
        } else {
            if (filters.length == 1) {
                // no prefix for single expression
                filterExpression = filters[0].id;
            } else {
                for (let i = 0; i < filters.length; i++) {
                    filterExpression = `${filterExpression}${filters[i].id},`;
                }
                filterExpression = filterExpression.slice(0, -1);
                if (model == MODEL_TYPE_USER) {
                    //append and-or condition prefix in expression
                    filterExpression = (userFilterData.userFilterExpression.startsWith(FILTER_CONDITION_ANY) ?
                        FILTER_CONDITION_ANY : FILTER_CONDITION_ALL) + `(${filterExpression})`;
                } else if (model == MODEL_TYPE_DEVICE) {
                    //append and-or condition prefix in expression
                    filterExpression = (deviceFilterData.deviceFilterExpression.startsWith(FILTER_CONDITION_ANY) ?
                        FILTER_CONDITION_ANY : FILTER_CONDITION_ALL) + `(${filterExpression})`;
                }
            }
        }
        //set app context for the filters and expressions
        model == MODEL_TYPE_USER ?
            (setUserFilterData({...userFilterData, userFilterExpression: filterExpression})) :
            (setDeviceFilterData({...deviceFilterData, deviceFilterExpression: filterExpression}));
        return filterExpression;
    }


    const handleDeleteFilter = (chipToDelete, model) => () => {
        if (model == MODEL_TYPE_USER) {
            const chips = userFilterData.userFilters;
            setUserFilterData({
                ...userFilterData,
                userFilters: [...chips.filter((chip) => chip.key !== chipToDelete.key)]
            });
        } else if (model == MODEL_TYPE_DEVICE) {
            const chips = deviceFilterData.deviceFilters;
            setDeviceFilterData({
                ...deviceFilterData,
                deviceFilters: [...chips.filter((chip) => chip.key !== chipToDelete.key)]
            });
        }
    }

    const handleUserOnChange = event => {
        const data = event.currentTarget.value.split('&');
        setUserEmail(data[0]);
        setUserType(data[1]);
        setUserId(data[2]);
    }

    const handleSubjectOnChange = event => {
        const data = event.currentTarget.value;
        if(data) setSubjectId(data);
    }

    const handleDeviceOnChange = event => {
        const data = event.currentTarget.value.split('&');
        setDeviceMacId(data[0]);
    }

    const handleNameOnChange = event => {
        const value = event.currentTarget.value;
        setName(value);
        const data = originalUserResult.filter(user => (user.firstName && user.firstName.toLowerCase().includes(value.toLowerCase())) || (user.lastName && user.lastName.toLowerCase().includes(value.toLowerCase())));
        setUserResult(data);
    }

    const handleEmailOnChange = event => {
        const value = event.currentTarget.value;
        setEmail(value);
        const data = originalUserResult.filter(user => user.email && user.email.toLowerCase().includes(value.toLowerCase()));
        setUserResult(data);
    }

    const handleSerialNoOnChange = event => {
        const value = event.currentTarget.value;
        setSerialNo(value);
        const data = originalDeviceResult.filter(device => device.attributes.serialnumber.includes(value));
        setDeviceResult(data);
    }

    const handleMacIdOnChange = event => {
        const value = event.currentTarget.value;
        setMacId(value);
        const data = originalDeviceResult.filter(device => device.attributes.macid.includes(value));
        setDeviceResult(data);
    }

    const handleNameIconOnClick = () => {
        let data;
        if (nameAsc) {
            data = userResult.sort((a, b) => (a.firstName + " " + a.lastName).localeCompare(b.firstName + " " + b.lastName));
        } else {
            data = userResult.sort((a, b) => (b.firstName + " " + b.lastName).localeCompare(a.firstName + " " + a.lastName));
        }
        setNameAsc(!nameAsc);
        setUserResult([...data]);
    }

    const handleSubjectIconOnClick = () => {
        let data;
        if (nameAsc) {
            data = subjectResult.sort((a, b) => (a.firstName + " " + a.lastName).localeCompare(b.firstName + " " + b.lastName));
        } else {
            data = subjectResult.sort((a, b) => (b.firstName + " " + b.lastName).localeCompare(a.firstName + " " + a.lastName));
        }
        setNameAsc(!nameAsc);
        setSubjectResult([...data]);
    }

    const handleEmailIconOnClick = () => {
        let data;
        if (emailAsc) {
            data = userResult.sort((a, b) => a.email.localeCompare(b.email));
        } else {
            data = userResult.sort((a, b) => b.email.localeCompare(a.email));
        }
        setEmailAsc(!emailAsc);
        setUserResult([...data]);
    }

    const handleSerialNoIconOnClick = () => {
        let data;
        if (serialNoAsc) {
            data = deviceResult.sort((a, b) => a.attributes.serialnumber.localeCompare(b.attributes.serialnumber));
        } else {
            data = deviceResult.sort((a, b) => b.attributes.serialnumber.localeCompare(a.attributes.serialnumber));
        }
        setSerialNoAsc(!serialNoAsc);
        setDeviceResult([...data]);
    }

    const handleMacIdIconOnClick = () => {
        let data;
        if (macIdAsc) {
            data = deviceResult.sort((a, b) => a.attributes.macid.localeCompare(b.macid));
        } else {
            data = deviceResult.sort((a, b) => b.attributes.macid.localeCompare(a.macid));
        }
        setMacIdAsc(!macIdAsc);
        setDeviceResult([...data]);
    }

    //Auto Hide toast after 5 seconds
    const autoHideToast = (css, message) => {
        setToastClass(css);
        setMessage(message);
        setToastVisible(true);
        setTimeout(() => {
            setToastVisible(false);
        }, 5000);
    }

    return (
        <IonContent>
            <div className="search-box search-box-mob">
                <IonItem className="search-input">
                    <IonItem className="filter" placeholder="Search by">
                        {(userFilterData.userFilters && userFilterData.userFilters.length > 0) ? userFilterData.userFilters.map((data) => {
                            return (<IonChip>
                                <IonLabel>{data.key + " " + data.value}</IonLabel>
                                <IonIcon icon={closeCircle}
                                         onClick={data.key === 'React' ? undefined : handleDeleteFilter(data, MODEL_TYPE_USER)}/>
                            </IonChip>)
                        }) : null}
                    </IonItem>

                </IonItem>
                <IonIcon icon={search}
                         size="small"
                         className="search-icon"
                         onClick={e => handleLoadUsers(true)}/>
                <IonIcon icon={optionsOutline}
                         className="filter-icon"
                         onClick={e => handleFilterOnClick(MODEL_TYPE_USER)}/>
            </div>

            <IonLabel className="table-heading">Showing results for Users</IonLabel>
            <div>
                {isUserDataLoading ?
                    <div className="spinner-box">
                        <IonSpinner name="lines" className="ion-spinner"/>
                        <IonLabel className="row-header">Please wait, search is in progress</IonLabel>
                    </div>
                    : <IonGrid className="container-grid">
                        <IonRow className="row-header">
                            <IonCol className="table-header-cell" >
                                <IonItem className="row-box">
                                    <IonInput value={name} placeholder="Name"
                                              onIonChange={handleNameOnChange}></IonInput>
                                    <IonIcon icon={filterOutline} slot='end' onClick={handleNameIconOnClick}/>
                                </IonItem>
                            </IonCol>
                            <IonCol className="table-header-cell">
                                <IonItem className="row-box">
                                    <IonInput value={email} placeholder="Email Id"
                                              onIonChange={handleEmailOnChange}></IonInput>
                                    <IonIcon icon={filterOutline} slot='end' onClick={handleEmailIconOnClick}/>
                                </IonItem>
                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                        <PaginatedView userData={userResult}>
                            <UserTable onRadioChange={handleUserOnChange}/>
                        </PaginatedView>
                    </IonGrid>}
            </div>
             {/* Search for enrolled subjectIds */}

             <div className="search-box search-box-mob">
                <IonItem className="search-input">
                    <IonItem className="filter" placeholder="Search by">
                        {null}
                    </IonItem>

                </IonItem>
                <IonIcon icon={search}
                         size="small"
                         className="search-icon"
                         onClick={e => handleLoadSubjects(true)}/>
            </div>

            <IonLabel className="table-heading">Showing all subjects</IonLabel>
            <div>
                {isSubjectDataLoading ?
                    <div className="spinner-box">
                        <IonSpinner name="lines" className="ion-spinner"/>
                        <IonLabel className="row-header">Please wait, search is in progress</IonLabel>
                    </div>
                    : <IonGrid className="container-grid">
                        <IonRow className="row-header">
                            <IonCol className="table-header-cell" >
                                <IonItem className="row-box">
                                    <IonInput value={subjectId} placeholder="SubjectId"
                                              onIonChange={handleSubjectOnChange}></IonInput>
                                    <IonIcon icon={filterOutline} slot='end' onClick={handleSubjectIconOnClick}/>
                                </IonItem>
                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                        <PaginatedView userData={subjectResult}>
                            <SubjectIdTable onRadioChange={handleSubjectOnChange}/>
                        </PaginatedView>
                    </IonGrid>}
            </div>             

            <div className="search-box">
                <IonItem className="search-input">
                    <IonItem className="filter" placeholder="Search by">
                        {(deviceFilterData.deviceFilters && deviceFilterData.deviceFilters.length > 0) ? deviceFilterData.deviceFilters.map((data) => {
                            return (<IonChip>
                                <IonLabel>{data.key + " " + data.value}</IonLabel>
                                <IonIcon icon={closeCircle}
                                         onClick={data.key === 'React' ? undefined : handleDeleteFilter(data, MODEL_TYPE_DEVICE)}/>
                            </IonChip>)
                        }) : null}
                    </IonItem>
                </IonItem>
                <IonIcon icon={search}
                         size="small"
                         className="search-icon"
                         onClick={e => handleLoadDevices(true)}/>
                <IonIcon icon={optionsOutline}
                         className="filter-icon"
                         onClick={e => handleFilterOnClick(MODEL_TYPE_DEVICE)}/>
            </div>

            {/* Search for enrolled subjectIds */}

            <IonLabel className="table-heading">Showing results for Devices</IonLabel>
            <div>
                {isDeviceDataLoading ?
                    <div className="spinner-box">
                        <IonSpinner name="lines" className="ion-spinner"/>
                        <IonLabel className="row-header">Please wait, search is in progress</IonLabel>
                    </div>
                    : <IonGrid className="container-grid">
                        <IonRow className="row-header">
                            <IonCol  className="table-header-cell">
                                <IonItem className="row-box">
                                    <IonInput value={serialNo} placeholder="Serial Number"
                                              onIonChange={handleSerialNoOnChange}></IonInput>
                                    <IonIcon icon={filterOutline} slot='end' onClick={handleSerialNoIconOnClick}/>
                                </IonItem>
                            </IonCol>
                            <IonCol  className="table-header-cell">
                                <IonItem className="row-box">
                                    <IonInput value={macId} placeholder="Mac Id"
                                              onIonChange={handleMacIdOnChange}></IonInput>
                                    <IonIcon icon={filterOutline} slot='end' onClick={handleMacIdIconOnClick}/>
                                </IonItem>
                            </IonCol>
                            <IonCol></IonCol>
                        </IonRow>
                        <PaginatedView userData={deviceResult}>
                            <DeviceTable onRadioChange={handleDeviceOnChange}/>
                        </PaginatedView>
                    </IonGrid>}
            </div>

            <div>
                {
                    toastVisible && <div className={toastClass}> {message} </div>
                }
            </div>

            <IonButton className="assign-button" disabled={!(userId && deviceMacId)} onClick={handleAssignDevice}>Assign
                Device</IonButton>
            <IonButton className="assign-button" disabled={!(userId && deviceMacId)} onClick={handleUnAssignDevice}>Unassign
                Device</IonButton>
            <IonButton className="assign-button" disabled={!(userId && userType == 'patient' && subjectId)} onClick={handleMapSubject}>Map 
                Subject</IonButton>                
        </IonContent>
    );
};
export default AssignDevice;