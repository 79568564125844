const operationModel = {
    "in" : "Contains",
    "like" : "Matches",
    "eq" : "=",
    "gt": ">",
    "lt": "<",
    "ge": ">=",
    "le": "<="
}

export  const userOperationModel ={
    "in" : "Contains",
    "like" : "Matches"
}
export default operationModel;