
import React, { useState, useEffect } from 'react'
import * as _ from "lodash";
import { FileAttachment } from './FileAttachment'
import {regpatternOthers,regpatternDate} from '../config'
import { handleSaveEnrollmentData,escapeCRFData } from './helperfunctions';
import { IonButton, IonNote } from '@ionic/react';
import '../../pages/ecrf/ecrf.css'
import { BehaviorSubject } from 'rxjs';

const errorSubject = new BehaviorSubject(null); //to emit error from child component;
const fileAttachmentSubject = new BehaviorSubject(null); //to relay the file attachment actions
let fileFieldsMap = new Map();
let hasInvalidFields = false;
var aryIannaTimeZones = [
    'Europe/Andorra',
    'Asia/Dubai',
    'Asia/Kabul',
    'Europe/Tirane',
    'Asia/Yerevan',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Antarctica/Mawson',
    'Antarctica/Palmer',
    'Antarctica/Rothera',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Jujuy',
    'America/Argentina/Tucuman',
    'America/Argentina/Catamarca',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'Pacific/Pago_Pago',
    'Europe/Vienna',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Asia/Baku',
    'America/Barbados',
    'Asia/Dhaka',
    'Europe/Brussels',
    'Europe/Sofia',
    'Atlantic/Bermuda',
    'Asia/Brunei',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Santarem',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Nassau',
    'Asia/Thimphu',
    'Europe/Minsk',
    'America/Belize',
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'Indian/Cocos',
    'Europe/Zurich',
    'Africa/Abidjan',
    'Pacific/Rarotonga',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'America/Bogota',
    'America/Costa_Rica',
    'America/Havana',
    'Atlantic/Cape_Verde',
    'America/Curacao',
    'Indian/Christmas',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Europe/Prague',
    'Europe/Berlin',
    'Europe/Copenhagen',
    'America/Santo_Domingo',
    'Africa/Algiers',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Europe/Tallinn',
    'Africa/Cairo',
    'Africa/El_Aaiun',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Helsinki',
    'Pacific/Fiji',
    'Atlantic/Stanley',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Atlantic/Faroe',
    'Europe/Paris',
    'Europe/London',
    'Asia/Tbilisi',
    'America/Cayenne',
    'Africa/Accra',
    'Europe/Gibraltar',
    'America/Godthab',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Athens',
    'Atlantic/South_Georgia',
    'America/Guatemala',
    'Pacific/Guam',
    'Africa/Bissau',
    'America/Guyana',
    'Asia/Hong_Kong',
    'America/Tegucigalpa',
    'America/Port-au-Prince',
    'Europe/Budapest',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Europe/Dublin',
    'Asia/Jerusalem',
    'Asia/Kolkata',
    'Indian/Chagos',
    'Asia/Baghdad',
    'Asia/Tehran',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'America/Jamaica',
    'Asia/Amman',
    'Asia/Tokyo',
    'Africa/Nairobi',
    'Asia/Bishkek',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Asia/Pyongyang',
    'Asia/Seoul',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Beirut',
    'Asia/Colombo',
    'Africa/Monrovia',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Riga',
    'Africa/Tripoli',
    'Africa/Casablanca',
    'Europe/Monaco',
    'Europe/Chisinau',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Asia/Yangon',
    'Asia/Ulaanbaatar',
    'Asia/Hovd',
    'Asia/Choibalsan',
    'Asia/Macau',
    'America/Martinique',
    'Europe/Malta',
    'Indian/Mauritius',
    'Indian/Maldives',
    'America/Mexico_City',
    'America/Cancun',
    'America/Merida',
    'America/Monterrey',
    'America/Matamoros',
    'America/Mazatlan',
    'America/Chihuahua',
    'America/Ojinaga',
    'America/Hermosillo',
    'America/Tijuana',
    'America/Bahia_Banderas',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Pacific/Noumea',
    'Pacific/Norfolk',
    'Africa/Lagos',
    'America/Managua',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Asia/Kathmandu',
    'Pacific/Nauru',
    'Pacific/Niue',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'America/Panama',
    'America/Lima',
    'Pacific/Tahiti',
    'Pacific/Marquesas',
    'Pacific/Gambier',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Asia/Manila',
    'Asia/Karachi',
    'Europe/Warsaw',
    'America/Miquelon',
    'Pacific/Pitcairn',
    'America/Puerto_Rico',
    'Asia/Gaza',
    'Asia/Hebron',
    'Europe/Lisbon',
    'Atlantic/Madeira',
    'Atlantic/Azores',
    'Pacific/Palau',
    'America/Asuncion',
    'Asia/Qatar',
    'Indian/Reunion',
    'Europe/Bucharest',
    'Europe/Belgrade',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Kirov',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Ulyanovsk',
    'Europe/Samara',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Novosibirsk',
    'Asia/Barnaul',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Khandyga',
    'Asia/Vladivostok',
    'Asia/Ust-Nera',
    'Asia/Magadan',
    'Asia/Sakhalin',
    'Asia/Srednekolymsk',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Asia/Riyadh',
    'Pacific/Guadalcanal',
    'Indian/Mahe',
    'Africa/Khartoum',
    'Europe/Stockholm',
    'Asia/Singapore',
    'America/Paramaribo',
    'Africa/Juba',
    'Africa/Sao_Tome',
    'America/El_Salvador',
    'Asia/Damascus',
    'America/Grand_Turk',
    'Africa/Ndjamena',
    'Indian/Kerguelen',
    'Asia/Bangkok',
    'Asia/Dushanbe',
    'Pacific/Fakaofo',
    'Asia/Dili',
    'Asia/Ashgabat',
    'Africa/Tunis',
    'Pacific/Tongatapu',
    'Europe/Istanbul',
    'America/Port_of_Spain',
    'Pacific/Funafuti',
    'Asia/Taipei',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Pacific/Wake',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Montevideo',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'America/Caracas',
    'Asia/Ho_Chi_Minh',
    'Pacific/Efate',
    'Pacific/Wallis',
    'Pacific/Apia',
    'Africa/Johannesburg'
  ];

//d.toLocaleString('en-US', { timeZone: 'America/New_York' })
  
export const ECRFform = props => {
       
  const [fieldValues, setFieldValues] = useState([]);
  const [invalidFields, setInvalidFields] = useState({});

  const [message, setMessage] = useState('Loading..Please wait');
  const [toastClass, setToastClass] = useState('toast-invisible');
  const [toastVisible, setToastVisible] = useState(false);
  const TOAST_SUCCESS_CSS = 'ecrf-toast-success';
  const TOAST_ERROR_CSS = 'ecrf-toast-error';
  const TOAST_INFO_CSS = 'toast toast-info';


    //Auto Hide toast after 5 seconds
    const autoHideToast = (css, message) => {
        setToastClass(css);
        setMessage(message);
        setToastVisible(true);
        setTimeout(() => {
            setToastVisible(false);
        }, 5000);
    }  

  const handleInputChange = (e) => {
    let isContinue = true;
    let { name, value,type } = e.target;
    let valid = e.target.validity.valid 
    if(type=='checkbox') value = e.target.checked;
    if(type=='number')  {
        if(value == '') valid = true; //treat empty values as valid
        setInvalidFields ({
            ...invalidFields,
            [name]: {valid},
        })
    }

    //if((type=='number') && !isFloat && (value=='.')) isContinue=false; //ingore the decimal

    if (isContinue) setFieldValues({
        ...fieldValues,
        [name]: {type,value},
    });

};


const isValidDate = (year, month, day) => {
    const testDate = new Date(year, month, day);
    return testDate.getFullYear() === year &&
      testDate.getMonth() === month &&
      testDate.getDate() === day;
};

const handleDateChange = (e) => {
    let { name, value,type } = e.target;
    type = 'date';
    let valid = e.target.validity.valid; 

    if(valid && !e.target.validity.patternMismatch && value != '') {
        let match = value.match(regpatternDate);
        let year = +match[3];
        let month = +match[2];
        let day = +match[1];

        if(isValidDate(year,month-1,day)) {
            let date = new Date(year,month-1, day); //year, month (0-based), day
            let datestr = date.toString();
            if(datestr.indexOf('Invalid') != -1)
            {
                //input date is invalid
                valid = false;
            } else {
                let dateelements = datestr.split(' ');
                if(dateelements.length > 4) { 
                    value = dateelements[2] + '/' + dateelements[1].toUpperCase() + '/' + dateelements[3]
                } else {
                    valid = false;
                }

            }
        } else {
            valid=false;
        }
    }

    if(value == '') valid = true; //treat empty values as valid
    setInvalidFields ({
        ...invalidFields,
        [name]: {valid},
    })

    setFieldValues({
        ...fieldValues,
        [name]: {type,value},
    });

};

const saveFollowUpData = () => {

    //prevent save if there are invalid fields
    let aInvalidFields = invalidFields;
    aInvalidFields = _.filter(aInvalidFields,function (o) { return !o.valid });
    if(_.isEmpty(aInvalidFields)) {

    let data = {
        features: {
            [props.formName]: {
                properties: {
                   values:fieldValues
                }
            }

        }
    }

    if(fileFieldsMap.size) {
        fileFieldsMap.forEach(function(fileinfo,key){
            let fieldname = key;
            let urls = Array.from(fileinfo.values());
            if(data.features[props.formName].properties.values[fieldname]) {
                data.features[props.formName].properties.values[fieldname].push(...urls);
            } else {
                data.features[props.formName].properties.values[fieldname] = urls;    
            }
             
            }
         )
       }        
    
    var escapedValues = escapeCRFData(data.features[props.formName].properties.values);
    data.features[props.formName].properties.values = escapedValues;   

    handleSaveEnrollmentData(props.namespace,props.identifier,data,props.accessRights);
    autoHideToast(TOAST_SUCCESS_CSS,"eCRF Data saved for " + props.identifier);
    } else {
        autoHideToast(TOAST_ERROR_CSS,"Please correct the highlighted fields and save again");  
    }
}

const deleteDraftFollowUp = () => {

    //restore the fieldvalues
    if (!_.isEmpty(props.fieldValues)) {
        setFieldValues(props.fieldValues); //restore the first fetched record
    } else {
        setFieldValues({});  //NOTE -> no original data, form will be reset completely. this could be annoying if someone clicks on Delete instead of save 
    }
}

//check if the dependent field is present with desired value
const checkforDependency = (field) => { 
    let isDependencyFullfilled = false;

    if(field.dependency && field.dependency?.fieldName) {
        if(_.isArray(field.dependency?.value)) {
            let idx = _.indexOf(field.dependency.value, fieldValues[field.dependency?.fieldName]?.value);
            if(idx != -1) {
                isDependencyFullfilled = true;
            }
        } else {
            if(fieldValues[field.dependency?.fieldName]?.value == field.dependency?.value) { 
                isDependencyFullfilled = true;
        }
    }
    } else {
        isDependencyFullfilled = true;
    }

    //isDependencyFullfilled = !field.dependency || (field.dependency && fieldValues[field.dependency?.fieldName]?.value == field.dependency?.value)
    return isDependencyFullfilled
}

//function to reformat the value based on data type
const reformatFieldValues = () => { 
    if(!_.isEmpty(props.fieldValues)) {
        for (let iIdx in props.fieldValues) {
            //change the format if the date value does not contain MON
            if((props.fieldValues[iIdx]?.type == 'date') && _.isEmpty(props.fieldValues[iIdx]?.value.match(/[A-Z]+/))) {
                let datevalue = new Date(props.fieldValues[iIdx].value);
                let datestr = datevalue.toString();
                if(datestr.indexOf('Invalid') == -1) {
                    let dateelements = datestr.split(' ');
                    if(dateelements.length > 4) { 
                        props.fieldValues[iIdx].value = dateelements[2] + '/' + dateelements[1].toUpperCase() + '/' + dateelements[3]
                    } 
    
                }
            }
        }
    }

}


    useEffect(async () => {
        props.eCRFfields?.map((item, index) => {
            item.fields.map((item, index) => {
            if(item.fieldType == 'file') {
                if(!fileFieldsMap.has(item.fieldName)) {
                    fileFieldsMap.set(item.fieldName,new Map());

                 }

            }})}) 
        
        reformatFieldValues(props.fieldValues)    
        setFieldValues(props.fieldValues)

        const errorEventSubscription = errorSubject.subscribe(errText => {
            if(errText != null) { 
                autoHideToast(TOAST_ERROR_CSS,errText); 
                errorSubject.next(null); //reset the error info
            }
        });

        const fileAttachmentSubscription = fileAttachmentSubject.subscribe(fileattachinfo => {
            if(fileattachinfo != null) { 
                if(fileattachinfo.action) {
                    fileFieldsMap.get(fileattachinfo.fieldName).set(fileattachinfo.fileName,fileattachinfo.fileurl)
                } else {
                    fileFieldsMap.get(fileattachinfo.fieldName).delete(fileattachinfo.fileName);     
                }
                fileAttachmentSubject.next(null); //reset the fileattach info
            }
        });

        aryIannaTimeZones.sort();
        
        return () => {
            errorEventSubscription.unsubscribe();
            fileAttachmentSubscription.unsubscribe();
        }
     
    }, [props.eCRFfields,props.fieldValues])

    return (   
 
        <div>
            <div className='ecrf_height' style={{ overflowY: "scroll", padding: "20px" }}>
            <form>
                    {props.eCRFfields?.filter((categorySubject) => checkforDependency(categorySubject)).map((item, index) => {
                        return (
                            <div key={index}>
                                <h4 style={{ color: "#535352" }}>{item.subject}</h4>
                                {item.fields.filter((field) => checkforDependency(field)).map((item, index) => {
                                    let currentField = item;
                                    return (
                                        <div key={index}>
                                            {item.fieldType == "info" && (
                                                <h3 style={{ color: "#535352", fontSize: "14px" }}>{item.fieldName}</h3>      
                                            )}

                                            {item.fieldType == "date" && (
                                                <div style={{ display: 'flex', justifyContent: "space-between", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px solid #535352", alignItems: "center" }}>
                                                    <label style={{ fontSize: "14px", color: "#535352" }}>{item.fieldName}</label>
                                                        <input data-testid={item.fieldName} name={item.fieldName} onChange={(e) => handleDateChange(e)} className={invalidFields[item.fieldName]?.valid==false?item.fieldType+"_invalid":item.fieldType} 
                                                               type="text" value={fieldValues[item.fieldName]?.value ?? ''} pattern="\d{2}/\d{2}/\d{4}" placeholder='DD/MM/YYYY'/>
                                                </div >
                                            )}                                            

                                            {item.fieldType != "radio" && item.fieldType != "select" && item.fieldType != "file" && !item.fieldType1 && item.fieldType != "info" && item.fieldType != "date" && (

                                                <div style={{ display: 'flex', justifyContent: "space-between", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px solid #535352", alignItems: "center" }}>
                                                    <label style={{ fontSize: "14px", color: "#535352" }}>{item.fieldName}</label>
                                                    <div className={item.fieldType + "_data_container"}>
                                                        <input data-testid={item.fieldName} name={item.fieldName} onChange={(e) => handleInputChange(e)} className={invalidFields[item.fieldName]?.valid==false?item.fieldType+"_invalid":item.fieldType} type={item.fieldType} 
                                                            min={item.min?item.min:null} max={item.max?item.max:null} step={item.isFloat==false?1:"any"} value={fieldValues[item.fieldName]?.value ?? ''} checked={fieldValues[item.fieldName]?.value??false}/>
                                                        {item?.unit && <span className="si-unit">{item.unit}</span>}
                                                        {
                                                            item?.tz && <select data-testid={item.fieldName+"_"+"tz"} name={item.fieldName+"_"+"tz"} onChange={handleInputChange} className='tz'>
                                                            {
                                                                <option value="" disabled selected>Select TimeZone</option>
                                                            }
                                                            {  aryIannaTimeZones? 
                                                                aryIannaTimeZones.map((tzval, index) => {
                                                                    return (
                                                                    <option value={tzval} selected={tzval==fieldValues[item.fieldName+"_"+"tz"]?.value}>{tzval}</option>
                                                                    )
                                                            }):
                                                                item.tz.map((tzval, index) => {
                                                                    return (
                                                                        <option value={tzval} selected={tzval==fieldValues[item.fieldName+"_"+"tz"]?.value}>{tzval}</option>
                                                                    )
                                                                })}
                                                        </select>
                                                        }   
                                                    </div>        
                                                </div >
                                            )}
                                            {item.fieldType != "radio" && item.fieldType != "select" && item.fieldType1 && 
                                                <div key={index} style={{ display: 'flex', justifyContent: "space-between", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px solid #535352", alignItems: "center" }}>
                                                    <label data-testid={item.fieldName} style={{ fontSize: "14px", color: "#535352" }}>{item.fieldName}</label>     
                                                </div> &&            
                                            (

                                                item?.methods.map((method, index) => {

                                                    return (
                                                        <div key={index} style={{ display: 'flex', justifyContent: "space-between", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px solid #535352", alignItems: "center" }}>
{/*                                                             <div style={{ fontSize: "14px", color: "#535352" }}>{item.fieldName}</div> */}
                                                            <div style={{ fontSize: "14px",color: "#535352" }}>{method}</div>
                                                            {_.isEmpty(method.match(regpatternOthers))? 
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <input data-testid={item.fieldName+'_'+item.fieldType1+'_'+index} name={item.fieldName+'_'+item.fieldType1+'_'+index} onChange={handleInputChange} style={{ marginRight: "30px" }} className={item.fieldType1} type={item.fieldType1} value={fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value ?? ''} checked={fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value??false}/>
                                                                {fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value && item?.fieldType2 && <input data-testid={item.fieldName+'_'+item.fieldType2+'_'+index} name={item.fieldName+'_'+item.fieldType2+'_'+index} onChange={item.fieldType2=='date'?handleDateChange:handleInputChange} className={invalidFields[item.fieldName+'_'+item.fieldType2+'_'+index]?.valid==false?item.fieldType2+"_invalid":item.fieldType2} 
                                                                type={item.fieldType2=='date'?'text':item.fieldType2} pattern={item.fieldType2=='date'?"\\d{2}/\\d{2}/\\d{4}":null} placeholder={item.fieldType2=='date'?'DD/MM/YYYY':null} value={fieldValues[item.fieldName+'_'+item.fieldType2+'_'+index]?.value ?? ''}/>}
                                                            </div>
                                                            :<div style={{ display: "flex", alignItems: "center" }}>
                                                                <input data-testid={item.fieldName+'_'+item.fieldType1+'_'+index} name={item.fieldName+'_'+item.fieldType1+'_'+index} onChange={handleInputChange} style={{ marginRight: "30px" }} className={item.fieldType1} type={item.fieldType1} value={fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value ?? ''} checked={fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value??false}/>
                                                                {fieldValues[item.fieldName+'_'+item.fieldType1+'_'+index]?.value && <input data-testid={item.fieldName+'_'+'text'+'_'+index} name={item.fieldName+'_'+'text'+'_'+index} onChange={handleInputChange} className="text" type="text" value={fieldValues[item.fieldName+'_'+'text'+'_'+index]?.value ?? ''}/>}
                                                            </div>}
                                                        </div >
                                                    )
                                                })
                                            )}
                                            {
                                                item.fieldType == "select" && (
                                                    <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #535352", paddingBottom: "15px", paddingTop: "15px" }}>
                                                        <label style={{fontSize: "14px", color: "#535352" }}>{item.fieldName}</label>

                                                        <select fieldType={item.fieldType} data-testid={item.fieldName} name={item.fieldName} onChange={handleInputChange} className='select'>
                                                        {   fieldValues[item.fieldName]?.value? 
                                                                item.values.map((valelement, index) => {
                                                                    return (
                                                                    <option value={valelement} selected={valelement==fieldValues[item.fieldName]?.value}>{valelement}</option>
                                                                    )
                                                            }):
                                                                item.values.map((item, index) => {
                                                                    return (
                                                                        <option value={item} >{item}</option>
                                                                    )
                                                                })}
                                                        </select>
                                                        {!_.isEmpty(fieldValues[item.fieldName]?.value.match(regpatternOthers))?
                                                        <input data-testid={item.fieldName+'_'+'text'} name={item.fieldName+'_'+'text'} onChange={handleInputChange} className="text" type="text" value={fieldValues[item.fieldName+'_'+'text']?.value ?? ''}/>
                                                        :null} 
                                                                                                               


                                                    </div>
                                                )
                                            }
                                            {item.fieldType == "radio" && (
                                                <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #535352", paddingBottom: "15px", paddingTop: "15px" }}>
                                                    <div style={{ fontSize: "14px", color: "#535352" }}>{item.fieldName}</div>
                                                    <div style={{ display: "flex" }}>
                                                        {item.values.map((item, index) => {
                                                            if(!item.fieldName){
                                                               item.fieldName= currentField.fieldName;
                                                            }
                                                            return (
                                                                <div key={index} style={{ display: "flex", alignItems: "center" }}>
                                                                    <label style={{ marginLeft: "10px", color: "#535352" }}>{item.value}</label>
                                                                    {fieldValues[item.fieldName]?.value==item.value?
                                                                        <input name={item.fieldName} data-testid={item.fieldName+'_'+item.value} onChange={handleInputChange} value={item.value} style={{ marginLeft: "5px" }} type="radio" checked/>:                                                                 
                                                                        <input name={item.fieldName} data-testid={item.fieldName+'_'+item.value} onChange={handleInputChange} value={item.value} style={{ marginLeft: "5px" }} type="radio" />
                                                                    }
                                                                    
                                                                </div>
                                                            )
                                                        })}
                                                        {item.values.map((item, index) => {
                                                            if(!item.fieldName){
                                                               item.fieldName= currentField.fieldName;
                                                            }                                                            
                                                            return (
                                                                    fieldValues[item.fieldName]?.value==item.value && item?.triggerType?<input data-testid={item.fieldName+'_'+item.triggerType} name={item.fieldName+'_'+item.triggerType} onChange={item.triggerType=='date'?handleDateChange:handleInputChange} 
                                                                    className={invalidFields[item.fieldName+'_'+item.triggerType]?.valid==false?item.triggerType+"_invalid":item.triggerType} type={item.triggerType=='date'?'text':item.triggerType} pattern={item.triggerType=='date'?"\\d{2}/\\d{2}/\\d{4}":null} 
                                                                    placeholder={item.triggerType=='date'?'DD/MM/YYYY':null} value={fieldValues[item.fieldName+'_'+item.triggerType]?.value ?? ''} style={{ marginLeft: "2px" }}/>:null
                                                            )
                                                        })}                                                        
                                                    </div>
                                                    {!_.isEmpty(fieldValues[item.fieldName]?.value.match(regpatternOthers))?
                                                        <input data-testid={item.fieldName+'_'+'text'} name={item.fieldName+'_'+'text'} onChange={handleInputChange} className="text" type="text" value={fieldValues[item.fieldName+'_'+'text']?.value ?? ''}/>
                                                        :null} 
                                                </div>
                                            )}
                                            {item.fieldType == "file" && (

                                             <FileAttachment item={item} fileUploaded={fileFieldsMap.get(item.fieldName)} fieldValues={fieldValues[item.fieldName]} errorSubject={errorSubject} fileAttachmentSubject={fileAttachmentSubject}/>
                                             
                                            )}                                                                                       
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </form>
            </div>
            {(props.accessRights?.policyowner?.type == 'ecrfadmin') && <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                {toastVisible && <IonNote data-testid="TECRFMESSAGE" slot='start' className={toastClass} style={{ height: "35px"}}> {message} </IonNote>}    
                <div>
{/*                     <div>
                         {
                            toastVisible && <div className={toastClass}> {message} </div>
                        }
                    </div> */}
                    <IonButton data-testid="TDELETEDRAFT" class='deleteBtnColor' onClick={deleteDraftFollowUp} style={{ width: "220px", textAlign: "center", height: "35px", backgroundColor: "#9D9D9C", color: "white", fontSize: "13px", letterSpacing: "0.2em", borderRadius: "5px", marginTop: "20px", marginBottom: "20px" }}>DELETE DRAFT</IonButton>
                    <IonButton data-testid="TSAVEDRAFT" class='saveBtnColor'  onClick={saveFollowUpData} style={{ width: "220px", textAlign: "center", height: "35px", backgroundColor: "#4D4D4D", color: "white", fontSize: "13px", letterSpacing: "0.2em", borderRadius: "5px", margin: "20px 20px 20px 40px" }}>SAVE</IonButton>
                </div>
            </div>}
        </div>

          
    )
     
}