import React, {useContext, useEffect} from 'react';
import {
    IonCardTitle,
    IonGrid,
    IonRow,
    IonCardHeader,
    IonCardContent,
    IonImg,
    IonCol, IonItem
} from '@ionic/react';
import { handleSubscribe } from '../service/devicesubscription/devicesubscription';
import "../styles/widget.css";
import AppContext from "./AppContext";

const Widget = props => {
    const {handleWidgetOnClick, bckimgChange, footerAction } = props;
    const {userData} = useContext(AppContext);
    const {userType} = userData;
    const { appData, setAppData, refreshTimerId, setRefreshTimerId } = useContext(AppContext);

    const widgetClass = userType == 'admin' ? 'widget-container' : 'no-widgets';
    const widgetMobClass = userType == 'admin' ? 'widget-container-mob' : 'no-widgets';
    const forbiddenToastVisible = userType == 'admin' ? false : true;

    useEffect(() => {
        bckimgChange('widget.png');
        footerAction(false);
    }, [])

    // useEffect(() => {
    //     console.log("device page reached")
    //     handleSubscribe(appData.accessToken)
    // }, [])
    

    return (
        <div>
            <div className={widgetClass}>
                <IonCardHeader>
                    <IonCardTitle className="category-title" class="ion-text-center" alig>Select Your
                        Category</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol className="col">
                                <IonItem id="registerDevice" className="cover" onClick={handleWidgetOnClick}>
                                    <IonImg src="../../assets/icon/cover.png"/>
                                    <IonImg className="icon" src="../../assets/icon/registerDeviceIcon.png"/>
                                    <h1 className="widget-label">Register Device</h1>
                                </IonItem>
                            </IonCol>
                            <IonCol className="col">
                                <IonItem id="registerPatient" className="cover" onClick={handleWidgetOnClick}>
                                    <IonImg src="../../assets/icon/cover.png"/>
                                    <IonImg className="icon" src="../../assets/icon/registerPatientIcon.png"/>
                                    <h1 className="widget-label">Register Patient</h1>
                                </IonItem>
                            </IonCol>
                            <IonCol className="col">
                                <IonItem id="registerClinician" className="cover" onClick={handleWidgetOnClick}>
                                    <IonImg src="../../assets/icon/cover.png"/>
                                    <IonImg className="icon" src="../../assets/icon/registerClinicianIcon.png"/>
                                    <h1 className="widget-label">Register Clinican</h1>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="col">
                                <IonItem id="assignDevice" className="coverLast ass-dev" onClick={handleWidgetOnClick}>
                                    <IonImg src="../../assets/icon/cover.png"/>
                                    <IonImg className="icon" src="../../assets/icon/assignDeviceIcon.png"/>
                                    <h1 className="widget-label">Assign Device</h1>
                                </IonItem>
                            </IonCol>
                            <IonCol className="col">
                                <IonItem id="revoke" className="coverLast rev" onClick={handleWidgetOnClick}>
                                    <IonImg src="../../assets/icon/cover.png"/>
                                    <IonImg className="icon" src="../../assets/icon/revokeIcon.png"/>
                                    <h1 className="widget-label">Revoke</h1>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </div>
            <div className={widgetMobClass}>
                <IonCardHeader>
                    <IonCardTitle className="title" class="ion-text-center" alig>Select Your Category</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonGrid>
                        <IonRow className="row">
                            <IonCol className="col">
                                <IonCardContent id="registerDevice" className="cover" onClick={handleWidgetOnClick} >
                                    <IonImg src="../../assets/icon/coverMob.png"/>
                                    <IonImg className="icon" src="../../assets/icon/registerDeviceIcon.png"/>
                                    <h1 className="widget-label">Register Device</h1>
                                </IonCardContent>
                            </IonCol>
                            <IonCol className="col">
                                <IonCardContent id="registerPatient" className="cover" onClick={handleWidgetOnClick} >
                                    <IonImg src="../../assets/icon/coverMob.png"/>
                                    <IonImg className="icon" src="../../assets/icon/registerPatientIcon.png"/>
                                    <h1 className="widget-label">Register Patient</h1>
                                </IonCardContent>
                            </IonCol>
                        </IonRow>
                        <IonRow className="row">
                            <IonCol className="col">
                                <IonCardContent id="registerClinician" className="cover" onClick={handleWidgetOnClick} >
                                    <IonImg src="../../assets/icon/coverMob.png"/>
                                    <IonImg className="icon" src="../../assets/icon/registerClinicianIcon.png"/>
                                    <h1 className="widget-label">Register Clinican</h1>
                                </IonCardContent>
                            </IonCol>
                            <IonCol className="col">
                                <IonCardContent id="assignDevice" className="cover" onClick={handleWidgetOnClick} >
                                    <IonImg src="../../assets/icon/coverMob.png"/>
                                    <IonImg className="icon" src="../../assets/icon/assignDeviceIcon.png"/>
                                    <h1 className="widget-label">Assign Device</h1>
                                </IonCardContent>
                            </IonCol>
                        </IonRow>
                        <IonRow className="row">
                            <IonCol className="col">
                                <IonCardContent id="revoke" className="coverLast" onClick={handleWidgetOnClick} >
                                    <IonImg src="../../assets/icon/coverMob.png"/>
                                    <IonImg className="icon" src="../../assets/icon/revokeIcon.png"/>
                                    <h1 className="widget-label">Revoke</h1>
                                </IonCardContent>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCardContent>
            </div>
            {
                forbiddenToastVisible && <div className="forbidden-container">
                    <div className="forbidden"> Forbidden Access</div>
                </div>
            }
        </div>
    );
};
export default Widget;