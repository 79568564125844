const registerDeviceModel = {
    "thingId": "pacemaker:12-13-14-15-16-17",
    "policyId": "pacemaker:default_all_policy",
    "definition": "com.calyan:pacemaker:0.1.0",
    "attributes": {
        "serialnumber": "AAA089010AAB",
        "mfgdate": "2021/15/01",
        "blekey_mfg": "eZ123BHDmfg",
        "blekey_patient": "eZ123BHDpatient",
        "blekey_clinician": "eZ123BHDclinician",
        "macid": "12-13-14-15-16-17",
        "model": "0.1",
		"technology": "VVIP",
        "version": {
            "sw": {
                "minor": "0.1",
                "major": "2.3.1"
            },
            "hw": "0.0.2",
            "fw": 1.245,
            "implant": false
        }
    },
    "features": {
        "devicestate": {
            "properties": {
                "status": "Created"
            }
        }
    }
}
export default registerDeviceModel;