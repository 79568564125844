export const followupFields = [
    {
        subject: "Visit Date",
        fields: [{
            fieldName: "Date of Visit:",
            fieldType: "date"
        },
        //ZZZZ TODO Follow-up Visit -> Other case has to be considered in conditional basis
        {
            fieldName: "Follow-up Visit:",  //update getEcrfFollowUpFields() if this fieldName changes
            fieldType: "radio",
            values: [{
                fieldName: "Follow-up Visit:",
                value: "1 Week"
            },
            {
                fieldName: "Follow-up Visit:",
                value: "2 Week"
            },
            {
                fieldName: "Follow-up Visit:",
                value: "1 Month"
            },
            {
                fieldName: "Follow-up Visit:",
                value: "3 Month"
            },
            {
                fieldName: "Follow-up Visit:",
                value: "6 Month"
            },
            {
                fieldName: "Follow-up Visit:",
                value: "9 Month"
            },
            {
                fieldName: "Follow-up Visit:",
                value: "12 Month"
            },            {
                fieldName: "Follow-up Visit:",
                value: "18 Month"
            }            
            ]
        }

        ],
    },
    {
        subject: "Physical Exam of Wound Site",
        fields: [{
            fieldName: "Notes:",
            fieldType: "text"
        }
        ],
    },
    {
        subject: "Pacemaker Parameters at Entry",
        fields: [{
            fieldName: "Electrode Impedance",
            fieldType: "number",
            unit: "Ω"
        },
        {
            fieldName: "Pacing Rate",
            fieldType: "number",
            unit: "bpm"
        },
        {
            fieldName: "Amplitude",
            fieldType: "number",
            unit: "V"
        },
        {
            fieldName: "Electrode Polarity",
            fieldType: "number"
        },
        {
            fieldName: "Capture",
            fieldType: "radio",
            values: [{
                fieldName: "Capture",
                value: "Yes"
            },
            {
                fieldName: "Capture",
                value: "No"
            },
            {
                fieldName: "Capture",
                value: "Intermittent"
            },
            ]
        },
        {
            fieldName: "Battery Voltage",
            fieldType: "number",
            unit: "V"
        },
        {
            fieldName: "Remaining Battery Life",
            fieldType: "number",
            unit: "%"
        }
        ],
    },
    {
        subject: "Pacemaker Log",
        fields: [{
            fieldName: "Time",
            fieldType: "time",
            tz:true
        },
        {
            fieldName: "Rate",
            fieldType: "number",
            unit: "bpm"
        },
        {
            fieldName: "Pacemaker Amplitude",
            fieldType: "number",
            unit: "V"
        },
        {
            fieldName: "Impedance",
            fieldType: "number",
            unit: "Ω"
        },
        {
            fieldName: "Posture",
            fieldType: "select",
            values: [
                "upright",
                "supine",
                "prone",
                "right lateral recumbent",
                "left lateral recumbent"
            ]
        },
        {
            fieldName: "Sensed Amp",
            fieldType: "number",
            unit: "mA"
        },
        {
            fieldName: "Pacemaker Capture",
            fieldType: "radio",
            values: [{
                fieldName: "Pacemaker Capture",
                value: "Yes"
            },
            {
                fieldName: "Pacemaker Capture",
                value: "No"
            },
            {
                fieldName: "Pacemaker Capture",
                value: "Intermittent"
            }
            ]
        },
        {
            fieldName: "Extra-Cardiac Stim",
            fieldType: "radio",
            values: [{
                fieldName: "Extra-Cardiac Stim",
                value: "Yes"
            },
            {
                fieldName: "Extra-Cardiac Stim",
                value: "No"
            },
            ]
        },
        {
            fieldName: "Comments",
            fieldType: "text"
        }
        ],
    },
    {
        subject: "Pacemaker Parameters at Exit",
        fields: [{
            fieldName: "Pacemaker Electrode Impedance",
            fieldType: "number",
            unit: "Ω"
        },
        {
            fieldName: "R-Wave Amplitude",
            fieldType: "number",
            unit: "V"
        },
        {
            fieldName: "Pacemaker Pacing Rate",
            fieldType: "number",
            unit: "bpm"
        },
        {
            fieldName: "Pacing Amplitude",
            fieldType: "number",
            unit: "V"
        },
        {
            fieldName: "Pacemaker Electrode Polarity",
            fieldType: "number"
        },
        {
            fieldName: "Pacemaker Exit Capture",
            fieldType: "radio",
            values: [
                {
                    fieldName: "Pacemaker Exit Capture",
                    value: "Yes"
                },
                {
                    fieldName: "Pacemaker Exit Capture",
                    value: "No"
                },
                {
                    fieldName: "Pacemaker Exit Capture",
                    value: "Intermittent"
                }
            ]
        },
        {
            fieldName: "Pacing Capture Threshold",
            fieldType: "number",
            unit: "V"
        },
        {
            fieldName: "Pacemaker data downloaded to programmer?",
            fieldType: "radio",
            values: [{
                fieldName: "Pacemaker data downloaded to programmer?",
                value: "Yes"
            },
            {
                fieldName: "Pacemaker data downloaded to programmer?",
                value: "No"
            }
            ]
        }
        ],
    },
    {
        //todo check for data load
        //ZZZZ TODO Textbox for Other instead of Date in other cases
        subject: "Imaging Method",
        fields: [{
            fieldName: "Which imaging method was used? Check all that apply and date of recording",
            fieldType1: "checkbox",
            fieldType2: "date",
            methods: [
                "Computerized Tomography (CT)",
                "Chest X-Ray",
                "Fluoroscopy",
                "Other",
            ]
        }
        ],
    },
    {
        subject: "Concomitant Medications / Therapies Logs",
        fields: [{
            fieldName: "Brand / Generic Name",
            fieldType: "text"
        },
        {
            fieldName: "Indication",
            fieldType: "text"
        },
        {
            fieldName: "Dose/Units",
            fieldType: "text"
        },
        {
            //ZZZZ TODO Other (specify case has to be considered in conditional basis
            fieldName: "Route",
            fieldType: "select",
            values: [
                "1= Per Oral (PO)",
                "2= Sublingual (SL)",
                "3= Subcutaneous (SC)",
                "4= Inhaled (IH)",
                "5= Intramuscular (IM)",
                "6= Transdermal (TD)",
                "7= Intravenous (IV)",
                "8= Nasal (N)",
                "9= Per Rectal (PR)",
                "10= Intrauterine (IU)",
                "11= Other (specify)"
            ]

        },
        {
            //ZZZZ TODO Other (specify case has to be considered in conditional basis
            fieldName: "Frequency",
            fieldType: "select",
            values: [
                "1= PRN",
                "2= Every Day (QD)",
                "3= Twice a day (BID)",
                "4= Thrice a day (TID)",
                "5= Every other day (QOD)",
                "6= Every hour (QH)",
                "7= Every 4 hours (Q4H)",
                "8= Once weekly (OW)",
                "9= Twice weekly (TIW)",
                "10= Other (specify)"
            ]

        },
        {
            fieldName: "Start Date",
            fieldType: "date"
        },
        {
            fieldName: "Stop Date",
            fieldType: "date"
        },
        {
            fieldName: "Check if Ongoing",
            fieldType: "radio",
            values: [{
                fieldName: "Check if Ongoing",
                value: "Yes"
            },
            {
                fieldName: "Check if Ongoing",
                value: "No"
            }
            ]
        }],
    },
    {
        subject: "Treadmill Test (1-Month)",
        fields: [{
            fieldName: "Was a treadmill test performed?",
            fieldType: "radio",
            values: [{
                fieldName: "Was a treadmill test performed?",
                value: "Yes"
            },
            {
                fieldName: "Was a treadmill test performed?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "***Scan all data associated with treadmill test***",
            fieldType: "file",
            dependency: {
                fieldName: "Was a treadmill test performed?",
                value: "Yes"
            } 
        }
        ],
        dependency: {
            fieldName: "Follow-up Visit:",
            value: "1 Month"
        } 
    },
    {
        subject: "Blood Collection (3-Month)",
        fields: [{
            fieldName: "Was blood collection performed?",
            fieldType: "radio",
            values: [{
                fieldName: "Was blood collection performed?",
                value: "Yes"
            },
            {
                fieldName: "Was blood collection performed?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "Is blood collection report available for upload?",
            fieldType: "radio",
            values: [{
                fieldName: "Is blood collection report available for upload?",
                value: "Yes"
            },
            {
                fieldName: "Is blood collection report available for upload?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE(Blood collection report)",
            fieldType: "file",
            dependency: {
                fieldName: "Is blood collection report available for upload?",
                value: "Yes"
            } 
        },
        // {
        //     fieldName: "If no, please give reason, and complete a protocol deviation CRF",
        //     fieldType: "text"
        // }
        ],
        dependency: {
            fieldName: "Follow-up Visit:",
            value: "3 Month"
        }         
    },
    {
        subject: "24-Hour Holter Electrocardiogram (1-Week, 1-Month, 3-Month)",
        fields: [{
            fieldName: "Was a 24-hour Holter ECG performed?",
            fieldType: "radio",
            values: [{
                fieldName: "Was a 24-hour Holter ECG performed?",
                value: "Yes"
            },
            {
                fieldName: "Was a 24-hour Holter ECG performed?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "Is 24-Hour Holter ECG report available for upload?",
            fieldType: "radio",
            values: [{
                fieldName: "Is 24-Hour Holter ECG report available for upload?",
                value: "Yes"
            },
            {
                fieldName: "Is 24-Hour Holter ECG report available for upload?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE(24-Hour Holter ECG report)",
            fieldType: "file",
            dependency: {
                fieldName: "Is 24-Hour Holter ECG report available for upload?",
                value: "Yes"
            } 
        },
        // {
        //     fieldName: "If no, please give reason, and complete a protocol deviation CRF",
        //     fieldType: "text"
        // }
        ],
        dependency: {
            fieldName: "Follow-up Visit:",
            value: ["1 Week","1 Month","3 Month"]
        }         
    },
    {
        subject: "Echocardiogram (6-Month)",
        fields: [{
            fieldName: "Was Echocardiogram performed at the 6-month visit?",
            fieldType: "radio",
            values: [{
                fieldName: "Was Echocardiogram performed at the 6-month visit?",
                value: "Yes"
            },
            {
                fieldName: "Was Echocardiogram performed at the 6-month visit?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "Is ECHO video available for upload?",
            fieldType: "radio",
            values: [{
                fieldName: "Is ECHO video available for upload?",
                value: "Yes"
            },
            {
                fieldName: "Is ECHO video available for upload?",
                value: "No"
            },
            ]
        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE(ECHO video)",
            fieldType: "file",
            dependency: {
                fieldName: "Is ECHO video available for upload?",
                value: "Yes"
            } 
        },
        {
            fieldName: "Is ECHO report available for upload?",
            fieldType: "radio",
            values: [{
                fieldName: "Is ECHO report available for upload?",
                value: "Yes"
            },
            {
                fieldName: "Is ECHO report available for upload?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "***SCAN AND PUT IN DATABASE(ECHO report)",
            fieldType: "file",
            dependency: {
                fieldName: "Is ECHO report available for upload?",
                value: "Yes"
            } 
        },
        // {
        //     fieldName: "If no, please give reason, and complete a protocol deviation CRF",
        //     fieldType: "text"
        // }
        ],
        dependency: {
            fieldName: "Follow-up Visit:",
            value: "6 Month"
        }           
    },
    {
        //ZZZZ TODO if ADE yes, link the adverse tab and remove associated field
        subject: "Adverse Events",
        fields: [{
            fieldName: "Did the subject experience any adverse events since the last visit?",
            fieldType: "radio",
            values: [{
                fieldName: "Did the subject experience any adverse events since the last visit?",
                value: "Yes"
            },
            {
                fieldName: "Did the subject experience any adverse events since the last visit?",
                value: "No"
            }
            ]
        },
        {
            fieldName: "If yes, please complete an Adverse Event CRF",
            fieldType: "text"
        }
        ],
    }
];