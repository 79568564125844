import { Capacitor } from "@capacitor/core";
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs'
import { baseurl } from "../util/config";
import { EventSourcePolyfill } from 'event-source-polyfill'
import { DateTime } from "luxon";
import { handleGrantThingPolicy, handleRegisterThing } from "../service/adminUI";
import * as _ from "lodash";
import { access } from "fs-extra";
import { realmpublickeypem,alg } from "../util/config";
import * as jose from 'jose';
//const Keycloak = require('keycloak-verify');  //to overcome the error related to Could not find a declaration file for module and when its @types not available


let knownNamespaces = ["pacemaker","message","syncTimer","symptom","mapper","eCRF_subjects", "eCRF_enrollment", 
                       "eCRF_preimplant", "eCRF_implant", "eCRF_followup1week", "eCRF_followup2week", "eCRF_followup1month",
                       "eCRF_followup3month", "eCRF_followup6month", "eCRF_followup9month", "eCRF_followup12month", "eCRF_followup18month", "eCRF_studycompletionORtermination",
                       "eCRF_adverseevent","eCRF_protocoldeviation", "eCRF_devicemalfunction"];

                                              
let eventTopicMap = new Map<string,string>();
//eventTopicMap.set("pacemaker", baseurl + 'api/2/things?namespaces=pacemaker');
//eventTopicMap.set("symptoms", baseurl + 'api/2/things?namespaces=symptoms');

_.forEach(knownNamespaces, function(namespace) {eventTopicMap.set(namespace, baseurl + 'api/2/things?namespaces='+namespace);})

let eventSourceMap = new Map<string,EventSource>() ; //store the event source instances for cleanup

let eventRxJsSubjectMap:Map<string,ReplaySubject<any>>; //store the RxJS subjects from caller
let historyEventSubject:ReplaySubject<any>;
let historyURL =  baseurl + 'api/2/things/';

export var eventSourceTriggerMessage:Function; //set only in JEST env
export interface UserInfo {
   userId:string,
   emailId:string,
   type:string
}
export interface Permisssions {
   policyowner?: UserInfo;
   rwowners?:UserInfo[];
   ronlyowners?:UserInfo[];
}


export class DTAL {
constructor(eventSubjectMap:Map<string,ReplaySubject<any>>) {
		eventRxJsSubjectMap = eventSubjectMap;
   }
async subscribe( event: string, callback?:(value: any) => void) {
   //determine event topic from event
   let topic = eventTopicMap.get(event);
   if(topic) {
   //fetch authenicated token from current session
   let accesstoken = localStorage.getItem("access_token");

   var eventSourceInitDict = {
      headers: {
        Authorization: `Bearer ${accesstoken}`,
        // "Content-Type": "application/merge-patch+json",
      },
    };
    let source = new EventSourcePolyfill(topic, eventSourceInitDict);
    source.onmessage = function (notifyEvent) {
         if((notifyEvent != null) && (notifyEvent.data != "")) {
         console.log(notifyEvent);
         //get RxJS subject from eventRxJsSubjectMap using notifyEvent.data.thingId
         let sseResponse = JSON.parse(notifyEvent.data);
         let thingId = sseResponse.thingId;
         let event = thingId.split(":")[0] ?? "";
         let subject = eventRxJsSubjectMap.get(event);
         if(subject) {
            subject.next(sseResponse) //send the just the payload to RxJs subject  
         }
         else {
            if(callback) callback(sseResponse); //invoke the callback if associated RxJS subject is not available for given event
         }    
         } 
    };   
 //jest testing
 if(process.env.JEST_WORKER_ID !== undefined) {
   eventSourceTriggerMessage = source.onmessage;
 }
	eventSourceMap.set(event,source);
}
		 
}

async unsubscribe( event: string) {
   let eventSource = eventSourceMap.get(event);
   if(eventSource) eventSource.close(); //close the event source

}

async gethistorical(identifier:string, fromversion:number, toversion:number, fromtimestamp:string, totimestamp:string, fields?:string): Promise<ReplaySubject<any>|any>  {
   //validate the identifier
   let retValue = null;
   if(identifier)
   {
      //check the format
      if(identifier.indexOf(':') != -1)
      {
         let isFromVersion = false;
         let isToVersion = false;
         let isFromTime = false;
         let isToTime = false;
         let ssePathParameter = ''  //to 
         let headerVersionParameter = 0;
         let headerTimeParameter = '';
         let isHistorySSE = false;

         if((fromversion != null) && (fromversion > 0)) isFromVersion = true; headerVersionParameter=fromversion;
         if((toversion != null) &&  (toversion > 0)) isToVersion = true; headerVersionParameter=toversion;

         if((fromtimestamp != null) &&  (DateTime.fromISO(fromtimestamp).isValid)) isFromTime = true; headerTimeParameter=fromtimestamp;
         if((totimestamp != null) &&  (DateTime.fromISO(totimestamp).isValid)) isToTime = true; headerTimeParameter=totimestamp;

         //if version range exists
         if(isFromVersion && isToVersion)
         {
            //SSE
            isHistorySSE = true
            ssePathParameter=`?from-historical-revision=${fromversion}&to-historical-revision=${toversion}`
         } 

         //if version range doesn't exist and time range exist
         if((!isHistorySSE) && (isFromTime) && (isToTime))
         {
            //SSE
            isHistorySSE = true
            ssePathParameter=`?from-historical-timestamp=${fromtimestamp}&to-historical-timestamp=${totimestamp}`            
            
         }
         let accesstoken = localStorage.getItem("access_token");

         //subscribe to historical SSE events
         if(isHistorySSE) {
            let fieldParameter = fields?'&fields='+fields:''
            let topic = baseurl + 'api/2/things/' + identifier + ssePathParameter + fieldParameter;
            //create RxJS subject
            historyEventSubject = new ReplaySubject(1);            
            //fetch authenicated token from current session
            var eventSourceInitDict = {
               headers: {
                 Authorization: `Bearer ${accesstoken}`,
                 // "Content-Type": "application/merge-patch+json",
               },
             };
             let source =  eventSourceMap.get("history");
             if(source != null) {
               source.close(); //close the previous history eventsource
             }
             source = new EventSourcePolyfill(topic, eventSourceInitDict);             
             source.onmessage = function (notifyEvent) {
                  if((notifyEvent != null) && (notifyEvent.data != "")) {
                  console.log(notifyEvent);
                  //get RxJS subject from eventRxJsSubjectMap using notifyEvent.data.thingId
                  let sseResponse = JSON.parse(notifyEvent.data);
                  historyEventSubject.next(sseResponse) //send the just the payload to RxJs subject      
                  }
             };  
            //jest testing
            if(process.env.JEST_WORKER_ID !== undefined) {
               eventSourceTriggerMessage = source.onmessage;
            }             
            eventSourceMap.set("history",source); //save the history eventsource
            retValue = historyEventSubject;
         } else {
            //fetch a sample from historical data
            let requestOptions = {};       
            if (headerVersionParameter) {
               requestOptions = {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + accesstoken,
                      'at-historical-revision' : headerVersionParameter
                  }
              };

            } else if (headerTimeParameter) {
               requestOptions = {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + accesstoken,
                      'at-historical-timestamp' : headerTimeParameter
                  }
              };               
               
            }

            const result = await fetch(historyURL+identifier, requestOptions);
            retValue = await result.json();
            

         }

      }
   }
   return retValue;
}

async create(identifier:string,data:JSON,permisssions:Permisssions={}) {
   if(identifier)
   {
      let accesstoken = localStorage.getItem("access_token");
      //check the format
      if(identifier.indexOf(':') != -1)
      {
         let policyowner:UserInfo|object={};
         let rwowners:UserInfo[] = [{userId:'', emailId:'',type:''}]; //Read Write
         let ronlyowners:UserInfo[] = [{userId:'', emailId:'',type:''}]; //ReadOnly
         if(!_.isEmpty(permisssions)) {
            policyowner = permisssions?.policyowner ?? {};
            rwowners = permisssions?.rwowners ?? [];
            ronlyowners = permisssions?.ronlyowners ?? [];
         }
         //create thing with policy
         await handleRegisterThing(identifier,data,accesstoken,policyowner);
         //grant rights 
         if(rwowners) {
            //_.forEach(rwowners, function(permission) {
            for (const permission of rwowners) {   
               await handleGrantThingPolicy(identifier,permission.userId,accesstoken,permission.emailId,permission.type,true)
             };
         }
         if(ronlyowners) {
            //_.forEach(ronlyowners, function(permission) {
            for (const permission of ronlyowners) {   
               await handleGrantThingPolicy(identifier,permission.userId,accesstoken,permission.emailId,permission.type,false)
             };
         }

      }
   }   

}

async fetch(namespace:string,filterexp:string,fields:string) {
   let retValue = {};
   if(namespace)
   {
      let accesstoken = localStorage.getItem("access_token");
      //validate against known namespaces
      let validnamespace =_.filter(knownNamespaces, _.matches(namespace));
      if(!_.isEmpty(validnamespace))
      {
         let url = baseurl + 'api/2/search/things?namespaces=' + namespace;
         if(filterexp && !_.isEmpty(filterexp)) {
            url += '&filter='+ filterexp;
         }
         if(fields && !_.isEmpty(fields)) {
            url += '&fields='+ fields;
         }
         const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accesstoken
            }
        };
        const result = await fetch(url, requestOptions);
        if ((result.ok) && (result.status == 200)) {
          retValue = await result.json();
         
        } 

      }
   }
   return retValue;  

}

async get(identifier:string) {
   let retValue = {};
   if(identifier)
   {
      let accesstoken = localStorage.getItem("access_token");
      //check the format
      if(identifier.indexOf(':') != -1)
      {
         let namespace = identifier.split(":")[0];
         //validate against known namespaces
         let validnamespace =_.filter(knownNamespaces, _.matches(namespace));
         if(!_.isEmpty(validnamespace))
         {         
            let url = baseurl + 'api/2/things?ids=' + identifier;
            const requestOptions = {
               method: 'GET',
               headers: {
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + accesstoken
               }
           };
           const result = await fetch(url, requestOptions);
           if ((result.ok) && (result.status == 200)) {
             retValue = await result.json();            
            }
         }
      }
   }
   return retValue;
}

async set(identifier:string,data:JSON) :Promise<boolean|JSON>{
   let retValue = false;
   if(identifier)
   {
      let accesstoken = localStorage.getItem("access_token");
      //check the format
      if(identifier.indexOf(':') != -1)
      {
         let namespace = identifier.split(":")[0];
         //validate against known namespaces
         let validnamespace =_.filter(knownNamespaces, _.matches(namespace));
         if(!_.isEmpty(validnamespace))
         {         
            let url = baseurl + 'api/2/things/' + identifier;
            const requestOptions = {
               method: 'PATCH',
               headers: {
                   'Content-Type': 'application/merge-patch+json',
                   'Authorization': 'Bearer ' + accesstoken
               },
               body: JSON.stringify(data)
           };
           const result = await fetch(url, requestOptions);
           if ((result.ok) && ((result.status == 204) || (result.status == 201))) {
             retValue = true;            
           } else {
               retValue = await result.json();
            }
         }
      }
   }
   return retValue;
}

async validatejwt(accessToken:string): Promise<boolean> {
   let retValue = false;
/*    const config = { publicKey: keycloakcfg.public_key };
   const keycloak = Keycloak(config);
   try {
      const user = await keycloak.verifyOffline(accessToken);
      retValue = true;
   } catch(e){ console.log(e) } */

   try {
         const publicKey = await jose.importSPKI(realmpublickeypem, alg)
         const { payload, protectedHeader } = await jose.jwtVerify(accessToken, publicKey)
         if(!_.isEmpty(payload)) { retValue = true}
   } catch(e){ console.log(e) } 


   return retValue;
}


}